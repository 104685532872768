import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import CheckoutWidget from '../widgets/CheckoutWidget';

const CheckoutPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) => state.user);
  const checkoutData = {
    eventId: 1,
    eventName: "Sample Event",
    eventDate: "20 May 2023",
    eventTime: "Saturday, 1 PM - 10 PM",
    location: "Sample Event Location",
    eventPicturePath: "https://i0.wp.com/picjumbo.com/wp-content/uploads/people-dancing-at-electronic-music-festival-free-photo.jpg?w=600&quality=80",
    ticketPrice: "$200 - $500",
    spotsLeft: 5,
  };

  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        marginBottom='4rem'
        justifyContent="space-between"
      >
        {/* <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <UserWidget userId={_id} picturePath={picturePath} />
        </Box> */}
        <Box
          flexBasis={isNonMobileScreens ? "70%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
          mb='4rem'
        >
          <CheckoutWidget checkoutData={checkoutData} />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="28%">
            <AdvertWidget />
            <Box m="2rem 0" />
            <FriendListWidget userId={_id} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckoutPage;
