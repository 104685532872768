import dayjs from 'dayjs';
import React from 'react';

const BlogCard = ({ blog }) => {
  const truncateMobileText = (text) => {
    return text.length > 70 ? text.substring(0, 70) + '...' : text;
  };
  
  const truncateText = (text) => {
    return text.length > 95 ? text.substring(0, 95) + '...' : text;
  };
  return (
    <div className="w-full bg-[#2A2A2A] rounded-[20px] overflow-hidden shadow-lg">
      <img
        src={blog.blogBannerPath}
        alt={blog.blogTitle}
        className="w-full h-[160px] sm:h-[280px] object-cover"
      />
      <div className="p-4">
        <h2 className="text-base sm:text-xl font-semibold block sm:hidden mb-2">{truncateMobileText(blog.blogTitle)}</h2>
        <h2 className="text-base sm:text-xl font-semibold hidden sm:block mb-2">{truncateText(blog.blogTitle)}</h2>
        <p className="text-xs sm:text-sm text-yellow">{blog.createdAt ? dayjs(blog.createdAt).format("MMMM DD, YYYY") : ""}</p>
      </div>
    </div>
  );
};

export default BlogCard;
