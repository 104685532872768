import React, { useEffect, useState } from "react";
import image from "../../assests/images/badmaash.png";
import { useParams } from "react-router";
import { apiUrl } from "config";
import { useSelector } from "react-redux";
import { seo } from "utilites/utlis";
import dayjs from "dayjs";
import parse from "html-react-parser";
import Photo from "../../assests/images/artist.jpg";
import { Typography } from "@mui/material";

const BlogDetailscetion = () => {
  const params = useParams();
  const [blogData, setBlogData] = useState({});
  const token = useSelector((state) => state.token);
  const [blogDataAvailable, setBlogDataAvalibility] = useState(false);
  const [imgErr, setImgErr] = useState(false);

  const getBlogById = async () => {
    const response = await fetch(`${apiUrl}/blogs/${params.blogId}/landing`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    console.log("data getPostById", data, data.blog);
    setBlogData(data.blog);
    setBlogDataAvalibility(true);
    seo({ title: data.eventName, metaDescription: data.eventName });
  };
  useEffect(() => {
    getBlogById();
  }, []);

  return (
    <section className="px-4 sm:px-24 py-6">
      {blogDataAvailable == true ? (
        <>
          <div className="flex sm:gap-x-6 gap-x-4 px-2">
            <div className="w-[20px] bg-yellow -mt-4 sm:-mt-6 rounded-b-md"></div>
            <div className="flex-grow flex flex-col gap-y-4 ">
              <div className="text-base text-gray-500">Blog</div>
              <div>
                <div className="text-lg sm:text-3xl font-bold mb-2">
                  {blogData.blogTitle}
                </div>
                <div className="text-yellow">
                  {" "}
                  {blogData.createdAt
                    ? dayjs(blogData.createdAt).format("MMMM DD, YYYY")
                    : ""}
                </div>
              </div>
            </div>
          </div>

          <div
            className="mt-8"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={blogData.blogBannerPath}
              alt={blogData.blogTitle}
              onError={() => {
                console.log("ERRROR");
                setImgErr(true);
              }}
              className="w-full sm:w-[80%] h-[60vh] sm:h-[60vh] rounded-[20px] object-cover"
            />
          </div>
          <div className="p-2 sm:p-4 mt-2">
            {parse(blogData.blogDescription)}
          </div>
          <div
            className="p-4 sm:p-4 rounded-lg relative my-4"
            style={{
              border: "1px solid white",
            }}
          >
            <div
              className="absolute inset-0"
              style={{
                background:
                  "linear-gradient(-74deg, rgba(79, 79, 79, 1) 0%, rgba(211, 211, 211, 1) 100%)",
                opacity: "23%",
              }}
            />
            <div className="flex items-center">
              <img
                src={imgErr ? Photo : blogData.userPicturePath}
                alt={imgErr ? blogData.blogTitle : "Author Icon"}
                onError={() => {
                  console.log("ERROR");
                  setImgErr(true);
                }}
                className="w-20 h-20 rounded-full mr-4 z-50"
              />
              <div>
                <div
                  className="text-lg"
                  style={{ textTransform: "capitalize" }}
                >
                  {blogData.userFirstName} {blogData.userLastName}
                </div>
                <Typography
                  variant="caption"
                  style={{ textTransform: "capitalize" }}
                  className="text-xs"
                >
                  {" "}
                  {blogData.userType === "EVENT_ORGANISER"
                    ? "Organiser"
                    : blogData.userType === "ARTIST"
                    ? "Artist"
                    : blogData.userType === "VENU"
                    ? "Venu"
                    : ""}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <div className="!w-[200px] !h-[20px] -mr-10 sm:-mr-32 mt-4 bg-yellow z-50 rounded-l-md"></div>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </section>
  );
};

export default BlogDetailscetion;
