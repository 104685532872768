import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "./../../config";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Chip,
  Stack,
  InputBase,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WidgetWrapper from "components/WidgetWrapper";
import ExplorePostWidget from "./ExplorePostWidget";
import FlexBetween from "components/FlexBetween";
import Checkbox from '@mui/material/Checkbox';

const ExplorePostsWidget = ({ userId, isProfile = false }) => {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const token = useSelector((state) => state.token);
  const [tags, setTags] = useState([]);
  const [tagTypes, setTagTypes] = useState([]);
  const { palette } = useTheme();
  const [weekTags, setWeekTags] = useState([
    { tagName: 'This Week', tagType: 'DATEFILTER' },
    { tagName: 'Today', tagType: 'DATEFILTER' },
    { tagName: 'Tomorrow', tagType: 'DATEFILTER' }
  ])
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [dateSearchType, setSearchType] = useState();
  const [expanded, setExpanded] = useState('Date');
  const getPosts = async () => {
    try {
      let body = {};
      if (tagTypes && tagTypes.length > 0) {
        body['postTags'] = tagTypes;
      }
      if (dateSearchType && dateSearchType != undefined) {
        body['dateSearchType'] = dateSearchType;
      }
      const response = await fetch(`${apiUrl}/posts/event-posts`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(body)
      });
      const data = await response.json();
      setPosts(data)
    } catch (e) {
      setPosts([])
    }

  };
  const getExploreTags = async () => {
    const response = await fetch(`${apiUrl}/tags`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      body: JSON.stringify({ tagType: "explore" }),
    });
    const data = await response.json();
    setTags(data);
  };

  const addTagForSearch = (tag) => {
    setTagTypes((prevSelectedDivs) => {
      const isSelected = prevSelectedDivs.includes(tag);

      if (isSelected) {
        return prevSelectedDivs.filter(
          (selectedValue) => selectedValue !== tag
        );
      } else {
        return [...prevSelectedDivs, tag];
      }

    });
    // getPosts()
  }
  useEffect(() => {

    getPosts();
    getExploreTags();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {

    getPosts();

  }, [tagTypes, dateSearchType]);

  const handleChange =
  (panel) => {
    setExpanded(panel);
  };
  return (
    <>
      <Box
        width="100%"
        padding="2rem 3rem"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box
          flexBasis={"18%"}
          style={{ width: "100%", marginTop: "2px" }}
          paddingLeft={'1rem'}
          paddingRight={'1rem'}
          paddingBottom={'1rem'}
        // backgroundColor={palette.background.alt}
        >
          <Typography
            textAlign={"left"}
            fontWeight={400}
            fontSize="16px"
            gridColumn="span 6"
            color="main"
            marginBottom={'10px'}
          >
            Filter
          </Typography>

          <Box style={{ borderRadius: '30px' }}>
            <Accordion style={{backgroundColor: '#3A3939'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="Date-content"
                id="Date-header"
                expanded={expanded === 'Date'} onChange={()=>handleChange('Date')}
              >
                <Typography color={'primary'}>Date</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel control={<Checkbox
                    checked={dateSearchType === "Today"}
                    onChange={() => {
                      if (dateSearchType === "Today") {
                        setSearchType(null)
                      } else {
                        setSearchType("Today")
                      }

                    }} />} label="Today" />
                  <FormControlLabel control={<Checkbox onChange={() => {
                    if (dateSearchType === "Tomorrow") {
                      setSearchType(null)
                    } else {
                      setSearchType("Tomorrow")
                    }

                  }}
                    checked={dateSearchType === "Tomorrow"} />} label="Tomorrow" />
                  <FormControlLabel control={<Checkbox onChange={() => {
                    if (dateSearchType === "This Week") {
                      setSearchType(null)
                    } else {
                      setSearchType("This Week")
                    }

                  }}
                    checked={dateSearchType === "This Week"} />} label="This Week" />
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ marginTop: "1rem", backgroundColor: '#3A3939' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="Categories-content"
                id="Categories-header"
                expanded={expanded === 'Categories'} onChange={()=>handleChange('Categories')}
              >
                <Typography color={'primary'}>Category</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <FormGroup>
                  {tags.map((value) => (
                    <FormControlLabel value={value.tagName} control={<Checkbox
                      onChange={(event)=>{
                        addTagForSearch(value.tagName)
                      }}
                       />} label={value.tagName} />
                  ))}
                </FormGroup>

              </AccordionDetails>
            </Accordion>
          </Box>

          {/* {weekTags.map((value) => (
            <Chip
              key={value.tagName}
              onClick={() => {
                if(dateSearchType === value.tagName){
                  setSearchType(null)
                }else{
                  setSearchType(value.tagName)
                }
                
              }}
              style={{
                fontSize: "16px",
                padding: "1rem",
                border: `0.5px solid ${palette.primary.main}`,
                backgroundColor: dateSearchType == value.tagName
                  ? palette.primary.main
                  : palette.grey[900],
                color: dateSearchType == value.tagName ? palette.grey[900] : "white",
                cursor: "pointer",
              }}
              label={value.tagName}
              variant="outlined"
            />
          ))} */}

          {/* <Stack
            style={{ width: "100%" }}
            direction="row"
            flexWrap={"wrap"}
            alignItems={"center"}
            // justifyContent="center"
            gap={"12px"}
          >
            {tags.map((value) => (
              <Chip
                key={value.tagName}
                onClick={() => addTagForSearch(value.tagName)}
                style={{
                  fontSize: "16px",
                  padding: "1rem",
                  border: `0.5px solid ${palette.primary.main}`,
                  backgroundColor: tagTypes.includes(value.tagName)
                    ? palette.primary.main
                    : palette.grey[900],
                  color: tagTypes.includes(value.tagName) ? palette.grey[900] : "white",
                  cursor: "pointer",
                }}
                label={value.tagName}
                variant="outlined"
              />
            ))}
          </Stack> */}

        </Box>
        <Box
          flexBasis={"80%"}>
          <Typography
            textAlign={"left"}
            fontWeight={400}
            fontSize="25px"
            gridColumn="span 6"
            color="main"
            marginBottom={'10px'}
          >
            Discover Amazing Event Near You
          </Typography>
          <FlexBetween
            direction="row"
            flexWrap={"wrap"}
            alignItems={"center"}
            gap={"12px"}
            style={{ justifyContent: 'flex-start' }}

          >
            {posts.map(
              (event) => (
                <ExplorePostWidget
                  event={event}
                />
              )
            )}
          </FlexBetween>

        </Box>
      </Box>


    </>
  );
};

export default ExplorePostsWidget;
