import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import { apiUrl } from "./../../config";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PostWidget = ({
  postId,
  postUserId,
  name,
  description,
  location,
  picturePath,
  userPicturePath,
  likes,
  comments,
  postType,
  eventDate,
  eventType,
  bannerPath
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = Boolean(likes[loggedInUserId]);
  const likeCount = Object.keys(likes).length;
  const [eventExpired, setEventExpired] = useState(false);

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const primaryMain = palette.primary.main;
  const [viewMore, setViewMore] = useState(false);

  const patchLike = async () => {
    const response = await fetch(`${apiUrl}/posts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
  };
  const isEventExpired = async () => {
    let eventDay = dayjs(eventDate);
    let currentDay = dayjs();

    let expiry = eventDay.isBefore(currentDay)
    // console.log("date2",expiry,postId)
    setEventExpired(expiry)
  }
  useEffect(() => {
    isEventExpired()
  }, []);
  const getClippedText = (text) => {
    if(text){
      if(isNonMobileScreens){
        if(text.length>180){
          let textRender = (
           <> {viewMore === false ?
            <><span  style={{whiteSpace:'pre-wrap'}}>{text.substring(0, 180).split("\n").map(str => <p>{str}</p>) }</span> < span style={{ cursor: 'pointer' }} onClick={()=>setViewMore(!viewMore)}><b> ...view more</b></span></>
                      : <><span  style={{whiteSpace:'pre-wrap'}}>{description.split("\n").map(str => <p>{str}</p>)}</span> <span style={{ cursor: 'pointer' }} onClick={()=>setViewMore(!viewMore)}><b> view less</b></span></> }</>)
          return textRender
        }else{
          return text
        }
        
      }else{
        if(text.length>80){
          let textRender = (
            <> {viewMore === false ?
             <>{text.substring(0, 80).split("\n").map(str => <p>{str}</p>) } < span style={{ cursor: 'pointer' }} onClick={()=>setViewMore(!viewMore)}><b> ...view more</b></span></>
                       : <>{description.split("\n").map(str => <p>{str}</p>)} <span style={{ cursor: 'pointer' }} onClick={()=>setViewMore(!viewMore)}><b> view less</b></span></> }</>)
           return textRender
        }else{
          return text
        }
      }
    }else{
      return text
    }
  
   
  }
  return (
    <WidgetWrapper m={isNonMobileScreens ? "0 0 2rem 0" : "0 0 1rem 0"} style={{ backgroundColor: '#3A3939' }}>
      <Friend
        friendId={postUserId}
        name={name}
        subtitle={location}
        userPicturePath={userPicturePath}
        postType={postType}
        postId={postId}
        loggedInUserId={loggedInUserId}
        eventDate={eventDate}
      />
      {( bannerPath!= undefined && bannerPath!=null && bannerPath!='' && bannerPath != 'null' && bannerPath!= 'undefined') ? 
      <>{ (
        <img
          alt="post"
          style={{ borderRadius: "0.75rem", marginTop: "0.75rem", width: "100%", height: '300px', objectFit: 'contain', backgroundColor:'black' }}
          src={`${bannerPath}`}
        />
      )}</>
      :
      <>
      {picturePath && (
        <img

          alt="post"
          style={{ borderRadius: "0.75rem", marginTop: "0.75rem", width: "100%", height: '300px', objectFit: 'contain', backgroundColor:'black' }}
          src={`${picturePath}`}
        />
      )}</> }

      
      <FlexBetween mt="0.25rem">
        <FlexBetween gap="1rem">
          <FlexBetween gap="0.3rem">
            <IconButton onClick={patchLike}>
              {isLiked ? (
                <FavoriteOutlined sx={{ color: primary }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
            <Typography>{likeCount}</Typography>
          </FlexBetween>

          <FlexBetween gap="0.3rem">
            <IconButton onClick={() => setIsComments(!isComments)}>
              <ChatBubbleOutlineOutlined />
            </IconButton>
            <Typography>{comments.length}</Typography>
          </FlexBetween>
        </FlexBetween>

        {/* <IconButton>
          <ShareOutlined />
        </IconButton> */}

      </FlexBetween>
      <FlexBetween gap="0.3rem" alignContent='start' alignItems='start' justifyContent='space-between'>
        <div style={{ width: '85%' }}>
          <Typography color={main} style={{whiteSpace:'pre-line'}}>
          { getClippedText(description) }
        </Typography>
      </div>

      {(postType == 'EVENT' && eventExpired === false) ?
        <Button
        data-umami-event-postlist={postId} 
        data-umami-event="View-Book-Button-home-page"
          onClick={() => {
            navigate(`/event/${postId}`)
          }}
          variant="contained"
          sx={{
            backgroundColor: primaryDark,
            color: 'white',
            p: "0.6rem",
            borderRadius: '10px',
            "&:hover": { color: palette.primary.main }
          }}
        >
          {eventType != 'LISTING' ? "Book Now" : "View Details"}
        </Button> : ''}
    </FlexBetween>

      {
    isComments && (
      <Box mt="0.5rem">
        {comments.map((comment, i) => (
          <Box key={`${name}-${i}`}>
            <Divider />
            <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
              {comment}
            </Typography>
          </Box>
        ))}
        <Divider />
      </Box>
    )
  }
    </WidgetWrapper >
  );
};

export default PostWidget;
