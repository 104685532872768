import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import Logo from "../../assests/logo/Vybez_Logo.png";

const AboutUs = () => {
  return (
    <Grid container lg={{ margin: "20px " }}  >
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '80vh', overflow: 'hidden', padding: '20px' }}>
        <Box >



          <img src={Logo} alt="" style={{ transform: "translate(-500%, -50%)", width: "100%", height: "auto", zIndex: 1, animation: "rotation 18s infinite linear", maxWidth: '300px' }} />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '100px 20px', order: { xs: 1, md: 2 } }}>
        <Box sx={{ maxWidth: 600 }}>

          <Typography variant="h2" paragraph sx={{
            fontFamily: "'Inter', sans-serif",
          }}>
            VYBEZ, THE ULTIMATE PLATFORM FOR THOSE WHO LOVE TO EXPLORE NEW EVENTS, VENUES, AND ARTISTS.
          </Typography>
          <Typography variant="body1" paragraph>
            With Vybez, you can discover the latest happenings in your city and beyond, all in one place. Whether you're looking for live music, parties, art exhibitions, or food festivals, Vybez has got you covered!
          </Typography>
          <Typography variant="body1" paragraph>
            At Vybez, we believe that life is all about experiencing new things and making memories. That's why we've created a platform that brings together a diverse community, all with the shared goal of creating unforgettable experiences for everyone.
          </Typography>
          <Typography variant="body1">
            By joining Vybez, you become part of this vibrant community, where you can stay connected to the latest info, be on the guest list, pre-buy packages, tickets, and plan seamlessly.
          </Typography>
        </Box>
      </Grid>
    </Grid >
  );
};

export default AboutUs;
