import { Typography } from "@mui/material";
import { apiUrl } from "config";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const VenueCarousel = () => {
  const [venues, setVenues] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchVenues();
  }, []);
  const fetchVenues = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/venues/1/16`);
      const data = await response.json();
      setVenues(data.venues);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const navigateToVenueDetail = (venue) => {
    navigate(`/venue-detail/${venue.profileSlug}`);
  };
  const navigateToVenue = () => {
    navigate(`/venues`);
  };
  return (
    <section className="px-4 sm:px-16 py-6">
      <div className="pb-6 flex justify-between items-center">
        <Typography
          variant="h2"
          className={`!font-bold text-xl animate-slide-up text-center md:text-left`}
        >
          Venues
        </Typography>
        <div
          onClick={() => {
            navigateToVenue();
          }}
          className="text-yellow flex items-center gap-x-2 cursor-pointer"
        >
          <div className="text-base">View All</div>
          <ArrowForwardIosIcon />
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="flex overflow-x-auto space-x-4 scrollbar-hide">
          {venues.map((venue, index) => (
            <div
              key={index}
              onClick={() => {
                navigateToVenueDetail(venue);
              }}
              className="relative inline-block flex-none w-[300px] sm:w-[350px]
              md:w-[400px] h-[180px] sm:h-[200px] md:h-[220px] bg-white
              rounded-lg shadow-md overflow-hidden cursor-pointer"
            >
              <img
                src={
                  venue.bannerPath != undefined &&
                  venue.bannerPath != null &&
                  venue.bannerPath != ""
                    ? venue.bannerPath
                    : venue.picturePath
                }
                alt={venue.venuName}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black/100"></div>
              <div className="absolute bottom-4 left-4 text-white">
                <h3 className="text-lg !font-bold">{venue.venuName}</h3>
                <p className="text-sm text-yellow underline">{venue.location}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VenueCarousel;
