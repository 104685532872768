import React, { useState } from "react";
import Photo from "../../assests/images/artist.jpg";

const ArtistCard = ({ artist }) =>{
  const [imgErr, setImgErr] = useState(false);

  return(
    <div className="bg-[#2A2A2A] rounded-[20px] shadow-md overflow-hidden w-full transition-transform transform hover:scale-105">
      <div className="relative">
      {imgErr == false ? (
          <>
            <img
              src={artist.picturePath}
              alt={artist.firstName}
              onError={() => {
                console.log("ERRROR");
                setImgErr(true);
              }}
              className="w-full h-48 object-contain bg-[#000000] pt-0.5"
            />
          </>
        ) : (
          <>
            <img
              src={Photo}
              alt={artist.firstName}
              className="w-full h-48 object-contain bg-[#000000] pt-0.5"
            />
          </>
        )}
      </div>
      <div className="p-4">
        <div className="text-yellow text-xs">{artist.nickName != undefined &&
          artist.nickName != null &&
          artist.nickName != ""
            ? `${artist.firstName} ${(artist.lastName!= undefined && artist.lastName!= null && artist.lastName!= 'null' && artist.lastName!='') ? artist.lastName : ''}`
            : ''}</div>
        <h3 className="font-semibold text-lg">{artist.nickName != undefined &&
          artist.nickName != null &&
          artist.nickName != ""
            ? artist.nickName
            : `${artist.firstName} ${(artist.lastName!= undefined && artist.lastName!= null && artist.lastName!= 'null' && artist.lastName!='') ? artist.lastName : ''}`}</h3>
      </div>
    </div>
  );
  
} 
export default ArtistCard;
