// LoginForm.jsx
import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { toast } from 'react-toastify';
import { apiUrl } from "config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "state";
import { GAEvent } from "components/tracking";

const LoginWithOtpForm = ({ values, errors, touched, handleBlur, handleChange,setPageType,resetForm,handleSubmit }) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [validPhone, setValidPhone] = useState(true);
  const [enableVerifyOtpBtn, setEnableVerifyOtp] = useState(false);
  const [otpSecret, setOTPSecret] = useState(null);
  const [enableNext, setNext] = useState(false);
  const [reTriggerSeconds, setRetriggerSecs] = useState(60);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enableRetriggerOtp, setRetriggerOtp] = useState(false);
  const sendOtp = async () => {
    if (validPhone == true) {
      const otpSentResponse = await fetch(`${apiUrl}/auth/send-login-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phone: values.phone
        }),
      });
      const sentOtp = await otpSentResponse.json();
      if (sentOtp.error == false) {
        toast.success(sentOtp.message);
        setEnableVerifyOtp(true);
        setOTPSecret(sentOtp.token)
        setRetriggerOtp(false);
        setRetriggerSecs(60)
        let secs = 60;
        let otpInterval = setInterval(() => {
          secs = secs - 1;
          setRetriggerSecs(secs);
          if (secs == 0) {
            setRetriggerOtp(true);
            clearInterval(otpInterval)
          }
        }, 1000)
      } else {
        toast.error(sentOtp.message)
      }
    }
  }
  const verifyOtp = async () => {
    if (validPhone == true) {
      const otpSentResponse = await fetch(`${apiUrl}/auth/verify-login-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phone: values.phone,
          otp: values.otp,
          token: otpSecret
        }),
      });
      const sentOtp = await otpSentResponse.json();
      if (sentOtp.error == false) {
        toast.success(sentOtp.message);
        setEnableVerifyOtp(false)
        setRetriggerOtp(false)
        dispatch(
          setLogin({
            user: sentOtp.user,
            token: sentOtp.token,
          })
        );
        setTimeout(()=>{
          let backUrl = localStorage.getItem('backUrl');
          if(backUrl && backUrl!=undefined && backUrl != null){
            console.log(backUrl)
            navigate(backUrl);
          }else{
            navigate("/home");
          }
        },300)
        
      } else {
        toast.error(sentOtp.message)
      }
    }
  }
  return (
    <Box
      display="grid"
      style={{ width: "100%", margin: "auto" }}
      gap="20px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
      }}
    >
      {/* Title */}
      {/* <Typography
        textAlign={"center"}
        fontWeight={400}
        fontSize="48px"
        lineHeight={"48px"}
        gridColumn="span 4"
      >
        VybeZ
      </Typography> */}
       <Typography variant='h3' gridColumn="span 6" color='primary'>Welcome Back</Typography>

      {/* Subtitle */}
      {/* <Typography
        textAlign={"center"}
        fontWeight={400}
        fontSize="16px"
        lineHeight={"36px"}
        variant="h5"
        color="primary"
        letterSpacing={"1px"}
        sx={{ mb: "1rem" }}
        gridColumn="span 4"
      >
        Start your frolicking!
      </Typography> */}
      {/* Email */}
      <TextField
        label="Phone"
        onBlur={handleBlur}
        onChange={(event) => {
          let reg = "(^\\d{10}$)+"
          let regex = new RegExp(reg, "g");
          let result = regex.test(event.currentTarget.value);
          setValidPhone(result);
          handleChange(event)
        }}
        value={values.phone}
        name="phone"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneAndroidOutlinedIcon />
            </InputAdornment>
          ),
        }}
        error={!validPhone}
        helperText={validPhone == false ? 'Enter valid mobile number' : ''}
        sx={{
          gridColumn: "span 6",
        }}
      />
      {enableVerifyOtpBtn == true ?
        <>
          {/* Password */}
          <TextField
            label="Otp"
            type="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.otp}
            name="otp"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PolicyOutlinedIcon />
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.otp) && Boolean(errors.otp)}
            helperText={touched.otp && errors.otp}
            sx={{ gridColumn: "span 6" }}
          />
          {enableRetriggerOtp ? <Typography
          data-umami-event-login={values.phone} 
          data-umami-event="Resend-OTP-Button"
            textAlign={"center"}
            onClick={() => {
              sendOtp();
              GAEvent({
                category: 'Resend-OTP-Button',
                action: 'Click',
                label: values.phone,
              })
            }}
            sx={{
              gridColumn: "span 6",
              textDecoration: "underline",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
            {"Resend OTP"}
          </Typography> : <Typography
            textAlign={"center"}
            sx={{
              gridColumn: "span 6",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
            {`Resend OTP in ${reTriggerSeconds} seconds`}
          </Typography>}


          <Box gridColumn={"span 6"}>
            <Button
             data-umami-event-login={values.phone} 
             data-umami-event="Login-Button-Verify-Otp-and-Login"
              fullWidth
              type="button"
              onClick={() => { verifyOtp();
                GAEvent({
                  category: 'Login-Button-Verify-Otp-and-Login',
                  action: 'Click',
                  label: values.phone,
                })
               }}
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
              disbled={validPhone == false ? true : false}
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              <Typography fontSize={16} fontWeight={400}>
                {"Verify OTP & Login"}
              </Typography>
            </Button>
          </Box>
        </>

        : ""
      }


      {enableNext == true ?
        <>
          <Box gridColumn={"span 6"}>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
              disbled={enableNext == false ? true : false}
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              <Typography fontSize={16} fontWeight={400}>
                {"Next"}
              </Typography>
            </Button>
          </Box>
        </>
        : <>
          {enableVerifyOtpBtn == false ? <Box gridColumn={"span 6"}>
            <Button
              fullWidth
              type="button"
              onClick={() => { 
                if(values.phone && values.phone.length>0){
                  sendOtp()
                }else{
                  toast.error("Phone number is mandatory")
                }
                }}
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
              disbled={(validPhone == false && values.phone.length>0) ? true : false}
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              <Typography fontSize={16} fontWeight={400}>
                {"Send OTP"}
              </Typography>
            </Button>
          </Box> : ''}
        </>}
        <Typography
        gridColumn={"span 4"}
          textAlign={"center"}
          onClick={() => {
            setPageType("otp");
            resetForm();
          }}
          sx={{
            textDecoration: "underline",
            color: palette.primary.main,
            "&:hover": {
              cursor: "pointer",
              color: palette.primary.light,
            },
          }}
        >
          {"Don't have an account? Sign Up here."}
        </Typography>
    </Box>
  );
};

export default LoginWithOtpForm;
