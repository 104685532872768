import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  TextField,
} from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FlexBetween from "components/FlexBetween";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { GAEvent } from "components/tracking";

const ProfilePic = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  setPageType
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
      }}
    >
      <Typography
        textAlign={"center"}
        fontWeight={400}
        variant='h3'
        lineHeight={"48px"}
        color="primary"
        gridColumn="span 8"
        mb={"1rem"}
      >
        Upload Profile picture

      </Typography>

      <Box
        style={{ width: "100%", margin: "30px auto" }}
        border={`1px solid ${palette.neutral.medium}`}
        borderRadius="5px"
        p="1rem"
      >
        <Dropzone
          acceptedFiles=".jpg,.jpeg,.png"
          multiple={false}
          onDrop={(acceptedFiles) => setFieldValue("picture", acceptedFiles[0])}
        >
          {({ getRootProps, getInputProps }) => (
            <Box
              {...getRootProps()}
              border={`2px dashed ${palette.primary.main}`}
              p="1rem"
              sx={{
                "&:hover": { cursor: "pointer" },
                border: `2px dashed ${errors.picture ? palette.error.main : palette.primary.main
                  }`,
              }}
            >
              <input {...getInputProps()} />
              {!values.picture ? (
                <Typography>{"Add Picture Here"}</Typography>
              ) : (
                <FlexBetween>
                  <Typography>{values.picture.name}</Typography>
                  <EditOutlinedIcon />
                </FlexBetween>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
      <Box gridColumn={"span 8"} style={{ textAlign: "center" }}>
        {values.type == 'VENU' ?
          <TextField
            label="Venu Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.venuName}
            name="venuName"
            error={Boolean(touched.venuName) || Boolean(errors.venuName)}
            helperText={touched.venuName || errors.venuName}
            sx={{ gridColumn: "span 6" }}
          /> : ''}
      </Box>

      <Box gridColumn={"span 8"} style={{ textAlign: "center" }}>
        <Button
          data-umami-event-login={values.phone}
          data-umami-event="Register-button-profile-pic"
          fullWidth
          type="submit"
          sx={{
            width: "100%",
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
          endIcon={<ArrowForwardOutlinedIcon />}
        >
          <Typography fontSize={16} fontWeight={400}>
            {"Register"}
          </Typography>
        </Button>
      </Box>
      <Box gridColumn={"span 8"} style={{ textAlign: "center" }}>
        <Button
          data-umami-event-login={values.phone}
          data-umami-event="Back-button-profile-pic"
          onClick={() => {
            setPageType("user type")
            GAEvent({
              category: 'Back-button-profile-pic',
              action: 'Click',
              label: values.phone,
            })
          }}
          sx={{
            width: "50%",
            m: "0.25rem auto",
            color: "white",
            "&:hover": {
              cursor: "pointer",
              background: "#00000000",
            },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            {"Back"}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ProfilePic;
