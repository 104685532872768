import { apiUrl } from "./../config";
import { Box,IconButton } from "@mui/material";
import { useState } from "react";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";

const UserImage = ({ image, size = "60px" }) => {
  const [imgErr, setImgErr] = useState(false);
  return (
    <Box width={size} height={size}>
      {imgErr == false ?
      <img
      style={{ objectFit: "cover", borderRadius: "50%" }}
      width={size}
      height={size}
      alt="user"
      src={`${image}`}
      onError={()=>{ setImgErr(true)}}
    /> : <IconButton size="large"><AccountCircleOutlined style={{fontSize:'130%'}}/></IconButton> }
      
      
    </Box>
  );
};

export default UserImage;
