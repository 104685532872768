import { Box, useMediaQuery,Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import ViewWidget from "scenes/widgets/ViewWidget";
import AppBar from "scenes/widgets/AppBar";
import FlexBetween from "components/FlexBetween";
import { seo } from "utilites/utlis";
import VenueListWidget from "scenes/widgets/VenuesListWidget";
import ArtistListWidget from "scenes/widgets/ArtistListWidget";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath, userType, userVerified } = useSelector((state) => state.user);
  seo({title:'Timeline | Vybez - Urban Frolic',metaDescription:'Timeline | Vybez - Urban Frolic'})
  return (
    <>
     <Box>
      <Navbar />
      <Box
        backgroundColor='#1A1A1A'
        width="100%"
        padding={isNonMobileScreens ? "2rem 5rem" : "0.5rem 2rem"}
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        {/* {isNonMobileScreens && (
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
            <UserWidget userId={_id} picturePath={picturePath} />
          </Box>
        )} */}

        <Box
          flexBasis={isNonMobileScreens ? "70%" : undefined}
          // mt={isNonMobileScreens ? undefined : "1rem"}
          // ml={isNonMobileScreens ? undefined : "2rem"}
          paddingBottom='4rem'
        >
          {/* {((userType === 'EVENT_ORGANISER' || userType === 'INFULENCER') && userVerified === true) ?
            <MyPostWidget userType={userType} verifiedUser={userVerified} picturePath={picturePath} />
            : ''} */}
          <Box m={isNonMobileScreens ? undefined : "1rem"}>
            <FlexBetween>
            <Typography variant="h6" fontSize={'28px'} textAlign={'left'} letterSpacing={'0.1rem'} >Feeds</Typography>          
            <Typography variant="caption" fontSize={'14px'} textAlign={'right'} letterSpacing={'0.1rem'} color={'primary'}> Recents</Typography>          
            </FlexBetween>
          </Box>

          <PostsWidget userId={_id} />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="28%">
            <AdvertWidget />
            <Box m="1rem 0" />
            <VenueListWidget/>
            <Box m="1rem 0" />
            <ArtistListWidget/>
            <Box m="1rem 0" />
            <FriendListWidget userId={_id} />
          </Box>
        )}
      </Box>
    </Box>
   
    </>
   
  
  );
};

export default HomePage;
