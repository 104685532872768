import React from 'react';
import { Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const Content = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is small

    return (
        <>

            <Grid container justifyContent="center">
                <Grid item xs={12} style={{ backgroundColor: '#f7f7f7', padding: isSmallScreen ? '50px 20px' : '100px 130px' }}>
                    <Typography variant="body1" gutterBottom style={{ color: '#6d6f72', fontSize: '18px' }}>
                        <p>
                            This ‘Terms and Conditions’ document (“T&C”) governs the end users’ (“You/Your/User”) access to and use of ‘VYBEZ Platform’ (which term shall be deemed to include the internet and mobile website www.insider.in as well as its mobile application and any other means to access the same from time to time) and the services offered thereunder.
                        </p>
                        <br />
                        <p>The VYBZE Platform is owned and controlled by ANANTALOOP, a company incorporated under the Companies Act, 2013 with its registered office at having its registered address at 44, 4th main 7th cross maruthinagar nagarbhvi main road Bangalore-560072(hereinafter referred to as ” ANANTALOOP /Us/Our/We”). Through the VYBZE Platform, ANANTALOOP  provides to You information in relation to events that are organized by third-party entities including but not limited to the dates, venue, ticket pricing and other event-related information, and facilitates sale of tickets to such events, to You. However, when you purchase a ticket for any event, show, or content available on VYBZE Platform, then for the purpose of this T&C, You will be considered as a customer of ANANTALOOP  only. You will not be considered as a customer of ANANTALOOP ., which is the parent entity having ownership of the brand, ‘VYBEZ’. You agree that for any issue or grievance under these T&C, Your recourse shall be solely to ANANTALOOP .</p>
                        <br />
                        <p>
                            This T&C, along with the ‘Privacy Policy’ (available here) which constitutes an integral and inalienable part of the T&C, and any other applicable policies published on the VYBZE Platform, comprise a binding agreement between You and ANANTALOOP . The ‘T&C’, ‘Privacy Policy’ and any such applicable policies constitute an electronic record under the Information Technology Act, 2000 as amended and the relevant rules made thereunder as applicable, and the amended provisions pertaining to electronic records in various statutes under applicable Indian laws.</p>
                        <br />
                        <p>
                            Your use of the VYBZE Platform and the services provided thereunder shall be governed by this T&C as well as any other applicable policies published on this VYBZE Platform, including but not limited to the Privacy Policy. Your use of the VYBZE Platform whether for the purpose of transacting through VYBZE Platform or otherwise, implies Your consent to the provisions of this T&C as well as any other applicable policies, including but not limited to ANANTALOOP ’s use of any information provided by You for the purpose of Your use of the VYBZE Platform. ANANTALOOP  hereby reserves the right to modify, add or delete any part of this T&C without any prior written notice/ intimation to You. It shall be Your sole responsibility to periodically review this T&C and all other applicable policies published on the VYBZE Platform for any revisions thereto. If You do not agree to any of the revisions made to this T&C by ANANTALOOP , You must discontinue further access and use of the VYBZE Platform and the services provided thereunder. You agree and acknowledge that such discontinuance of access to and use of the VYBZE Platform and the services provided thereunder will render the T&C inapplicable from such date of discontinuance only; however this T&C will remain applicable to all instances of use by You of VYBZE Platform and the services provided thereunder prior to such date of discontinuance.
                        </p>
                        <br />
                        <p>It is clarified that terms and conditions specific to an event in respect of which ANANTALOOP  has facilitated sale of tickets through VYBZE Platform (“Event”) shall be published on the event page accessible on the VYBZE Platform. ANANTALOOP  publishes such information as provided by and on behalf of the organizer associated with such Event (“Event Organizer”). In the event of any conflict between the Event-related terms and conditions, and this T&C, User agrees that the Event-related terms and conditions shall prevail and be binding on the User at all times.</p>
                        <br />
                        <p>
                            CANCELLATION PROTECT
                        </p>
                        <br />
                        <ul>
                            <li>In case of cancellation for bookings with cancellation protect in relation to an Event (as applicable), the User will be entitled to a refund of 100% of ticket price of ticket within the cancellation window.</li><br />

                            <li>A cancellation protected booking has a window of up to 10 days prior to the Event or date of courier whichever is earlier for cancelling the booking.</li><br />

                            <li>Tickets purchased online on VYBZE Platform cannot be cancelled via an offline mode either being the box-office or stores.</li><br />

                            <li>Cancellation Protect only covers the ticket and food and beverages booked, any additional amount like commission, booking fee & cancellation protect fees including GST on the same will not be refunded.</li><br />

                            <li>Partial cancellation of a booking is not allowed.</li><br />

                            <li>Refund will be processed to the source account used for payment at the time of booking, and any query related to delay in a refund will be resolved as per the provisions of this T&C.</li><br />

                            <li>On any booking with cancellation protect, the User will receive a booking confirmation via SMS/email. Please note that the booking confirmation is NOT the ticket and the User would be able to enter the Event arena only with physical tickets or an e-ticket, in accordance with the terms and conditions of the Event in respect of which the User has booked a ticket. In the event that entry is subject to presentation of physical tickets by the User, the said physical tickets will be delivered once the allowed cancellation period is over.</li><br />

                            <li>Cancellation Protect is a promotional program by ANANTALOOP which owns and controls the internet platform ‘VYBEZ’ and it reserves the right to alter the terms and conditions or discontinue the offer without prior notice. ANANTALOOP may suspend any User’s ability to participate in the said program at its discretion without prior intimation and reasoning.</li><br />

                            <li>In case of any issues, the decision of ANANTALOOP will be considered final and binding on the User(s) at all times.</li><br />
                        </ul>
                        <br />
                        <p>COPYRIGHT</p>
                        <br />
                        <p>The entire content included in the VYBZE Platform, including but not limited to text, graphics or code is copyrighted as a collective work under the Indian and all other copyright laws of the world, and is the sole property of ANANTALOOP  or is appropriately licensed by ANANTALOOP  from the relevant owners thereof. At no point of time, will the User have any rights and/ or claim to have acquired any rights in and to any part or portion of the VYBZE Platform and/ or any content contained therein, in any manner whatsoever.</p>
                        <br />
                        <p>You may display and, subject to any expressly stated restrictions or limitations relating to specific material, download or print portions of the material from the different areas of the VYBZE Platform solely for Your own non-commercial use, or to place an order with VYBZE Platform or to purchase VYBZE Platform products.</p>
                        <br />
                        <p>
                            Any other use, including but not limited to the reproduction, distribution, display or transmission of the content of the VYBZE Platform is strictly prohibited, unless specifically authorized by ANANTALOOP  in writing.
                        </p>
                        <br />
                        <p>TRADEMARKS</p>
                        <br />
                        <p>All trademarks, service marks and trade names of ‘VYBEZ Insider’ used on VYBZE Platform are trademarks or registered trademarks of ANANTALOOP, as the case may be. At no point of time, will the User have any rights and/ or claim to have acquired any rights in and to any part or portion of the ‘VYBEZ Insider’ trademarks and/ or logos, in any manner whatsoever.</p>
                        <br />
                        <p>
                            PAYMENT METHODS
                        </p>
                        <br />
                        <p>We accept Visa, MasterCard, American Express, Debit Cards, International Cards via Stripe, Net Banking, VYBEZ Wallet, UPI, and PayU Money through the integrated ticketing system available only on VYBZE Platform. For certain events, we will also accept offline payment modes including cash / cheques only through authorised promoters, whose details will be listed on the respective event pages. If you are purchasing Tickets from anywhere outside of VYBZE Platform, including through any authorized promoters, it shall be completely your own responsibility to verify the genuineness of the source and authenticity of the Ticket issued to you. You may reach out to us at help@vybez.in for verifying the purchased tickets.</p>
                        <br />
                        <p>
                            PRICING, OFFERS, AVAILABILITY, AND SEAT CONFIRMATION
                        </p>
                        <br />
                        <p>
                            VYBZE Platform is a ticketing platform and sells tickets on behalf of Event Organisers and therefore has no control over its availability, offers, and pricing. If the Event Organiser announces any offer for the Event, such an offer will not apply to the tickets purchased before the announcement of the offer.
                        </p>
                        <br />
                        <p>After the payment is complete, You will receive a confirmation page or email that must be shown at the event venue to redeem Your tickets.</p>
                        <br />
                        <p>If You do not receive a confirmation number after submitting payment information, or if you experience an error message or service interruption after submitting payment information, please get in touch with our customer service department to confirm whether the order has been placed or not. We don’t want You to lose money in case You assume that an order was not placed because you failed to receive confirmation.</p>
                        <br />
                        <p>While purchasing tickets, You are limited to a specific number of tickets You can buy per event.</p>
                        <p>Tickets</p><br />
                        <p>ANY TICKET PURCHASED THROUGH THE VYBZE PLATFORM IS MEANT SOLELY AND EXCLUSIVELY FOR PERSONAL USE BY THE PERSON IN WHOSE NAME THE TICKET HAS BEEN ISSUED. TICKETS ARE NOT A TRANSFERABLE PROPERTY AND CANNOT BE RESOLD, TRANSFERRED OR CONVEYED TO ANY PERSON UNLESS PRIOR WRITTEN AND EXPRESS PERMISSION HAS BEEN PROVIDED BY THE EVENT ORGANIZER. ANY PERSON FOUND ABUSING TICKETS FOR PERSONAL GAIN OR FOR COMMERCIAL USE SHALL BE LIABLE TO SUITABLE LEGAL PROCEEDINGS INCLUDING THOSE RELATED TO BLACK MARKETING AND FINANCIAL & CRIMINAL FRAUD.</p><br />
                        <p>If ANANTALOOP or the Event Organizer finds that You are transferring or selling, or You had in the past transferred or sold, Your tickets, including m-ticket or band, through any mode, including social media platforms, other ticketing platforms, by hand, thereby allowing any other person to access the Event without purchasing the ticket from VYBZE Platform, then ANANTALOOP or the Event Organizer may initiate suitable legal proceedings.</p><br />
                        <p>Tickets once sold cannot be exchanged, cancelled, modified, transferred, or refunded, and You shall not make any request for modifications in the ticket, including change in date, time, location, or category unless the Event Organizer has specified so in the Event’s terms and conditions. Please make sure You check the date, time, and location before You book Your tickets. But we’d still advise You to read the terms and conditions for each Event regarding exchange, cancellation, modification, transfer, and refund. If You wish to change or modify your ticket details, the same shall be entirely at the discretion of the Event Organizer.</p><br />
                        <p>If an Event is cancelled or postponed and the Event Organisers of such Event agree on issuing a refund for the tickets, it shall be the Event Organiser’s sole responsibility to contact You and process the refund and ANANTALOOP and/or One97 shall have no liability in this regard. However, the booking fee charged by ANANTALOOP is non-refundable. If the Event Organizer informs ANANTALOOP to process the refunds on their behalf, Your refunds may reflect in Your account within seven (07) to ten (10) working days from the date of receiving the refund request.</p><br />

                        <p>Products</p><br />
                        <p>In the event that You have placed an order for purchase of a product made available for sale on VYBZE Platform, if at the time of delivery and/or within 15 days from the date of delivery, if any defect is found or You are unhappy with the product for any reason, then You can ask for replacement of the product or products from the seller subject to the following terms and conditions:</p><br />
                        <p>Availability of the product or part of the product with the seller.</p><br />
                        <p>The product is not damaged due to misuse.</p><br />
                        <p>The damage/defect is covered under the manufacturer’s warranty.</p><br />
                        <p>The product is not tampered with and missing serial numbers.</p><br />
                        <p>The product is returned with all original packaging and accessories, including the box, manufacturer’s packaging if any, and all other items originally included with the product delivered.</p><br />
                        <p>The product is not customized or made to order.</p><br />
                        <p>Clothes and footwear are not used (other than for trial), altered, washed or damaged in any way.</p><br />
                        <p>Original tags and packaging should be intact.</p><br />
                        <p>Please note that this policy relating to Products is only in relation to goods. This Products policy is not applicable to tickets that You purchase on the VYBZE Platform or on the Food & Beverage tokens that you purchase at any Event which You access.</p><br />

                        <p>Refund Policy</p><br />
                        <p>In case You have made the payment but failed to receive any email and/or SMS confirmation, then chances are that such a payment has failed. Once we have confirmed a failed order, we process refunds on our own. While we process such refunds within 3-5 days, it may take up to 7-10 working days (and sometimes more) for your financial institution to credit the amount back to your account.</p><br />
                        <p>VYBZE reserves the right to deny refunds for tickets purchased outside of VYBZE Platform. Any decisions taken by the event organiser and VYBZE management will be final and binding in this regard. If you find that you have been issued fake tickets not authorized by us, then you may be a victim of fraud, for which you may consider filing appropriate police complaints/first information report at the nearest police station having jurisdiction over the area of your residence. We bear no responsibility or liability, under any law or circumstances, for such frauds committed by unauthorized third parties.</p><br />

                        <p>DELIVERY</p><br />

                        <p>Tickets</p><br />
                        <p>Email confirmation: Your booking confirmation will be sent via an email. This confirmation needs to be printed and shown at the ticket counter to get a physical ticket, if entry to the Event in respect of which such ticket has been booked is subject to presentation of a physical ticket.</p><br />
                        <p>SMS/ Whatsapp confirmation: Your booking confirmation will be sent via an SMS and/or Whatsapp notification. You may be requested to display the ticket details received along with appropriate identity proofs to receive a physical ticket.</p><br />
                        <p>Physical Tickets: In case of physical tickets, we will deliver them to Your address confirmed and provided by You on VYBZE Platform. In case the tickets are returned to the origin, then ANANTALOOP will refund the delivery charges. However, delivery charges will not be refunded for any reasons beyond ANANTALOOP’s or its delivery partner’s control, including but not limited to your premises being closed at the time of delivery or in case you have provided an incorrect address. It is Your responsibility to ensure that delivery is taken at the address provided by You. It is also Your responsibility to ensure that You keep your delivery address updated to the one where You can ensure delivery is taken.</p><br />
                        <p>Ticket Pick-Up: If at the time of purchasing physical tickets, You choose to ‘pick-up’ tickets from a designated location, then the pick-up details shall be intimated to You on the purchase email containing details of your purchase. You can only pick-up the physical tickets during the pick-up window from the location or address specified in the email and/or SMS confirmation.</p><br />
                        <p>It is clarified that if you have chosen the delivery option, then the ticket redemption shall not be available at the box-office at the Event’s venue. We will not be able to fulfil Your requests for issuing a duplicate ticket or issuing an e-ticket as a replacement. Therefore, it’s highly important for You to ensure that You pick up the tickets during the pick-up window. It is Your responsibility to periodically check your notifications that shall be sent to You at the start of and during the pick-up window</p><br />
                        <p>In all of the above options, You will need to produce the credit or debit card used to purchase the tickets for picking up the tickets at the venue. If the card user is not picking up the tickets herself/himself, an authorisation with the photocopy of this card signed by the cardholder needs to be produced. The signature on the card will be matched against the one used for authorisation.</p><br />
                        <p>Damage: You shall be solely responsible for any damage, loss, destruction, mutilation or wastage of a physical ticket which occurs for any reason except those related to packaging issues. If You received a damaged ticket due to packaging issues, please do not take delivery of the physical ticket and instead hand it back to the delivery supplier. Accepting delivery shall exempt ANANTALOOP from all liability for damage to physical tickets arising because of packaging issues and ANANTALOOP shall not be responsible to compensate You for such damage or provide You a replacement ticket. We will not be able to help You redeem the physical tickets if the barcode/qr code is not scannable. In such situations, You will be required to purchase another new ticket(s) to get access to the venue.</p><br />

                        <p>PRODUCTS</p><br />
                        <p>Products will be delivered to the address specified, within the timeline specified in the terms and conditions of the Event. In case of any unusual delays, the Event Organizer shall reach out to You directly on the contact details provided by You.</p><br />

                        <p>INDEMNIFICATIONS</p><br />
                        <p>You agree to indemnify, defend and hold harmless VYBZE (including its directors, employees, officers, affiliates, consultants and agents)(each an “Indemnified Party”) from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Indemnified Parties that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by the User, pursuant to these Terms and Conditions, or any acts committed by third parties not authorized by VYBEZ Insider, or in relation to any third party claims raised against Indemnified Parties in relation to the foregoing.</p><br />

                        <p>NOTIFICATIONS</p><br />

                        <p>You are consenting to receive transactional and promotional messages, or notifications, as generated by the VYBZE Platform. We will be sending notifications to you by way of the following methods:</p><br />
                        <ul>
                            <li><p>SMS</p></li>
                            <li><p>Email</p></li>
                            <li><p>Whatsapp</p></li>
                            <li><p>Voice Call</p></li>
                        </ul>
                        <br />



                        <p>Opt-out</p><br />
                        <p>We provide all Users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from Us on behalf of our partners, and from Us in general, after setting up an account.</p><br />
                        <p>If You want to remove Your contact information from all VYBZE lists and newsletters, please click on the unsubscribe links in the emailer. For opting out of receiving any transactional and promotional messages or notifications on your whatsapp account, please reach out to us at help@vybez.in and we will assist you.</p><br />

                        <p>GRIEVANCE REDRESSAL</p><br />

                        <p>Content</p><br />
                        <p>In accordance with the provisions of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules 2021, any grievances which You may have with respect to the information shared by You with VYBZE Platform hereunder and its treatment, may be directed by You to the customer support of VYBZE Platform. You may contact VYBZE Platform customer support by sending an email to help@vybez.in</p><br />
                        <p>Your complaint shall be acknowledged within 24 hours and disposed of within 15 days from the date of receipt of complaint. If your complaint pertains to any material which exposes the private area, shows full nudity, depicts sexual act or is in nature of impersonation, the complaint shall be resolved within 24 hours from receipt of complaint.</p><br />

                        <p>Customer Experience</p><br />
                        <p>At VYBEZ Insider, your ticketing experience is of utmost importance to us. Our team is always on their toes with one goal and that is delighting our customers and their experience with us. In any way, if you feel that we can do more to live upto your expectations then that would be an opportunity for us. Here are all the ways through which you can easily get in touch with us.</p><br />
                        <ol>
                            <li><p>You can send your complaints directly through the contact form, available on the website.</p></li>
                            <li><p>You can also write to us directly at help@vybez.in</p></li>
                        </ol>


                    </Typography>
                </Grid>

            </Grid>

        </>
    );
}

export default Content;
