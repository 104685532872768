import { Typography } from "@mui/material";
import { apiUrl } from "config";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";


const OtherVenuesSection = () => {
  const [venues, setVenues] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchVenues();
  }, []);
  const fetchVenues = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/venues/1/16`);
      const data = await response.json();
      setVenues(data.venues);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const navigateToVenueDetail = (venue) => {
    // navigate(`/venue-detail/${venue.profileSlug}`);
    window.location.href = `/venue-detail/${venue.profileSlug}`
  };
  const navigateToVenue = () => {
    navigate(`/venues`);
  };
  return (
    <section className="px-4 sm:px-16 pb-6 mb-20">
      <div
        className="p-4 sm:p-8 rounded-lg relative"
        style={{
          border: "1px solid white",
          overflow: "hidden",
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(-74deg, rgba(79, 79, 79, 1) 0%, rgba(211, 211, 211, 1) 100%)",
            opacity: "23%",
          }}
        />

        <div className="pb-6 flex justify-between items-center">
          <div className="!font-bold text-2xl text-white pb-4">Other Venues</div>
          <div
            onClick={() => {
              navigateToVenue();
            }}
            style={{zIndex:'100'}}
            className="text-yellow flex items-center gap-x-2 cursor-pointer"
          >
            <div className="text-base">View All</div>
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div className="flex overflow-x-auto gap-4 scrollbar-hide">
          {venues.map((venue, index) => (
            <div
              key={index}
              onClick={() => {
                navigateToVenueDetail(venue);
              }}
              className="relative inline-block flex-none w-[200px] sm:w-[250px] md:w-[300px] h-[130px] sm:h-[150px] md:h-[170px] bg-white rounded-lg shadow-md overflow-hidden"
            >
              <img
                src={
                  venue.bannerPath != undefined &&
                    venue.bannerPath != null &&
                    venue.bannerPath != ""
                    ? venue.bannerPath
                    : venue.picturePath
                }
                alt={venue.venuName}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black/100"></div>
              <div className="absolute bottom-4 left-4 text-white">
                <h3 className="text-lg !font-bold">{venue.venuName}</h3>
                <p className="text-sm underline">{venue.location}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OtherVenuesSection;

