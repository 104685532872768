import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Stack,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

const SelectableDivs = ({
  data,
  title,
  nClick,
  bClick,
  selected,
  setFieldValue,
  fieldValue,
  multiSelect
}) => {
  const initialValues = data ?? [];
  const [selectedDivs, setSelectedDivs] = useState(selected[fieldValue] ?? []);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { palette } = useTheme();

  const handleDivClick = (value) => {
    if(multiSelect != undefined && multiSelect == true){
      setSelectedDivs((prevSelectedDivs) => {
        const isSelected = prevSelectedDivs.includes(value);
  
        if (isSelected) {
          return prevSelectedDivs.filter(
            (selectedValue) => selectedValue !== value
          );
        } else {
          return [...prevSelectedDivs, value];
        }
      });
    }else{
      setSelectedDivs(()=>{
        return [value];
      })
    }
    
  };

  useEffect(() => {
    setFieldValue(fieldValue, selectedDivs);
  }, [fieldValue, selectedDivs, setFieldValue]);

  return (
    <div style={{width:'95%'}}>
      <Box>
        <Typography
          textAlign={"center"}
          fontWeight={400}
          variant="h3"
          color="primary"
          mb={"1rem"}
        >
          {title}
        </Typography>
      </Box>
      <Stack
        style={{ width: "50%", minWidth:'50%', margin: "30px auto" }}
        direction="row"
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent="center"
        gap={"10px"}
      >
        {initialValues.map((value) => (
          <Chip
            key={value}
            onClick={() => handleDivClick(value)}
            style={{
              fontSize: "12px",
              padding: "1rem 0.5rem",
              border: `0.5px solid ${palette.primary.main}`,
              borderRadius: "5px",
              backgroundColor: selectedDivs.includes(value)
                ? palette.primary.main
                : palette.grey[900],
              color: selectedDivs.includes(value) ? palette.grey[900] : "white",
              cursor: "pointer",
            }}
            label={value}
            variant="outlined"
          />
        ))}
      </Stack>
      <Box gridColumn={"span 6"} style={{ textAlign: "center" }}>
        <Button
        data-umami-event-login={title} 
        data-umami-event="Selectable-div-Next-button"
          type="button"
          onClick={nClick}
          sx={{
            width: "40%",
            m: "0rem auto",
            p: ".5rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
          endIcon={<ArrowForwardOutlinedIcon />}
        >
          <Typography fontSize={16} fontWeight={400}>
            {"Next"}
          </Typography>
        </Button>
      </Box>
      <Box gridColumn={"span 6"} style={{ textAlign: "center" }}>
        <Button
        data-umami-event-login={title} 
        data-umami-event="Selectable-div-Next-button"
          onClick={bClick}
          sx={{
            width: "40%",
            m: "0.25rem auto",
            color: "white",
            "&:hover": {
              cursor: "pointer",
              background: "#00000000",
            },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            {"Back"}
          </Typography>
        </Button>
      </Box>
    </div>
  );
};

export default SelectableDivs;
