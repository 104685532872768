import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import Divider from '@mui/material/Divider';
import { nFormatter, currencyFormatter } from 'utilites/utlis'
import FlexBetween from "components/FlexBetween";
import Chart from 'react-apexcharts'

const ProfileInsightWidget = ({ data }) => {
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const { _id, picturePath, userType, userVerified } = useSelector((state) => state.user);
    const { palette } = useTheme();
    const series = [{
        name: "Tickets",
        data: [0,0,0,0,0,0,0,0]
    }];
    const options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },
        dataLabels: {
            enabled: true,
            style:{
                colors:['black']
            }
        },
        tooltip: {
            shared: false,
            intersect: true,
            x: {
                show: false
            }
        },
        fill: {
            colors: ['black']
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Profile visits',
            align: 'left',
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                color: 'white'
            }
        },
        grid: {
            show: false
        },
        colors: ['black'],
        xaxis: {
            categories: ['7 Dec', '8 Dec', '9 Dec', '10 Dec', '11 Dec', '12 Dec', '13 Dec', '14 Dec'],
            labels: {
                style: {
                    colors: 'white',
                    fontWeight: 400
                },
                tooltip: {
                    style: {
                        colors: 'white',
                        fontWeight: 400
                    },
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: 'white',
                    fontWeight: 400
                },
            }
        },
        theme: {
            monochrome: {
                enabled: true,
                color: palette.primary.main,
                shadeTo: 'light',
                shadeIntensity: 0.65
            },
        }
    }
    return (
        <Box>
            <Box
                width="100%"
                minHeight="25vh"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="space-between"
            >


                <Box
                    flexBasis={isNonMobileScreens ? "100%" : undefined}
                    mt={isNonMobileScreens ? undefined : "2rem"}
                >
                    <Typography
                        textAlign={"left"}
                        fontWeight={700}
                        fontSize="20px"
                        color="white"
                        marginBottom={'10px'}
                    >
                        {data.typeLabel}
                    </Typography>
                    <Typography
                        textAlign={"left"}
                        fontWeight={700}
                        fontSize="20px"
                        color="white"
                        style={{ marginTop: '1.5rem' }}
                    >
                        {currencyFormatter((data.totalProfileViews ? data.totalProfileViews : 0))}
                    </Typography>
                    <Typography
                        textAlign={"left"}
                        fontWeight={300}
                        fontSize="12px"
                        color={palette.neutral.medium}
                        style={{ marginTop: '5px' }}
                    >
                        Profile activity
                    </Typography>
                    <FlexBetween paddingRight={'2rem'} marginTop={'1rem'} justifyContent={'space-between'}>
                        <Typography
                            textAlign={"left"}
                            fontWeight={300}
                            fontSize="14px"
                            color={'white'}
                            style={{ marginTop: '5px' }}
                        >
                            Profile visits
                        </Typography>
                        <Typography
                            textAlign={"left"}
                            fontWeight={300}
                            fontSize="16px"
                            color={'white'}
                            style={{ marginTop: '5px' }}
                        >
                            {nFormatter((data.profileViews ? data.profileViews : 0), 2)}
                        </Typography>
                    </FlexBetween>
                    <FlexBetween paddingRight={'2rem'} justifyContent={'space-between'}>
                        <Typography
                            textAlign={"left"}
                            fontWeight={300}
                            fontSize="14px"
                            color={'white'}
                            style={{ marginTop: '5px' }}
                        >
                            Total followers
                        </Typography>
                        <Typography
                            textAlign={"left"}
                            fontWeight={300}
                            fontSize="16px"
                            color={'white'}
                            style={{ marginTop: '5px' }}
                        >
                            {nFormatter((data.totalFollowers ? data.totalFollowers : 0), 2)}
                        </Typography>
                    </FlexBetween>
                </Box>
                <Divider orientation="vertical" flexItem></Divider>
                <Box
                    flexBasis={isNonMobileScreens ? "100%" : undefined}
                    mt={isNonMobileScreens ? undefined : "2rem"}
                >
                    <Chart options={options} series={series} type="line" height={250} />
                </Box>
            </Box>
        </Box >
    );
};

export default ProfileInsightWidget;
