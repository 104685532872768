import React, { useEffect, useState } from "react";
import BlogCard from "./blogCards";
import { Stack, Pagination, Typography } from "@mui/material";
import "../../assests/css/swiper.css";
import { apiUrl } from "config";
import { useNavigate } from "react-router-dom";

const BlogPage = () => {
  const navigate = useNavigate();
  const [blogData, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalBlogs, SetTotalBlogs] = useState(0);
  const [limit] = useState(12);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${apiUrl}/blogs/all-blogs/${page}/${limit}`);
        const data = await response.json();
        setBlogs(data.blogs);
        SetTotalBlogs(data.total);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBlogs();
  }, [page, limit]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getTotalPages = () => {
    return Math.ceil(totalBlogs / limit);
  };

  const navigateToBlogDetails = (blog) => {
    navigate(`/blog-detail/${blog._id}`, { state: blog });
  };

  return (
    <section className="px-4 sm:px-16 py-6 mb-20">
      <Typography
        variant="h2"
        className="font-bold text-xl animate-slide-up pb-6 text-center md:text-left"
      >
        Blogs
      </Typography>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        {blogData.length > 0 ? (
          blogData.map((blog, index) => (
            <div
              key={index}
              onClick={() => navigateToBlogDetails(blog)}
              className="cursor-pointer"
            >
              <BlogCard blog={blog} />
            </div>
          ))
        ) : (
          <Typography variant="h5" className="pb-2 text-neutral-600">
            No Blogs Found
          </Typography>
        )}
      </div>
      <Stack spacing={2} className="mt-8 flex items-center">
        <Pagination
          count={getTotalPages()}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          page={page}
        />
      </Stack>
    </section>
  );
};

export default BlogPage;
