import React, { useRef } from "react";
import { Typography, useTheme, Button, Card, CardContent,Box } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { apiUrl } from "config";
import Logo from "../../assests/logo/Vybez_Logo.png";

const ViewTicketWidget = () => {
  const widgetRef = useRef();
  const { palette } = useTheme();

  const primary = palette.primary.main;
  const primaryMain = palette.primary.main;
  const primaryDark = palette.primary.dark;
  const ticketDetails = useSelector((state) => state.viewTicket);

  const handleDownload = async() => {
    const widget = widgetRef.current;

    domtoimage.toPng(widget).then((dataUrl) => {
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(dataUrl, "PNG", 10, 10, 190, 0);
      pdf.save("ticket.pdf");
    });
  };
  const getTotalTicketsPurchased = () => {
    if (ticketDetails) {
      if (ticketDetails) {
        let seatCounts = 0;
        if (ticketDetails.tickets && ticketDetails.tickets.length > 0) {
          ticketDetails.tickets.map((ele) => {
            seatCounts = seatCounts + ele.ticketPurchaseCount;
          })
        }
        return seatCounts;
      } else {
        return 0
      }
    } else {
      return 0
    }

  }
  return (
    <>
      <WidgetWrapper ref={widgetRef}>
        <Box 
        >
          <FlexBetween
          alignItems='center'
          gap={'2rem'}
          justifyContent='normal'
          style={{height: "10vh",justifyContent:'normal'}}>
          <img
            src={Logo}
            alt={"LOGO"}
            style={{objectFit: "contain",
              height: "10vh"}}
          />
         
          </FlexBetween>
        </Box>
        <FlexBetween
          alignItems="center"
          justifyContent="center"
          height="100%"
          flexDirection="column"
        >
          <img
            src={`${apiUrl}/qr-codes/${ticketDetails.qrCodeName}`}
            alt="QR Code"
            mb="1rem"
            style={{
              width: "50%",
              height: "auto",
              maxWidth: "200px",
              maxHeight: "200px",
            }}
          />
          <Typography variant="h3" color={primary} my="2rem">
            {ticketDetails.eventDetails.eventName}
          </Typography>
        </FlexBetween>

        <Typography
          mb="1rem"
          variant="body2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <CalendarMonthIcon sx={{ fontSize: 16, marginRight: "4px" }} />
          {ticketDetails.eventDetails.eventDate
            ? dayjs(ticketDetails.eventDetails.eventDate).format("DD MMM YYYY")
            : ""} | {ticketDetails.eventDetails.eventStartTime
              ? dayjs(ticketDetails.eventDetails.eventStartTime).format("hh:mm A")
              : ""}
        </Typography>
        <Typography
          mb="1rem"
          variant="body2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <LocationOnIcon sx={{ fontSize: 16, marginRight: "4px" }} />
          {ticketDetails.eventDetails.location}
        </Typography>
        <Typography
          mb="1rem"
          variant="body2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <EventSeatIcon sx={{ fontSize: 16, marginRight: "4px" }} />
          {getTotalTicketsPurchased()} Seats
        </Typography>
        <Typography variant="body1" mb="1rem">
          Organized by: {ticketDetails.eventDetails.firstName} {ticketDetails.eventDetails.lastName}
        </Typography>
        <Card>
          <CardContent>
            <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1"> Name:</Typography>
              <Typography variant="body1">{ticketDetails.name}</Typography>
            </FlexBetween>
            <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">Phone:</Typography>
              <Typography variant="body1">{ticketDetails.contact}</Typography>
            </FlexBetween>
            <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">Email:</Typography>
              <Typography variant="body1">{ticketDetails.email}</Typography>
            </FlexBetween>
            <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">seats:</Typography>
              <Typography variant="body1">
                {getTotalTicketsPurchased()}
              </Typography>
            </FlexBetween>
            <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">Tax:</Typography>
              <Typography variant="body1">
                Rs.{ticketDetails.taxes ?
                  (parseFloat(ticketDetails.taxes).toFixed(2).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",")) : '0.00'}
              </Typography>
            </FlexBetween>
            {/* <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">Coupon:</Typography>
              <Typography variant="body1">
                {paymentDetails.couponAmount}
              </Typography>
            </FlexBetween> */}
            <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">Discount:</Typography>
              <Typography variant="body1">
                Rs.{ticketDetails.discount ?
                  (parseFloat(ticketDetails.discount).toFixed(2).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",")) : '0.00'}
              </Typography>
            </FlexBetween>
            <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">Total:</Typography>
              <Typography variant="body1">
                Rs.{ticketDetails.amount ?
                  (parseFloat(ticketDetails.amount).toFixed(2).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",")) : '0.00'}
              </Typography>
            </FlexBetween>
            {/* <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">Payment Method:</Typography>
              <Typography variant="body1"></Typography>
            </FlexBetween> */}
            <FlexBetween
              justifyContent="space-between"
              alignItems="center"
              my=".5rem"
            >
              <Typography variant="body1">Transaction ID:</Typography>
              <Typography variant="body1">{ticketDetails.razorpayPaymentId}</Typography>
            </FlexBetween>
          </CardContent>
        </Card>
      </WidgetWrapper>
      <Button
        variant="contained"
        onClick={handleDownload}
        sx={{
          backgroundColor: primaryMain,
          color: primaryDark,
          "&:hover": { color: primaryMain },
          p: "0.6rem",
          mt: "1rem",
          width: "100%",
        }}
      >
        Download Ticket
      </Button>
    </>
  );
};

export default ViewTicketWidget;
