import React from "react";
import BackgroundImage from "../../assests/images/background-img.png";
import HeroCarousel from "./HeroCarousel";
import { Typography } from "@mui/material";
import HeroCarouselNew from "./HeroCarouselDivided";

export default function HeroSection() {
  return (
    <div
      className="w-full sm:h-[850px]  h-[700px] bg-cover bg-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="md:px-16 py-10">
        <div className="min-h-58 sm:min-h-58 md:min-h-76 lg:min-h-84 xl:min-h-84">
        {/* <HeroCarousel /> */}
        <HeroCarouselNew/>
          </div>
        <Typography
          variant="h1"
          align="center"
          className="!font-bold leading-loose tracking-wide animate-slide-up text-white text-4xl sm:text-5xl md:text-6xl pt-20"
        >
          LIVE IT.  {"   "} LOVE IT.
        </Typography>
        <Typography
          variant="h1"
          align="center"
          className=" text-yellow !font-bold leading-loose tracking-wide animate-slide-up text-4xl sm:text-5xl md:text-6xl sm:pt-6 pt-2"
        >
          VYBE IT.
        </Typography>
        <Typography
          align="center"
          className="pt-4 text-gray-400 text-sm sm:text-base md:text-lg"
        >
          YOUR CITY - YOUR BEAT. YOUR EXPERIENCE.
        </Typography>
      </div>
    </div>
  );
}
