import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFriends } from "state";
import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";
import { apiUrl } from "./../config";
import dayjs from "dayjs";
import { useEffect,useState } from "react";
import ReactGA from 'react-ga4';

const Friend = ({ friendId, name, subtitle, userPicturePath, postType, postId, loggedInUserId, eventDate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const friends = useSelector((state) => state.user.friends);
  const [eventExpired, setEventExpired] = useState(false);

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const primaryMain = palette.primary.main;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const isFriend = friends.find((friend) => friend._id === friendId);

  const patchFriend = async () => {
    const response = await fetch(
      `${apiUrl}/users/${_id}/${friendId}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };
  const isEventExpired = async()=>{
    let eventDay = dayjs(eventDate);
    let currentDay = dayjs();
    
    let expiry = eventDay.isBefore(currentDay)
    // console.log("date2",expiry,postId)
    setEventExpired(expiry)
  }

  useEffect(() => {
    isEventExpired();
  }, [eventDate]);

  return (
    <FlexBetween>
      <FlexBetween gap="0.5rem">
        <UserImage image={userPicturePath} size="55px"/>
        <Box
        data-umami-event-friend={friendId} 
        data-umami-event="Profile-view-from-post-header"
          onClick={() => {
            navigate(`/profile/${friendId}`);
            navigate(0);
            ReactGA.send({
              hitType:"Profile-view-from-post-header",
              page:'/',
              title:`/profile/${friendId}`
            })
          }}
        >
          <Typography
            color={'white'}
            variant="h6"
            fontWeight="500"
            textTransform="capitalize"
            sx={{
              "&:hover": {
                color: 'white',
                cursor: "pointer",
                textTransform: 'capitalize'
              },
            }}
          >
            {name}
          </Typography>
          <Typography color={'white'} fontSize="0.5rem">
            {subtitle}
          </Typography>
        </Box>
      </FlexBetween>
      <FlexBetween gap="1rem">
        {/* {(postType == 'EVENT' && eventExpired === false) ?
          <Button
            onClick={() =>{
               navigate(`/event/${postId}`)
              }}
            variant="contained"
            sx={{
              backgroundColor: primaryMain,
              color: primaryDark,
              p: "0.6rem",
              "&:hover": { color: palette.primary.main }
            }}
          >
            Book Now
          </Button> : ''} */}
        {
          loggedInUserId === friendId ? '' : <Button
            onClick={() => patchFriend()}
            sx={{color:'white',textTransform:'capitalize', backgroundColor: '#494949', borderRadius:'10px', p: "0.6rem" }}
          >
            {isFriend ? (
              // <PersonRemoveOutlined sx={{ color: primaryDark }} />
              <Typography variant="caption" >Unfollow</Typography>
            ) : (
              // <PersonAddOutlined sx={{ color: primaryDark }} />
              <Typography variant="caption" >Follow</Typography>
            )}
          </Button>
        }

      </FlexBetween>

    </FlexBetween>
  );
};

export default Friend;
