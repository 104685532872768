import React from "react";
import FastMarquee from "react-fast-marquee";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Navbar from "../commons/Navbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "../commons/Footer";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Content from './content'
import { seo } from "utilites/utlis";

const styles = {
    container: {
        overflow: 'hidden'
    },
    card: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'black'

    },

    icon: {
        fontSize: "50px",
    }
};

const termsandConditionPage = () => {
    seo({title:`Terms and Condition`,metaDescription:`Terms and Condition`})
    return (
        <div style={styles.container}>
            {/* <FastMarquee direction="right" gradient={false} style={{ ...styles.marqueeContainer, ...styles.straightMarquee }}>
                YOUR BEAT. YOUR EXPERIENCE. YOUR CITY - YOUR BEAT. YOUR EXPERIENCE. YOUR
                CITY - YOUR BEAT. YOUR EXPERIENCE. YOUR CITY -  YOUR BEAT. YOUR EXPERIENCE.
            </FastMarquee> */}

            <Navbar />

            <div style={{ width: "100%", overflowX: "visible" }}>
                <Box style={styles.card}>
                    <Typography
                        variant="h3"
                        align="center"
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                            fontSize: {
                                xs: "16px",
                                sm: "18px",
                                md: "28px",
                                lg: "48px",
                            },
                            animation: "slide-up 2s ease-out",
                            fontFamily: "'Inter', sans-serif",
                        }}
                    >
                        Terms And Condition
                        <div ><ExpandMoreIcon style={styles.icon} /></div>
                        {/* <span style={{ color: "yellow" }}>VYBE IT.</span> */}
                    </Typography>


                </Box>
            </div>


            <Content />
            {/* <AboutUs /> */}
            <Footer />
        </div>
    );
};

export default termsandConditionPage;
