import React, { useState, useEffect } from "react";
import { Typography, Stack, Pagination } from "@mui/material";
import { apiUrl } from "./../../config";
import "../../assests/css/swiper.css";
import EventsLandingCardWidget from "scenes/landingPage/EventsLandingCardWidget";

const VenueEventsSection = (details) => {
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [page, setPage] = useState(1);

  const getLimit = () => {
    const width = window.innerWidth;
    if (width <= 600) {
      return 1;
    } else {
      return 6;
    }
  };

  const limit = getLimit();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        // console.log("details",details)
        const response = await fetch(`${apiUrl}/posts/${details.venue._id}/tags/${page}/${limit}`);
        const data = await response.json();
        if(data.error == false){
          setFeaturedPosts(data.posts);
          setTotalPosts(data.total);
        }else{
          setFeaturedPosts([]);
          setTotalPosts(0);
        }
       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEventData();
  }, [page, limit, details]);

  const handleChange = (event, value) => {
    setPage(value);
  };



  return (
    <section className="px-4 sm:px-24 py-10">
      <div
        className={`!font-bold text-2xl animate-slide-up pb-6 text-center md:text-left`}
      >
        Don't Miss These Events
      </div>
      {featuredPosts.length === 0 ? (
        <Typography variant="h5" className="pb-2 text-neutral-600">
          No Upcomming Events
        </Typography>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {featuredPosts.map((event) => (
              <EventsLandingCardWidget key={event._id} event={event} />
            ))}
          </div>
          <Stack spacing={2} className="mt-8 flex items-center">
            <Pagination
              count={Math.ceil(totalPosts / limit)}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              page={page}
            />
          </Stack>
        </>
      )}
    </section>
  );
};

export default VenueEventsSection;
