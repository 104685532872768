// UserTypeSelection.js
import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

const UserTypeSelection = ({ setFieldValue, setPageType }) => {
  const { palette } = useTheme();

  const handleUserTypeSelection = (type) => {
    setFieldValue("type", type);
  };

  return (
    <Box
      display="grid"
      style={{ width: "100%", margin: "auto" }}
      gap="20px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      
    >
      <Typography
        textAlign={"center"}
        fontWeight={400}
        variant="h3"
        lineHeight={"48px"}
        color="primary"
        gridColumn="span 4"
        mb={"1rem"}
      >
        Who are you?
      </Typography>
      <Box gridColumn={"span 4"} sx={{ display: "flex" }}>
        <Button
        data-umami-event-login={'I am an Artist'} 
        data-umami-event="Select-user-type-button"
          fullWidth
          type="button"
          onClick={() => {
            handleUserTypeSelection("ARTIST");
            setPageType("profile pic");
          }}
          sx={{
            p: "0.5rem",
            backgroundColor: palette.grey[900],
            color: palette.grey[50],
            border: `1px solid ${palette.primary.main}`,
            "&:hover": { color: palette.primary.main },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            I am an Artist
          </Typography>
        </Button>
      </Box>
      <Box gridColumn={"span 4"}>
        <Button
        data-umami-event-login={'I am an event organizer'} 
        data-umami-event="Select-user-type-button"
          fullWidth
          type="button"
          onClick={() => {
            handleUserTypeSelection("EVENT_ORGANISER");
            setPageType("profile pic");
          }}
          sx={{
            p: "0.5rem",
            backgroundColor: palette.grey[900],
            color: palette.grey[50],
            border: `1px solid ${palette.primary.main}`,
            "&:hover": { color: palette.primary.main },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            I am an event organizer
          </Typography>
        </Button>
      </Box>
      <Box gridColumn={"span 4"}>
        <Button
        data-umami-event-login={'I am an venu'} 
        data-umami-event="Select-user-type-button"
          fullWidth
          type="button"
          onClick={() => {
            handleUserTypeSelection("VENU");
            setPageType("profile pic");
          }}
          sx={{
            p: "0.5rem",
            backgroundColor: palette.grey[900],
            color: palette.grey[50],
            border: `1px solid ${palette.primary.main}`,
            "&:hover": { color: palette.primary.main },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            I am an venu
          </Typography>
        </Button>
      </Box>
      <Box gridColumn={"span 4"}>
        <Button
        data-umami-event-login={'Just looking for VybeZ around me'} 
        data-umami-event="Select-user-type-button"
          fullWidth
          type="button"
          onClick={() => {
            handleUserTypeSelection("VYBER");
            setPageType("location");
          }}
          sx={{
            p: "0.5rem",
            backgroundColor: palette.grey[900],
            color: palette.grey[50],
            border: `1px solid ${palette.primary.main}`,
            "&:hover": { color: palette.primary.main },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            Just looking for VybeZ around me
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default UserTypeSelection;
