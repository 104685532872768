import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { apiUrl } from "config";
import ArtistDisplay from "./ArtistDisplay";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const ArtistsSection = () => {
  const [artists, setArtists] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchArtists();
  }, []);
  const fetchArtists = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/artists/1/16`);
      const data = await response.json();
      setArtists(data.artists);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const navigateToArtists = () => {
    navigate(`/artists`);
  };
  const navigateToArtistsDetail = (artist) => {
    navigate(`/artist-detail/${artist.profileSlug}`);
  };
  return (
    <section className="px-4 sm:px-16 py-6">
      <div
        className="p-4 sm:p-8 rounded-lg relative"
        style={{
          border: "1px solid white",
          overflow: "hidden",
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(-74deg, rgba(79, 79, 79, 1) 0%, rgba(211, 211, 211, 1) 100%)",
            opacity: "23%",
          }}
        />
        <div className="pb-6 flex justify-between items-center">
          <Typography
            variant="h2"
            className={`!font-bold text-xl animate-slide-up text-white text-center md:text-left`}
          >
            Artists
          </Typography>
          <div
            onClick={() => {
              navigateToArtists();
            }}
            className="text-yellow cursor-pointer flex items-center gap-x-2 z-50"
          >
            <div className="text-base">View All</div>
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div className="flex overflow-x-auto gap-4 scrollbar-hide z-50">
          {artists.map((artist) => (
            <div
              onClick={() => {
                navigateToArtistsDetail(artist);
              }}
              className="cursor-pointer"
            >
              <ArtistDisplay artist={artist} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ArtistsSection;
