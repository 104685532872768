import React from "react";


const VenueDetailsection = (details) => {
  // console.log("VenueDetailsection",details.venue)
  return (
    <section className="relative sm:px-16 py-6 pb-10">
      <div
        className="relative mb-6 w-full sm:h-60 h-80 sm:rounded-[20px] bg-cover bg-center"
        style={{ backgroundImage: `url(${details.venue.bannerPath})` }}
      >
        <div className="absolute inset-0 flex sm:gap-x-8 gap-x-6 sm:px-10 px-4 bg-black bg-opacity-50 rounded-[20px]">
          <div className="!w-[20px] h-3/4 bg-yellow z-50 rounded-b-md"></div>
          <div className="flex flex-col justify-around py-6 gap-y-4 text-white">
            <div className="text-base text-gray-300">Venues</div>
            <div className="w-full">
              <div className="flex sm:flex-row flex-col	 gap-x-6">
                <div>
                  <img
                    src={details.venue.picturePath}
                    alt={details.venue.venuName}
                    className="h-40 w-40 rounded-[20px] mb-2 sm:mb-0"
                  />
                </div>
                <div className="flex-grow justify-end flex flex-col gap-y-4">
                  <div>
                    <div className="text-2xl sm:text-3xl font-bold mb-2">
                      {details.venue.venuName}
                    </div>
                    <div className="text-yellow text-base">{details.venue.location}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VenueDetailsection;
