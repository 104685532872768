import React from "react";
import { Box, Button, Typography, Card, CardContent, useTheme } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const CouponCardWidget = ({ coupons }) => {
  const { palette } = useTheme();
  const primary = palette.primary.main;

  return (
    <>
      {coupons.map((coupon, index) => (
        <Card key={index} style={{ marginBottom: "1rem" }}>
          <CardContent style={{ display: "flex", alignItems: "center", gap:"1rem", justifyContent: "space-between" }}>
            <Box
              style={{
                backgroundColor: primary,
                borderRadius: "50%",
                padding: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LocalOfferIcon style={{ color: "#fff" }} />
            </Box>

            <Box style={{ flex: 1, paddingLeft: "1rem" }}>
              <Typography variant="subtitle1" color="primary">
                {coupon.code}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {coupon.description}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Expires on: {coupon.expiresOn}
              </Typography>
            </Box>

            <Button variant="contained" color="primary">
              Use
            </Button>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default CouponCardWidget;
