import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import EventTicketsWidget from "scenes/widgets/EventTicketsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import { seo } from "utilites/utlis";

const EventTicketPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) => state.user);
  const  event  = useSelector((state) => state.event);
  seo({title:`Book Ticket: ${event.eventName}`,metaDescription:`Book Ticket: ${event.eventName}`})
  const ticketsData = [
    {
      eventId: 1,
      ticketName: "Early Bird",
      ticketDescription: "Browse through the icons below to find the one you need. The search field supports synonyms—for example, try searching for",
      ticketPrice: 500,
      spotsLeft: 5,
      ticketCount: 0,
    },
    {
      eventId: 2,
      ticketName: "Diamond",
      ticketDescription: "Browse through the icons below to find the one you need.",
      ticketPrice: 300,
      spotsLeft: 10,
      ticketCount: 0,
    },
    // Add more sample ticket data as needed
  ];

  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        marginBottom='4rem'
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          {/* <UserWidget userId={_id} picturePath={picturePath} /> */}
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "40%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
          mb='4rem'
        >
          <EventTicketsWidget tickets={event.ticketTypes} event={event} />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="28%">
            <AdvertWidget />
            <Box m="2rem 0" />
            <FriendListWidget userId={_id} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EventTicketPage;
