import { Box, useMediaQuery ,useTheme} from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import EventWidget from "scenes/widgets/EventWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import CreateEventForm from 'scenes/widgets/createEvent';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import { seo } from "utilites/utlis";

const CreateEvent = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { palette, typography } = useTheme();
  const { _id, picturePath } = useSelector((state) => state.user);
  const user  = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const params = useParams()
  seo({title:`Create Event`,metaDescription:`Create Event`})
  const navigateBasedOnRoute=(route)=>{
    navigate(`/${route}`)
  }
  
  return (
    <Box>
                <Navbar />
                <Box
                    width="100%"
                    padding="2rem 6%"
                    display={isNonMobileScreens ? "flex" : "block"}
                    gap="0.5rem"
                    justifyContent="space-between"
                >
                   
                    <Box
                        flexBasis={isNonMobileScreens ? "70%" : undefined}
                        mt={isNonMobileScreens ? undefined : "2rem"}
                    >
                        <CreateEventForm eventId={params.eventId} navigateBasedOnRoute={navigateBasedOnRoute} token={token} userId={_id} user={user} theme={{palette:palette,typography:typography,isNonMobileScreens:isNonMobileScreens}}></CreateEventForm>
                    </Box>
                    {isNonMobileScreens && (
                        <Box flexBasis="28%">
                            <AdvertWidget />
                            <Box m="2rem 0" />
                            <FriendListWidget userId={_id} />
                        </Box>
                    )}
                </Box>
            </Box>
  );
};

export default CreateEvent;
