import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Divider,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiUrl } from "config";
import { useSelector,useDispatch } from "react-redux";
import dayjs from "dayjs";
import { setViewETicket } from "state";
import { cloneDeep } from "lodash";


const BookingHistoryCardWidget = ({ active, completed }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [eventsBooked, setEventBooked] = useState([]);
  const dispatch = useDispatch();
  const fetchBookedHistory = async () => {
    let url = '';
    if (completed === true) {
      url = "/checkout/previous-events"
    } else {
      url = "/checkout/upcomming-events"
    }
    const response = await fetch(`${apiUrl}${url}`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      body: JSON.stringify({ userId: user._id }),
    });
    const data = await response.json(response);
    if (data.error == false) {
      console.log("data", data)
      setEventBooked(data.bookingHistory)
    }

  }
  const getTotalTicketsPurchased = (eventDetails) => {
    if (eventDetails) {
      let seatCounts = 0;
      if (eventDetails.tickets && eventDetails.tickets.length > 0) {
        eventDetails.tickets.map((ele) => {
          seatCounts = seatCounts + ele.ticketPurchaseCount;
        })
      }
      return seatCounts;
    } else {
      return 0
    }

  }
  const navigateToViewTicket=(event)=>{
    // let purchasedTicketDetails = {};
    // let index = event.tickets.findIndex((ele)=> ele.ticketPurchaseCount > 0);
    // purchasedTicketDetails = event.tickets[index]
    // let updatedEvent = cloneDeep(event)
    // updatedEvent['purchasedTicketDetails'] = purchasedTicketDetails;
    dispatch(setViewETicket(event));
    setTimeout(()=>{
      navigate(`/viewticket`)
    },1000)
  }
  useEffect(() => {
    fetchBookedHistory();
  }, [completed]);

  return (
    <>
      {eventsBooked.length === 0 ?
          <Box width="100%" textAlign={'center'} margin={'1rem'}>
            <Typography variant="h4" pb="1rem" sx={{ color: primary }}>
              No Active Events
            </Typography>
          </Box>
      
        : ''}
      {eventsBooked.map((event) => (
        <Card sx={{ margin: "10px", padding: "20px", borderRadius: "8px" }}>
          <FlexBetween justifyContent="center" alignItems="center" gap="0.75rem">
            <Box width="35%">
              <img
                width="100%"
                height="auto"
                alt="Party Image"
                style={{ borderRadius: "0.75rem", marginTop: "0.50rem" }}
                src={`${event.eventDetails.picturePath}`}
              />
            </Box>
            <Box width="60%">
              {/* <Typography variant="p" pb="1rem" sx={{ color: main }}>
                Ticket Name
              </Typography> */}
              <Typography variant="h4" pb="1rem" sx={{ color: primary }}>
                {event.eventDetails.eventName}
              </Typography>
            </Box>
          </FlexBetween>
          <Divider />
          <FlexBetween py=".75rem">
            <Typography
              variant="body2"
              sx={{ color: main, display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon sx={{ fontSize: 16, marginRight: "4px" }} />
              {event.eventDetails.location}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: main, display: "flex", alignItems: "center" }}
            >
              <CalendarMonthIcon sx={{ fontSize: 16, marginRight: "4px" }} />
              {event.eventDetails.eventDate
                ? dayjs(event.eventDetails.eventDate).format("DD MMM YYYY")
                : ""} ({event.eventDetails.eventStartTime
                  ? dayjs(event.eventDetails.eventStartTime).format("hh:mm A")
                  : ""})
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: main, display: "flex", alignItems: "center" }}
            >
              <EventSeatIcon sx={{ fontSize: 16, marginRight: "4px" }} />
              {getTotalTicketsPurchased(event)} Seats
            </Typography>
          </FlexBetween>
          <Divider />
          {/* {active && (
   <FlexBetween>
     <Button
       variant="contained"
       color="primary"
       sx={{ width: "48%", marginRight: "2%" }}
       style={{ marginTop: 16 }}
     >
       Cancel Booking
     </Button>
     <Button
       variant="contained"
       onClick={() => navigate(`/viewticket`)}
       color="secondary"
       sx={{ width: "48%" }}
       style={{ marginTop: 16 }}
     >
       View E-Ticket
     </Button>
   </FlexBetween>

 )} */}

          <Box>
            <Button
              variant="contained"
              onClick={() => {navigateToViewTicket(event)}}
              color="primary"
              sx={{ width: "100%" , "&:hover": { color: palette.primary.main }}}
              style={{ marginTop: 16 }}
            >
              View E-Ticket
            </Button>
          </Box>

        </Card>
      ))}

    </>

  );
};

export default BookingHistoryCardWidget;
