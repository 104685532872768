import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import { apiUrl } from "./../../config";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import InsightsIcon from '@mui/icons-material/Insights';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import { seo } from "utilites/utlis";
const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  const token = useSelector((state) => state.token);
  const loggedInUser = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [imgErr, setImgErr] = useState(false);
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;
  const navigate = useNavigate();

  const getUser = async () => {
    const response = await fetch(`${apiUrl}/users/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    updateInsights(data)
    setUser(data);
    seo({title:`${data.firstName} ${data.lastName}`,metaDescription:`Profile Details ${data.firstName} ${data.lastName}`})

  };
  const updateInsights = async (data) => {
    if (loggedInUser._id !== userId) {
      const response = await fetch(`${apiUrl}/insights`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          insightType: "PROFILE_VIEW",
          eventId: null,
          viewedByUserId: loggedInUser._id,
          profileId: userId
        })
      });
    }

  };
  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) return null;

  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        padding="1rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="2rem"
        justifyContent="center"
        backgroundColor="#1A1A1A"
      >

        <Box
          flexBasis={isNonMobileScreens ? "70%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
          paddingBottom='4rem'
        >
          <div style={{ margin: "2rem 0", display: 'flex', alignItems: 'end', backgroundColor: '#272727', borderRadius: '10px', height: '300px' }}>

            <div style={{
              width: "150px",
              height: "150px",
              padding: "10px",
              marginLeft: "2rem",
              marginBottom: '2rem',
              borderRadius: "50px",
              background: "linear-gradient(207.81deg, #D7C415 0.1%, rgba(73, 73, 73, 0) 114.33%)",
              border: "2px",
              borderImageSource: "linear-gradient(207.81deg, #D7C415 0.1%, rgba(73, 73, 73, 0) 114.33%)"
            }}>
              <div
                style={{
                  width: "130px",
                  height: "130px",
                  borderRadius: "50px",
                  background: "#4c4c4ce8"
                }}>{imgErr == false ? <img
                  style={{
                    objectFit: "cover", width: "130px",
                    height: "130px", borderRadius: "50px"
                  }}
                  alt="user"
                  src={`${user.picturePath}`}
                  onError={() => { setImgErr(true) }} /> : ''}</div>


            </div>
            {isNonMobileScreens ?
              <div style={{ marginLeft: "2rem", marginBottom: '2rem' }}>
                <Typography variant="h2" style={{ textTransform: 'capitalize' }}>
                  {user.firstName} {user.lastName} <sup>{user.userVerified === true ? <><VerifiedIcon fontSize="medium" sx={{ color: palette.primary.main }} /></> : ''}  </sup></Typography>
                <Typography variant="caption" style={{ fontSize: '12px', textTransform: 'capitalize' }}> 
                  {user.userType === 'EVENT_ORGANISER' ? 'Organiser' : (user.userType === 'ARTIST' ? 'Artist' : (user.userType === 'VENU' ? 'Venu' : ''))}
                  </Typography>
              </div>
              : ''}


          </div>
          {isNonMobileScreens ? '' :
            <div style={{ marginBottom: '2rem' }}>
              <Typography variant="h2" style={{ textTransform: 'capitalize', fontSize: '20px' }}>
                {user.firstName} {user.lastName} <sup>{user.userVerified === true ? <><VerifiedIcon fontSize="medium" sx={{ color: palette.primary.main }} /></> : ''}  </sup></Typography>
              <Typography variant="caption" style={{ fontSize: '12px', textTransform: 'capitalize' }}> {user.userType === 'EVENT_ORGANISER' ? 'Organiser' : (user.userType === 'ARTIST' ? 'Artist' : (user.userType === 'VENU' ? 'Venu' : ''))}</Typography>
            </div>
          }

          {loggedInUser._id !== userId ? '' :
            <>
              <div>
                <div>
                  {(loggedInUser.userType === 'EVENT_ORGANISER' || loggedInUser.userType === 'VENU')?
                    <Button
                      onClick={() => navigate(`/verify-vendor`)}
                      variant="contained"
                      style={{ padding: '10px', marginRight: '1rem', marginTop: '10px', textTransform: 'capitalize', borderRadius: '10px' }}
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        "&:hover": { color: palette.primary.main },
                        p: "0.1rem",
                      }}
                    >
                      {((loggedInUser.userType === 'EVENT_ORGANISER' || loggedInUser.userType === 'VENU') && loggedInUser.userVerified === false) ? <><VerifiedUserIcon fontSize="medium" sx={{ color: 'white', marginRight: '10px' }} /> Get Verified </> : (((loggedInUser.userType === 'EVENT_ORGANISER' || loggedInUser.userType === 'VENU') && loggedInUser.userVerified === true) ? <><EditRoundedIcon fontSize="medium" sx={{ color: 'white', marginRight: '10px' }} /> Edit Profile</> : '')}
                    </Button> : ''}

                  {loggedInUser.userType === 'ARTIST' ?
                    <Button
                      onClick={() => navigate(`/verify-artist`)}
                      variant="contained"
                      style={{ padding: '10px', marginRight: '1rem', textTransform: 'capitalize', marginTop: '10px', borderRadius: '10px' }}
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        "&:hover": { color: palette.primary.main },
                        p: "0.1rem",
                      }}
                    >
                      {(loggedInUser.userType === 'ARTIST' && loggedInUser.userVerified === false) ? <><VerifiedUserIcon fontSize="medium" sx={{ color: 'white', marginRight: '10px' }} /> Get Verified </> : ((loggedInUser.userType === 'ARTIST' && loggedInUser.userVerified === true) ? <><EditRoundedIcon fontSize="medium" sx={{ color: 'white', marginRight: '10px' }} />Edit Profile</> : '')}
                    </Button> : ''}
                  {((loggedInUser.userType === 'ARTIST' || loggedInUser.userType === 'EVENT_ORGANISER' || loggedInUser.userType === 'VENU')) ?
                    <Button
                      onClick={() => navigate(`/dashboard`)}
                      variant="contained"
                      style={{ padding: '10px', marginRight: '1rem', marginTop: '10px', textTransform: 'capitalize', borderRadius: '10px' }}
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        "&:hover": { color: palette.primary.main },
                        p: "0.1rem",
                      }}
                    >
                      <InsightsIcon fontSize="medium" sx={{ color: 'white', marginRight: '10px' }} /> Insights
                    </Button>
                    : ''}
                  {((loggedInUser.userType === 'ARTIST' || loggedInUser.userType === 'EVENT_ORGANISER' || loggedInUser.userType === 'VENU')) ?
                    <Button
                      onClick={() => navigate(`/my-events`)}
                      variant="contained"
                      style={{ padding: '10px', marginRight: '1rem', marginTop: '10px', textTransform: 'capitalize', borderRadius: '10px' }}
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        "&:hover": { color: palette.primary.main },
                        p: "0.1rem",
                      }}
                    >
                      <LocalActivityRoundedIcon fontSize="medium" sx={{ color: 'white', marginRight: '10px' }} /> My Events
                    </Button>
                    : ''}

                  <Button
                    onClick={() => navigate(`/bookinghistory`)}
                    variant="contained"
                    style={{ padding: '10px', marginRight: '1rem', marginTop: '10px', textTransform: 'capitalize', borderRadius: '10px' }}
                    sx={{
                      backgroundColor: 'black',
                      color: 'white',
                      "&:hover": { color: palette.primary.main },
                      p: "0.1rem",
                    }}
                  >
                    <ConfirmationNumberRoundedIcon fontSize="medium" sx={{ color: 'white', marginRight: '10px' }} /> Booking History
                  </Button>

                </div>
              </div>
              <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                <Typography marginBottom='1rem' variant="h6" fontSize={'28px'} textAlign={'left'} letterSpacing={'0.1rem'} >Posts</Typography>
                <MyPostWidget picturePath={user.picturePath} />
              </div></>
          }


          <Box m="2rem 0" />
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Typography marginBottom='1rem' variant="h6" fontSize={'28px'} textAlign={'left'} letterSpacing={'0.1rem'} >{loggedInUser._id !== userId ? 'Feeds': 'My Feeds'}</Typography>
            <PostsWidget userId={userId} isProfile />
          </div>
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="28%">
            <AdvertWidget />
            <Box m="1rem 0" />
            <FriendListWidget userId={loggedInUser._id} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfilePage;
