// RegisterForm.jsx
import React from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
const RegisterForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  click,
}) => {
  const { palette, typography } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box
      display="grid"
      gap="20px"
      gridTemplateColumns="repeat(6, minmax(0, 1fr))"
      style={{ width: "100%", margin: "auto" }}
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
      }}
    >
     <Typography variant='h3' gridColumn="span 6" color='primary'>Personal details</Typography>
      {/* Title */}
      {/* <Typography
        textAlign={"center"}
        fontWeight={400}
        fontFamily={typography.fontFamily[0]}
        fontSize="48px"
        lineHeight={"48px"}
        gridColumn="span 6"
      >
        VybeZ
      </Typography> */}

      {/* Subtitle */}
      {/* <Typography
        textAlign={"center"}
        fontWeight={400}
        fontFamily={typography.fontFamily[0]}
        fontSize="16px"
        lineHeight={"36px"}
        variant="h5"
        color="primary"
        letterSpacing={"1px"}
        sx={{ mb: "0.5rem" }}
        gridColumn="span 6"
      >
        Start your frolicking!
      </Typography> */}

      {/* First Name */}
      <TextField
        label="First Name"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.firstName}
        name="firstName"
        error={Boolean(touched.firstName) || Boolean(errors.firstName)}
        helperText={touched.firstName || errors.firstName}
        sx={{ gridColumn: "span 6" }}
      />

      {/* Last Name */}
      <TextField
        label="Last Name"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.lastName}
        name="lastName"
        error={Boolean(touched.lastName) || Boolean(errors.lastName)}
        helperText={touched.lastName || errors.lastName}
        sx={{ gridColumn: "span 6" }}
      />

      <TextField
        label="Location"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.location}
        name="location"
        error={Boolean(touched.location) || Boolean(errors.location)}
        helperText={touched.location || errors.location}
        sx={{ gridColumn: "span 6" }}
      />

      {/* Occupation */}
      <TextField
        label="Occupation"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.occupation}
        name="occupation"
        error={Boolean(touched.occupation) || Boolean(errors.occupation)}
        helperText={touched.occupation || errors.occupation}
        sx={{ gridColumn: "span 6" }}
      />

      {/* Dropzone for Picture */}
      <Box
        gridColumn="span 6"
        border={`1px solid ${palette.neutral.medium}`}
        borderRadius="5px"
        p="1rem"
      >
        <Dropzone
          acceptedFiles=".jpg,.jpeg,.png"
          multiple={false}
          onDrop={(acceptedFiles) => setFieldValue("picture", acceptedFiles[0])}
        >
          {({ getRootProps, getInputProps }) => (
            <Box
              {...getRootProps()}
              border={`2px dashed ${palette.primary.main}`}
              p="1rem"
              sx={{
                "&:hover": { cursor: "pointer" },
                border: `2px dashed ${
                  errors.picture ? palette.error.main : palette.primary.main
                }`,
              }}
            >
              <input {...getInputProps()} />
              {!values.picture ? (
                <Typography>{"Add Picture Here"}</Typography>
              ) : (
                <FlexBetween>
                  <Typography>{values.picture.name}</Typography>
                  <EditOutlinedIcon />
                </FlexBetween>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
      {/* Email */}
      <TextField
        label="Email"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
        name="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon />
            </InputAdornment>
          ),
        }}
        error={Boolean(touched.email) && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        sx={{ gridColumn: "span 6" }}
      />

      {/* Password */}
      <TextField
        label="Password"
        type="password"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.password}
        name="password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
        error={Boolean(touched.password) && Boolean(errors.password)}
        helperText={touched.password && errors.password}
        sx={{ gridColumn: "span 6" }}
      />
      <Box gridColumn="span 6">
        <Button
        data-umami-event-login={values.phone} 
        data-umami-event="Register-Button"
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            {"Register"}
          </Typography>
        </Button>
        <Typography
        data-umami-event="Login-Button-To-Navigate-Login-Page"
          textAlign={"center"}
          onClick={click}
          sx={{
            textDecoration: "underline",
            color: palette.primary.main,
            "&:hover": {
              cursor: "pointer",
              color: palette.primary.light,
            },
          }}
        >
          {"Already have an account? Login here."}
        </Typography>
      </Box>
    </Box>
  );
};

export default RegisterForm;
