import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "./../../config";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { DataGrid } from '@mui/x-data-grid';
import dayjs from "dayjs";
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { toast } from "react-toastify";
import { seo } from "utilites/utlis";

const MyEventsBookingListWidget = (eventId) => {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const [eventData, setEvent] = useState({});
  const token = useSelector((state) => state.token);
  const { _id, picturePath, userType, userVerified } = useSelector((state) => state.user);
  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const primaryDark = palette.primary.dark;
  const primaryMain = palette.primary.main;
  const [rowCount, setRowCount] = useState(0);
  const columns = [

    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'email', headerName: 'Email Id', width: 130 },
    { field: 'contact', headerName: 'Mobile No', width: 130 },
    {
      field: 'tickets', headerName: 'Tickets Purchased', width: 130,
      valueGetter: (value, row) => {
        if (row.tickets) {
          let ticketStr = "";
          row.tickets.map((ele, index) => {
            ticketStr =
              ticketStr +
              ele.name +
              ": " +
              ele.ticketPurchaseCount +
              (index == row.tickets.length - 1 ? "" : ", ");
          });
          return ticketStr;
        } else {
          return "";
        }
      }
    },
    { field: 'noOfTicketsPurchased', headerName: 'Total No of Tickets', width: 130 },
    {
      field: 'createdAt', headerName: 'Booking Date', width: 130,
      valueGetter: (value, row) => `${dayjs(value).format("DD-MM-YYYY hh:mm")}`
    },
    { field: 'amount', headerName: 'Total Payment(in Rs)', width: 130 },
    {
      field: 'paymentStatus', headerName: 'Payment Status', width: 130,
      valueGetter: (value, row) => `${(value == 'PAYMENT_SUCCESS' ? 'Paid' : 'Pending')}`
    },



  ];
  const getPosts = async (e) => {
    try {
      let body = {
        eventId: eventId.eventId,
        limit: e.pageSize,
        page: e.page + 1
      };

      const response = await fetch(`${apiUrl}/checkout/event-bookings`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(body)
      });
      const data = await response.json();
      if (data.error == false) {
        let updatedBookings = data.bookings;
        updatedBookings.map((ele) => {
          ele['id'] = ele._id
        })
        setEvent(data.eventDetails)
        setPosts(updatedBookings)
        setRowCount(data.total ?  data.total : updatedBookings.length)
        seo({title:`Bookings:${data.eventDetails.eventName}`,metaDescription:`Bookings:${data.eventDetails.eventName}`})
      } else {
        setPosts([])

      }
    } catch (e) {
      setPosts([])
    }

  };

  const downloadXLSX=async()=>{
    try{
      const response = await fetch(`${apiUrl}/checkout/download-event-bookings`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({ eventId: eventData._id }),
        responseType:'blob'
      });
      const data = await response.blob();
      const href = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `bookings_${eventData.eventName}_${new Date().getTime()}.xlsx`); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("File is being downloaded")
    }catch(e){
      toast.error("Unable to download booking. Try again or Contact support")
    }
   
  }
  useEffect(() => {

    getPosts(paginationModel);

  }, []);

  return (
    <>
      <Box
        width="100%"
        padding="2rem 3rem"
        marginBottom={'4rem'}
        // display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >

        <Box mb={ "1rem"}>
          <FlexBetween>
            <Typography variant="h3" color={palette.primary.main}  textAlign={'left'} letterSpacing={'0.1rem'} >{eventData.eventName}</Typography>
            {/* <Typography variant="caption" fontSize={'14px'} textAlign={'right'} letterSpacing={'0.1rem'} color={'primary'}> Download Bookings</Typography> */}
            <Button
          onClick={() => {
            downloadXLSX()
          }}
          variant="contained"
          textAlign={'right'}
          sx={{
            backgroundColor: primaryMain,
            color: primaryDark,
            "&:hover": { color:  palette.primary.main },
            p: "0.6rem",
            borderRadius:'10px',
          }}
        >
          <ArrowCircleDownRoundedIcon color={primaryDark} sx={{mr: "0.5rem"}}/> Bookings
        </Button>
          </FlexBetween>
        </Box>


        <DataGrid
          rows={posts}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel
            },
            pinnedColumns: { left: ['name'] }
          }}
          pageSizeOptions={[10, 20, 50, 100, 200]}
          rowCount={rowCount}
          paginationMode="server"
          onPaginationModelChange={(e) => {
            setPaginationModel(e);
            getPosts(e)
          }}
          sx={{ overflowX: 'scroll', boxShadow: 2,height: '60vh' }}
        />

      </Box>


    </>
  );
};

export default MyEventsBookingListWidget;
