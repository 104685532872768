import React from "react";
import { Container, Typography, Grid, Link } from "@mui/material";
import {
    Facebook,
    Instagram,
    Twitter,
} from "@mui/icons-material";
import Logo from "../../assests/logo/Vybez_Logo.png";

const styles = {
    footerBody: {
        // textAlign: 'center'
    },
    footerLinks: {
        fontFamily: "'Inter' sans-serif"
    }
}
const Footer = () => {
    return (

        <div
            style={{
                color: "white",
                padding: "32px",
            }}
        >
            <Container style={{ padding: '5% 0' }}>
                <Typography variant="h2" mb="4rem">
                    VYBEZ: WHERE EVERY BEAT CREATES MEMORIES
                </Typography>
                <Grid container spacing={4} style={styles.footerBody}>
                    <Grid item xs={12} md={3}>
                        <img
                            src={Logo}
                            alt="Logo"
                            style={{ maxWidth: "50%", height: "auto", marginBottom: "20px" }}
                        />
                        <Typography>
                            Vybez, the ultimate platform for those who love to explore new
                            events, venues, and artists.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography
                            variant="h4"
                            style={{ fontWeight: "400", marginBottom: "30px",  }}
                        >
                            OFFICE
                        </Typography>
                        <Typography>
                            VYBEZ C/O ANANTALOOP 4th Floor, 9th Cross Rd, 2nd Phase, J. P.
                            Nagar, Bengaluru, Karnataka 560078 help@vybez.in
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography
                            variant="h4"
                            style={{ fontWeight: "400", marginBottom: "20px",  }}
                        >
                            Links
                        </Typography>
                        <ul style={{ listStyle: "none", padding: "0px", textAlign: "left" }}>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px", // Increase font size
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },
                                        
                                    }}
                                >
                                    Home
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/privacy-policy"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px",
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },
                                        
                                    }}
                                >
                                    Privacy Policy
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/refund-policy"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px",
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },
                                        
                                    }}
                                >
                                    Refund Policy
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/terms-and-conditions"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px",
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },
                                        
                                    }}
                                >
                                    Terms and Conditions
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/contact"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px", // Increase font size
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },
                                        
                                    }}
                                >
                                    Contact Us
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href="/about-us"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px", // Increase font size
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },
                                        
                                    }}
                                >
                                    About Us
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" style={{ textAlign: 'center', fontWeight: "400", marginBottom: "30px",  }}>Social Media</Typography>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginTop: "20px",
                            }}
                        >
                            <Link
                                href="https://www.facebook.com/vybezurbanfrolic"
                                aria-label="Facebook"
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "24px",
                                    marginRight: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#ffd700" },
                                    
                                }}
                            >
                                <Facebook />
                            </Link>
                            <Link
                                href="https://www.instagram.com/vybezoficial/"
                                aria-label="Instagram"
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "24px",
                                    marginRight: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#ffd700" },
                                    
                                }}
                            >
                                <Instagram />
                            </Link>
                            <Link
                                href="https://twitter.com/VybezOficial"
                                aria-label="Twitter"
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "24px",
                                    marginRight: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#ffd700" },
                                    
                                }}
                            >
                                <Twitter />
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;
