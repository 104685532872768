import { Typography } from "@mui/material";
import { apiUrl } from "config";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const MoreBlogSection = () => {
  const navigate = useNavigate();
  const [blogData, setBlogs] = useState([]);
  useEffect(() => {
    fetchBlogs();
  }, []);
  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${apiUrl}/blogs/all-blogs/1/9`);
      const data = await response.json();
      setBlogs(data.blogs);
      console.log("fetchBlogs", data.blogs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function truncateText(text) {
    if (text) {
      if (text.length > 40) {
        return text.slice(0, 75) + "...";
      }
    }
    return text;
  }
  const navigateToBlogDetails = (blog) => {
    navigate(`/blog-detail/${blog._id}`, { state: blog });
  };
  const navigateToBlog = () => {
    navigate(`/blog`);
  };
  return (
    <section className="px-4 sm:px-16 py-6">
      <div className="pb-6 flex justify-between items-center">
        <div
          className={`font-bold text-xl sm:text-2xl animate-slide-up text-center text-left`}
        >
          Discover More Blogs
        </div>
        <div
          onClick={() => {
            navigateToBlog();
          }}
          className="text-yellow flex items-center gap-x-2 cursor-pointer"
        >
          <div className="text-base">View All</div>
          <ArrowForwardIosIcon />
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="flex overflow-x-auto space-x-4 scrollbar-hide">
          {blogData.map((blog, index) => (
            <div
              key={index}
              onClick={() => {
                navigateToBlogDetails();
              }}
              className="relative inline-block flex-none w-[300px] sm:w-[400px] h-[230px] sm:h-[320px] bg-[#2A2A2A]
              rounded-[20px] shadow-md overflow-hidden cursor-pointer"
            >
              <div>
                <img
                  src={blog.blogBannerPath}
                  alt={blog.blogTitle}
                  className="w-full h-[140px] sm:h-[200px] object-cover"
                />
                <div className="p-3 sm:p-4">
                  <h2 className="text-sm sm:text-lg font-semibold mb-2">
                    {truncateText(blog.blogTitle)}
                  </h2>
                  <div className="text-xs text-yellow">
                    {" "}
                    {blog.createdAt
                      ? dayjs(blog.createdAt).format("MMMM DD, YYYY")
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MoreBlogSection;
