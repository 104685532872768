import React, { Component } from 'react';
import { InstagramLogin } from '@amraneze/react-instagram-login';
import instgramLogo from '../../assests/images/instagram.png';
import { Typography } from '@mui/material';
import LoginForm from './loginForm';
import SignUpFlow from './signUpFlow';
class InstagramLoginPage extends Component {
    constructor(props) {
        super(props);

    }



    componentDidMount() {

    }


    componentDidUpdate(prevProps, prevState) {

    }


    render() {
        return (
            <div>
                {/*App secret  4a1dad913b9e4dd0962e30595cb55447 */}
               
                {/* <InstagramLogin
                    clientId="717752780432368"
                    cssClass='inst-btn'
                    scope="user_profile,user_media"
                    implicitAuth={false}
                    onSuccess={(e) => {
                        console.log("Success", e)
                    }}
                    onFailure={(e) => {
                        console.log("failure", e)
                    }}
                >
                    <img style={{paddingLeft:'18px'}} height={'25px'} weight={'25px'} src={instgramLogo}></img>
                    <span style={{paddingLeft:'15px',paddingRight:'18px'}}>Continue with Instagram</span></InstagramLogin> */}

                <SignUpFlow />
            </div>
        );
    }
}

export default InstagramLoginPage;