
export function nFormatter(num, digits,minNumber) {
    if(isNaN(num)){
        num = parseInt(num)
    }
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  export function currencyFormatter(number){
    const formatter = new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 3 });
    if(number && isNaN(number)){
        number = parseInt(number)
    }
    return formatter.format(number)
  }

  export function seo(data = {}) {
    data.title = data.title || 'Vybez - Urban Frolic';
    data.metaDescription = data.metaDescription || 'Vybez - A community driven event booking site';
  
    document.title = data.title;
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
  }

