import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import VerificationWidget from "scenes/widgets/VendorVerificationWidget";
import { setLogin } from "state";

const VendorVerification = () => {
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const { palette } = useTheme();
    const dispatch = useDispatch();

    const setUpdatedUserDetails = (userDetails) => {
        dispatch(
            setLogin({
                user: userDetails,
                token: token
            })
        );
    }

    return (
        <Box>
            <Navbar />
            <Box
                width="100%"
                padding="2rem 6%"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="space-between"
            >
                {/* {isNonMobileScreens && (
                    <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
                        <UserWidget userId={user._id} picturePath={user.picturePath} />
                    </Box>
                )} */}

                <Box
                 paddingBottom='4rem'
                    flexBasis={isNonMobileScreens ? "70%" : undefined}
                    mt={isNonMobileScreens ? undefined : "2rem"}
                >
                    <VerificationWidget setUpdatedUserDetails={setUpdatedUserDetails} token={token} user={user} palette={palette} theme={palette} />
                </Box>
                {isNonMobileScreens && (
                    <Box flexBasis="29%">
                        <AdvertWidget />
                        <Box m="2rem 0" />
                        <FriendListWidget userId={user._id} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default VendorVerification;
