import React from "react";
import { Grid, Box, Typography, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const AboutUs = () => {
  return (
    <Grid container lg={{ margin: "20px ", }}  >
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '80vh', overflow: 'hidden', padding: '20px' }}>
        <Box sx={{ maxWidth: 600 }}>
          <Typography variant="h6" sx={{
            fontFamily: "'Inter', sans-serif",
          }} >
            Contact Us
          </Typography>
          <br />
          <Typography variant="h2" paragraph sx={{
            fontFamily: "'Inter', sans-serif",
          }}>
            ANY QUESTIONS? GET IN TOUCH!
          </Typography>
          <Typography variant="body1" paragraph>
            Anantaloop, 4th Floor, 9th Cross Rd, 2nd Phase, J. P. Nagar, Bengaluru, Karnataka 560078
          </Typography>
          <br />

          <Typography variant="body1" paragraph color="black">
            <EmailIcon fontSize="small" style={{ marginRight: '5px', verticalAlign: 'middle', color: 'white' }} />
            <a href="mailto:help@vybez.in" style={{ color: 'white', textDecoration: 'none' }}>help@vybez.in</a>
          </Typography>

        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '100px 20px', order: { xs: 1, md: 2 } }}>
        <Box sx={{ maxWidth: 600 }}>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField label="Name" fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Email" fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Phone" fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Subject" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField label="How can I help you?" multiline rows={4} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel control={<Checkbox />} label="I agree that my data is collected" />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" fullWidth>
                  Get in Touch
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid >
  );
};

export default AboutUs;
