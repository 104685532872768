// LoginForm.jsx
import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { toast } from 'react-toastify';
import { apiUrl } from "config";
import { useNavigate } from "react-router-dom";
import { GAEvent } from "components/tracking";

const OtpForm = ({ values, errors, touched, handleBlur, handleChange }) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [validPhone, setValidPhone] = useState(true);
  const [enableVerifyOtpBtn, setEnableVerifyOtp] = useState(false);
  const [otpSecret, setOTPSecret] = useState(null);
  const navigate = useNavigate();
  const [enableNext, setNext] = useState(false);
  const [reTriggerSeconds, setRetriggerSecs] = useState(60);
  const [enableRetriggerOtp, setRetriggerOtp] = useState(false);
  const sendOtp = async () => {
    if (validPhone == true) {
      const otpSentResponse = await fetch(`${apiUrl}/auth/send-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phone: values.phone
        }),
      });
      const sentOtp = await otpSentResponse.json();
      if (sentOtp.error == false) {
        toast.success(sentOtp.message);
        setEnableVerifyOtp(true);
        setOTPSecret(sentOtp.token)
        setRetriggerOtp(false);
        setRetriggerSecs(60)
        let secs = 60;
        let otpInterval = setInterval(() => {
          secs = secs - 1;
          setRetriggerSecs(secs);
          if (secs == 0) {
            setRetriggerOtp(true);
            clearInterval(otpInterval)
          }
        }, 1000)
      } else {
        toast.error(sentOtp.message)
      }
    }
  }
  const verifyOtp = async () => {
    if (validPhone == true) {
      const otpSentResponse = await fetch(`${apiUrl}/auth/verify-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phone: values.phone,
          otp: values.otp,
          token: otpSecret
        }),
      });
      const sentOtp = await otpSentResponse.json();
      if (sentOtp.error == false) {
        toast.success(sentOtp.message);
        setEnableVerifyOtp(false)
        setRetriggerOtp(false)
        setNext(true)
      } else {
        toast.error(sentOtp.message)
      }
    }
  }
  return (
    <Box
      display="grid"
      style={{ width: "100%", margin: "auto" }}
      gap="20px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
      }}
    >
       <Typography variant='h3' gridColumn="span 6" color='primary'>Create your account</Typography>
      {/* Title */}
      {/* <Typography
        textAlign={"center"}
        fontWeight={400}
        fontSize="48px"
        lineHeight={"48px"}
        gridColumn="span 4"
      >
        VybeZ
      </Typography> */}

      {/* Subtitle */}
      {/* <Typography
        textAlign={"center"}
        fontWeight={400}
        fontSize="16px"
        lineHeight={"36px"}
        variant="h5"
        color="primary"
        letterSpacing={"1px"}
        sx={{ mb: "1rem" }}
        gridColumn="span 4"
      >
        Start your frolicking!
      </Typography> */}
      {/* Email */}
      <TextField
        label="Phone"
        onBlur={handleBlur}
        onChange={(event) => {
          let reg = "(^\\d{10}$)+"
          let regex = new RegExp(reg, "g");
          let result = regex.test(event.currentTarget.value);
          setValidPhone(result);
          handleChange(event)
        }}
        value={values.phone}
        name="phone"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneAndroidOutlinedIcon />
            </InputAdornment>
          ),
        }}
        error={!validPhone}
        helperText={validPhone == false ? 'Enter valid mobile number' : ''}
        sx={{
          gridColumn: "span 6",
        }}
      />
      {enableVerifyOtpBtn == true ?
        <>
          {/* Password */}
          <TextField
            label="Otp"
            type="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.otp}
            name="otp"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PolicyOutlinedIcon />
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.otp) && Boolean(errors.otp)}
            helperText={touched.otp && errors.otp}
            sx={{ gridColumn: "span 6" }}
          />
          {enableRetriggerOtp ? <Typography
          data-umami-event-login={values.phone} 
          data-umami-event="Resend-OTP-Button-create-account"
            textAlign={"center"}
            onClick={() => {
              sendOtp();
              GAEvent({
                category: 'Resend-OTP-Button-create-account',
                action: 'Click',
                label: values.phone,
              })
            }}
            sx={{
              gridColumn: "span 6",
              textDecoration: "underline",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
            {"Resend OTP"}
          </Typography> : <Typography
            textAlign={"center"}
            sx={{
              gridColumn: "span 6",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
            {`Resend OTP in ${reTriggerSeconds} seconds`}
          </Typography>}


          <Box gridColumn={"span 6"}>
            <Button
            data-umami-event-login={values.phone} 
            data-umami-event="Verify-OTP-Button-create-account"
              fullWidth
              type="button"
              onClick={() => { verifyOtp();
                GAEvent({
                  category: 'Verify-OTP-Button-create-account',
                  action: 'Click',
                  label: values.phone,
                })
               }}
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
              disbled={validPhone == false ? true : false}
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              <Typography fontSize={16} fontWeight={400}>
                {"Verify OTP"}
              </Typography>
            </Button>
          </Box>
        </>

        : ""
      }


      {enableNext == true ?
        <>
          <Box gridColumn={"span 6"}>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
              disbled={enableNext == false ? true : false}
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              <Typography fontSize={16} fontWeight={400}>
                {"Next"}
              </Typography>
            </Button>
          </Box>
        </>
        : <>
          {enableVerifyOtpBtn == false ? <Box gridColumn={"span 6"}>
            <Button
              fullWidth
              type="button"
              onClick={() => {
                if (values.phone && values.phone.length > 0) {
                  sendOtp()
                } else {
                  toast.error("Phone number is mandatory")
                }
              }}
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
              disbled={validPhone == false ? true : false}
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              <Typography fontSize={16} fontWeight={400}>
                {"Send OTP"}
              </Typography>
            </Button>
          </Box> : ''}
        </>}
        <Typography
          textAlign={"center"}
          onClick={()=>{
            navigate("/home");
          }}
          sx={{
            textDecoration: "underline",
            color: palette.primary.main,
            gridColumn:"span 4",
            "&:hover": {
              cursor: "pointer",
              color: palette.primary.light,
            },
          }}
        >
          {"Already have an account? Login here."}
        </Typography>
    </Box>
  );
};

export default OtpForm;
