import React from "react";
import FastMarquee from "react-fast-marquee"; // Import FastMarquee from the package
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LandingNavbar from "../commons/LandingNavbar";
import Box from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Footer from "./../commons/Footer";
import AboutUs from './AboutUs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, Grid } from "@mui/material";
import { seo } from "utilites/utlis";


const styles = {
	container: {
		overflow: 'hidden',
		backgroundColor: 'black'

	},
	card: {
		width: "100%",
		justifyContent: "center",
		alignItems: "center",


	},
	straightMarquee: {
		background: "#9bfbb3",
		color: "black"
	},
	marqueeDiv: {
		padding: '10px',
		position: 'relative',
		width: '100%',
	},
	rotatedMarquee1: {
		position: 'absolute',
		left: '-10%',
		right: '5%',
		transform: 'rotate(10deg)',
		background: 'white',
		color: 'black',
		zIndex: '1',
		overflow: 'hidden',
	},
	rotatedMarquee2: {

		background: '#c699fb',
		color: 'black',

	},
	marqueeContainer: {
		height: "60px",
		display: "flex",
		fontSize: "24px",
		fontFamily: "'Inter', sans-serif",

	},
	icon: {
		fontSize: "50px",
	}
};

const aboutUsPage = () => {
	seo({title:`About Us`,metaDescription:`About Us`})
	return (
		<div style={styles.container}>


			<LandingNavbar />
			<div style={{ width: "100%", overflowX: "visible" }}>
				<Grid sx={{ ...styles.card, backgroundColor: 'black' }}>
					<Typography
						variant="h3"
						align="center"
						gutterBottom
						sx={{
							fontWeight: 700,
							fontSize: {
								xs: "16px",
								sm: "18px",
								md: "28px",
								lg: "48px",
							},
							animation: "slide-up 2s ease-out",
							fontFamily: "'Inter', sans-serif",
						}}
					>
						About Us
						<div><ExpandMoreIcon style={styles.icon} /></div>
						{/* <span style={{ color: "yellow" }}>VYBE IT.</span> */}
					</Typography>
				</Grid>
			</div>

			<FastMarquee direction="right" gradient={false} style={{ ...styles.marqueeContainer, ...styles.straightMarquee }}>
				YOUR BEAT. YOUR EXPERIENCE. YOUR CITY - YOUR BEAT. YOUR EXPERIENCE. YOUR
				CITY - YOUR BEAT. YOUR EXPERIENCE. YOUR CITY -  YOUR BEAT. YOUR EXPERIENCE.
			</FastMarquee>
			<AboutUs />
			<FastMarquee direction="right" gradient={false} style={{ ...styles.marqueeContainer, ...styles.straightMarquee, ...styles.rotatedMarquee2 }}>
				YOUR BEAT. YOUR EXPERIENCE. YOUR CITY - YOUR BEAT. YOUR EXPERIENCE. YOUR
				CITY - YOUR BEAT. YOUR EXPERIENCE. YOUR CITY -  YOUR BEAT. YOUR EXPERIENCE.
			</FastMarquee>
			<Footer />
		</div>
	);
};

export default aboutUsPage;
