import React, { Component } from 'react';
import {
  Box,
  TextField,
  Typography
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";

import { apiUrl } from "./../../config";
import LoadingButton from '@mui/lab/LoadingButton';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

class ArtistVerificationWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {
        phone: null,
        email: null,
        nickName: null,
        address: null,
        curatedEvents: null,
        repeatEvents: null,
        artistAssociated: null,
        associatedVenus: null,
        introduction: null,
        residentDj: null,
        musicGenere: null
      },
      loading: false,
      alert: false,
      severity: 'success',
      message: ''
    }

  }

  componentDidMount() {
    console.log("componentDidMount", this.props.theme);
    this.setState({
      userDetails: this.props.user
    })
  }
  fetchTags = async () => {
    const response = await fetch(`${apiUrl}/tags`, {
      method: "POST",
      headers: { Authorization: `Bearer ${this.props.token}`, "Content-Type": "application/json" },
      body: JSON.stringify({ tagType: "ALL" }),
    });
    const tags = await response.json();
    console.log("tags", tags);
    this.setState({
      tags: tags
    })
  }
  updateUserData = async (key, value) => {
    let userData = cloneDeep(this.state.userDetails);
    userData[key] = value;
    this.setState({
      userDetails: userData
    })
  }
  submitForVerification = async () => {
    console.log("Userdetails", this.state.userDetails)
    let reqBody = {
      userId: this.state.userDetails._id,
      nickName: this.state.userDetails.nickName ? this.state.userDetails.nickName : '',
      address: this.state.userDetails.address ? this.state.userDetails.address : '',
      ownerName: this.state.userDetails.ownerName ? this.state.userDetails.ownerName : '',
      curatedEvents: this.state.userDetails.curatedEvents ? this.state.userDetails.curatedEvents : '',
      repeatEvents: this.state.userDetails.repeatEvents ? this.state.userDetails.repeatEvents : '',
      artistAssociated: this.state.userDetails.artistAssociated ? this.state.userDetails.artistAssociated : '',
      associatedVenus: this.state.userDetails.associatedVenus ? this.state.userDetails.associatedVenus : '',
      introduction: this.state.userDetails.introduction ? this.state.userDetails.introduction : '',
      musicGenere: this.state.userDetails.musicGenere ? this.state.userDetails.musicGenere : ''
    }
    const response = await fetch(`${apiUrl}/users/submit-for-verification`, {
      method: "POST",
      headers: { Authorization: `Bearer ${this.props.token}`, "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    });
    const verificationSubmitDetails = await response.json();
    console.log("verificationSubmitDetails", verificationSubmitDetails);
    if (verificationSubmitDetails.error === false) {
      //success toaster
      this.props.setUpdatedUserDetails(verificationSubmitDetails.updatedDetials);
      toast.success("Successfully submitted for the verification.")
    }else{
      toast.error(verificationSubmitDetails.message ? verificationSubmitDetails.message : "Unable to submit for verification. Try again later.")
    }
  }
  render() {
    return (
      <WidgetWrapper m="0">

        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(6, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: this.props.theme.isNonMobileScreens ? undefined : "span 6" },
          }}
        >
          <Typography
            textAlign={"left"}
            fontWeight={400}
            fontSize="20px"
            gridColumn="span 6"
            color="primary"
          >
            Update Profile
          </Typography>
          <TextField
            label="Nick Name"
            onChange={(e) => {
              this.updateUserData('nickName', e.target.value)
            }}
            value={this.state.userDetails.nickName}
            name="nickName"
            sx={{ gridColumn: "span 6" }}
          />
          <TextField
            disabled={true}
            label="Contact Number"
            onChange={(e) => {
              this.updateUserData('phone', e.target.value)
            }}
            value={this.state.userDetails.phone}
            name="phone"
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            disabled={true}
            label="Email Id"
            onChange={(e) => {
              this.updateUserData('email', e.target.value)
            }}
            value={this.state.userDetails.email}
            name="email"
            sx={{ gridColumn: "span 2" }}
          />
          {/* <TextField
            label="Owner Name"
            onChange={(e) => {
              this.updateUserData('ownerName', e.target.value)
            }}
            value={this.state.userDetails.ownerName}
            name="ownerName"
            sx={{ gridColumn: "span 6" }}
          /> */}
          <TextField
            id="outlined-multiline-static"
            label="Address"
            multiline
            rows={4}
            onChange={(e) => {
              this.updateUserData('address', e.target.value)
            }}
            value={this.state.userDetails.address}
            name='address'
            sx={{ gridColumn: "span 6" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Introduction"
            multiline
            rows={4}
            onChange={(e) => {
              this.updateUserData('introduction', e.target.value)
            }}
            value={this.state.userDetails.introduction}
            name='introduction'
            sx={{ gridColumn: "span 6" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Curated/Popular Events"
            multiline
            rows={4}
            onChange={(e) => {
              this.updateUserData('curatedEvents', e.target.value)
            }}
            value={this.state.userDetails.curatedEvents}
            name='curatedEvents'
            sx={{ gridColumn: "span 6" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Repeat Events"
            multiline
            rows={4}
            onChange={(e) => {
              this.updateUserData('repeatEvents', e.target.value)
            }}
            value={this.state.userDetails.repeatEvents}
            name='repeatEvents'
            sx={{ gridColumn: "span 6" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Associated Venus"
            multiline
            rows={4}
            onChange={(e) => {
              this.updateUserData('associatedVenus', e.target.value)
            }}
            value={this.state.userDetails.associatedVenus}
            name='associatedVenus'
            sx={{ gridColumn: "span 6" }}
          />
          {/* <TextField
            id="outlined-multiline-static"
            label="Artists Associated"
            multiline
            rows={4}
            onChange={(e) => {
              this.updateUserData('artistAssociated', e.target.value)
            }}
            value={this.state.userDetails.artistAssociated}
            name='artistAssociated'
            sx={{ gridColumn: "span 6" }}
          /> */}
          <TextField
            id="outlined-multiline-static"
            label="Music Genere"
            multiline
            rows={4}
            onChange={(e) => {
              this.updateUserData('musicGenere', e.target.value)
            }}
            value={this.state.userDetails.musicGenere}
            name='musicGenere'
            sx={{ gridColumn: "span 6" }}
          />
          <LoadingButton
            disabled={this.state.userDetails.userVerified}
            sx={{ gridColumn: "span 6", "&:hover": { color: this.props.palette.primary.main } }}
            color="primary"
            onClick={this.submitForVerification}
            loading={this.state.loading}
            loadingIndicator="Creating..."
            variant="contained"

          >
            <span>Submit for verification</span>
          </LoadingButton>

        </Box>
      </WidgetWrapper>
    );
  }
}



export default ArtistVerificationWidget;