import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Logo from "../../assests/logo/Vybez_Logo.png";
import { Box, Grid } from '@mui/material';

const Navbar = () => {
  const navigate = useNavigate();

  const navigateToLoginPage = () => {
    navigate('/login');
  };

  return (
    <Box position="static" style={{ backgroundColor: 'black' }}>
      <Toolbar>
        <div style={{ flexGrow: 1 , cursor:'pointer' }} onClick={() => navigate(`/`)}>
          <img
            src={Logo}
            alt="Logo"
            style={{ height: '120px', width: 'auto', paddingTop: '10px' }}
          />
        </div>
        {/* <Button
          style={{
            backgroundColor: '#ffd700',
            width: '100px',
            height: '50px',
            color: 'black',
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
          onClick={navigateToLoginPage}
        >
          Login
        </Button> */}
      </Toolbar>
    </Box>
  );
};

export default Navbar;
