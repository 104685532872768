import React from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Select
} from "@mui/material";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { GAEvent } from "components/tracking";

const WelcomeForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  setPageType,
}) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const locationOptions = [
    { label: "Select Gender", value: "" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" }
  ];
  return (
    <Box
      display="grid"
      style={{ width: "100%", margin: "auto" }}
      gap="20px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 8" },
      }}
    >
      {/* Title */}
      {/* <Typography
        textAlign={"center"}
        fontWeight={400}
        fontSize="48px"
        lineHeight={"48px"}
        gridColumn="span 4"
      >
        VybeZ
      </Typography> */}
       <Typography variant='h3' gridColumn="span 6" color='primary'>Personal details</Typography>

      {/* Subtitle */}
      {/* <Typography
        textAlign={"center"}
        fontWeight={400}
        fontSize="16px"
        lineHeight={"36px"}
        variant="h5"
        color="primary"
        letterSpacing={"1px"}
        sx={{ mb: "1rem" }}
        gridColumn="span 4"
      >
        Welcome to VybeZ
      </Typography> */}
      <TextField
        label="First Name"
        required={true}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.firstName}
        name="firstName"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlined />
            </InputAdornment>
          ),
        }}
        error={Boolean(touched.firstName) && Boolean(errors.firstName)}
        helperText={touched.firstName && errors.firstName}
        sx={{
          gridColumn: "span 8",
        }}
      />
      <TextField
        label="Last Name"
        onBlur={handleBlur}
        required={true}
        onChange={handleChange}
        value={values.lastName}
        name="lastName"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlined />
            </InputAdornment>
          ),
        }}
        error={Boolean(touched.lastName) && Boolean(errors.lastName)}
        helperText={touched.lastName && errors.lastName}
        sx={{
          gridColumn: "span 8",
        }}
      />
      <TextField
        label="Phone"
        disabled={true}
        onBlur={handleBlur}
        required={true}
        onChange={handleChange}
        value={values.phone}
        name="phone"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneAndroidOutlinedIcon />
            </InputAdornment>
          ),
        }}
        error={Boolean(touched.phone) && Boolean(errors.phone)}
        helperText={touched.phone && errors.phone}
        sx={{
          gridColumn: "span 8",
        }}
      />
       <Select
        label="Gender"
          native
          value={values.gender}
          onChange={(e) => setFieldValue("gender", e.target.value)}
          sx={{
           
            gridColumn: "span 8",
          }}
        >
          {locationOptions.map((item) => (
            <option sx={{ padding: "16px 8px" }} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      {/* <TextField
        label="Gender"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.gender}
        name="gender"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <WcOutlinedIcon />
            </InputAdornment>
          ),
        }}
        error={Boolean(touched.gender) && Boolean(errors.gender)}
        helperText={touched.gender && errors.gender}
        sx={{
          gridColumn: "span 4",
        }}
      /> */}
      <TextField
        label="Email"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
        required={true}
        name="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon />
            </InputAdornment>
          ),
        }}
        error={Boolean(touched.email) && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        sx={{
          gridColumn: "span 8",
        }}
      />
      {/* <TextField
        label="Password"
        type="password"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.password}
        name="password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
        error={Boolean(touched.password) && Boolean(errors.password)}
        helperText={touched.password && errors.password}
        sx={{ gridColumn: "span 4" }}
      /> */}

      <Box gridColumn={"span 8"}>
        <Button
        data-umami-event-login={values.phone} 
        data-umami-event="Next-Button-Welcome-form"
          fullWidth
          type="button"
          onClick={() => {
            setPageType("user type");
            GAEvent({
              category: 'Next-Button-Welcome-form',
              action: 'Click',
              label: values.phone,
            })
          }}
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
          endIcon={<ArrowForwardOutlinedIcon />}
        >
          <Typography fontSize={16} fontWeight={400}>
            {"Next"}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default WelcomeForm;
