// LocationSelection.js
import React from "react";
import { Box, Button, Typography, Select, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { GAEvent } from "components/tracking";

const LocationSelection = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  setPageType,
  resetForm,
}) => {
  const { palette } = useTheme();
  const locationOptions = [
    { label: "Select Location", value: "" },
    { label: "Bengaluru", value: "Bengaluru" },
  ];

  return (
    <Box
      display="grid"
      gap="20px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      justifyContent={"center"}
    >
      <Typography
        textAlign={"center"}
        fontWeight={400}
        variant='h3'
        lineHeight={"48px"}
        color="primary"
        gridColumn="span 6"
        mb={"1rem"}
        sx={{ whiteSpace: "nowrap" }}
      >
        Where do you want to vybe?
      </Typography>
      <Box gridColumn={"span 6"} style={{ textAlign: "center", width: "100%" }}>
        <Select
          native
          value={values.location}
          onChange={(e) => setFieldValue("location", e.target.value)}
          sx={{
            width: "100%",
          }}
        >
          {locationOptions.map((item) => (
            <option sx={{ padding: "16px 8px" }} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      </Box>
      <Box gridColumn={"span 6"} style={{ textAlign: "center" }}>
        <Button
         data-umami-event-login={values.phone} 
         data-umami-event="Location-page-Next-button"
          type="button"
          onClick={() => {
            setPageType("genre");
            GAEvent({
              category: 'Location-page-Next-button',
              action: 'Click',
              label: values.phone,
            })
          }}
          sx={{
            width: "100%",
            m: "0rem auto",
            p: ".5rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            {"Next"}
          </Typography>
        </Button>
      </Box>
      <Box gridColumn={"span 6"} style={{ textAlign: "center" }}>
        <Button
        data-umami-event-login={values.phone} 
        data-umami-event="Location-page-skip-button"
          onClick={() => {
            setPageType("genre");
            GAEvent({
              category: 'Location-page-skip-button',
              action: 'Click',
              label: values.phone,
            })
          }}
          sx={{
            width: "100%",
            m: "0.25rem auto",
            color: "white",
            "&:hover": {
              cursor: "pointer",
              background: "#00000000",
            },
          }}
        >
          <Typography fontSize={16} fontWeight={400}>
            {"Skip"}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default LocationSelection;
