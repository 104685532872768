import {
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Button,
  Select,
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "state";
import Dropzone from "react-dropzone";
import LoginForm from "./loginForm";
import FlexBetween from "components/FlexBetween";
import RegisterForm from "./register-form";
import OtpForm from "./otpForm";
import WelcomeForm from "./informationForm";
import { apiUrl } from "./../../config";
import SelectableDivs from "./selectableDiv";
import ProfilePic from "./profile-pic";
import UserTypeSelection from "./userTypeForm";
import LocationSelection from "./locationForm";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { toast } from 'react-toastify';
import LoginWithOtpForm from "./loginWithOtp";
import LoadingButton from '@mui/lab/LoadingButton';

// function getValidationSchema(pageType) {
//   switch (pageType) {
//     case "register":
//       return yup.object().shape({
//         firstName: yup.string().required("required"),
//         lastName: yup.string().required("required"),
//         email: yup.string().email("invalid email").required("required"),
//         password: yup.string().required("required"),
//         location: yup.string().required("required"),
//         occupation: yup.string().required("required"),
//         picture: yup.string().required("required"),
//       });
//     case "login":
//       return yup.object().shape({
//         email: yup.string().email("invalid email").required("required"),
//         password: yup.string().required("required"),
//       });
//     case "otp":
//       return yup.object().shape({
//         phone: yup.string().required("required"),
//         otp: yup.string().required("required"),
//       });
//     default:
//       return yup.object();
//   }
// }

function getInitialValues(pageType) {
  switch (pageType) {
    case "login":
      return {
        email: "",
        password: "",
      };
    default:
      return {
        name: "",
        email: "",
        password: "",
        phone: "",
        gender: "",
        picture: "",
        phone: "",
        otp: "",
        type: "",
        location: "",
        genreType: [],
        eventType: [],
        eventVisits: [],
        atmosphere: [],
        ageRange: [],
      };
  }
}

const SignUpFlow = () => {
  const [pageType, setPageType] = useState("login");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { palette } = useTheme();
  const [loading, setLoading] = useState(false); 

  const register = async (values, onSubmitProps) => {
    // this allows us to send form info with image
    try {
      setLoading(true)
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      if (values.picture) {
        formData.append("picturePath", values.picture.name ?? "");
      }

      const savedUserResponse = await fetch(`${apiUrl}/auth/register`, {
        method: "POST",
        body: formData,
      });
      const savedUser = await savedUserResponse.json();
      if (savedUser.error == false) {
        toast.success("Registered successfully. Login to Vybe!");
        setPageType("login");
        setLoading(false)
        onSubmitProps.resetForm();
      } else {
        setLoading(false)
        toast.error(savedUser.message)
      }

    } catch (e) {
      setLoading(false)
      toast.error("Unable to register. Try again later.")
    }

  };

  const login = async (values, onSubmitProps) => {
    try {
      const loggedInResponse = await fetch(`${apiUrl}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });
      const loggedIn = await loggedInResponse.json();
      if (loggedIn.error === false) {
        onSubmitProps.resetForm();
        if (loggedIn) {
          dispatch(
            setLogin({
              user: loggedIn.user,
              token: loggedIn.token,
            })
          );
          navigate("/home");
        }
      } else {
        toast.error(loggedIn.message)
      }

    } catch (e) {
      toast.error(e)
    }

  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      // if (isLogin) await login(values, onSubmitProps);
      if (pageType === "profile pic" || pageType === "socialize") {
        await register(values, onSubmitProps);

      }
      if (pageType === "otp") setPageType("welcome");
    } catch (e) {
      toast.error("Something went wrong. Try again later.")
    }

  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={getInitialValues(pageType)}
    // validationSchema={getValidationSchema(pageType)}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            {isRegister && (
              <RegisterForm
                {...{
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  click: () => {
                    setPageType("login");
                    resetForm();
                  },
                }}
              />
            )}
            {isLogin && (
              <LoginWithOtpForm
                {...{
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  resetForm,
                  setPageType,
                  resetForm,
                  handleSubmit
                }}
              />
            )}
            {pageType === "otp" && (
              <OtpForm
                {...{
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  resetForm,
                }}
              />
            )}
            {pageType === "welcome" && (
              <WelcomeForm
                {...{
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  setPageType,
                }}
              />
            )}
            {pageType === "profile pic" && (
              <ProfilePic
                {...{
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  resetForm,
                  setPageType
                }}
              />
            )}
            {pageType === "user type" && (
              <UserTypeSelection
                {...{
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  setPageType,
                }}
              />
            )}
            {pageType === "location" && (
              <LocationSelection
                {...{
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setPageType,
                  setFieldValue,
                }}
              />
            )}
            {pageType === "genre" && (
              <SelectableDivs
                title={"Which genres you vybe with?"}
                setFieldValue={setFieldValue}
                nClick={() => setPageType("event")}
                bClick={() => setPageType("location")}
                fieldValue={"genreType"}
                selected={values}
                data={[
                  "EDM", "Hip-Hop",
                  "Dance", "Techno", "Bollywood",
                  "Karaoke", "New-age", "Reggae",
                  "Pop",
                  "Rap",
                  "R&B",
                  "Rock",
                  "Salsa",
                  "Latin",
                  "Country",
                  "Indie",
                  "Disco",
                  "Funk",
                  "Soul",
                  "Jazz",
                  "Classical",
                  "Electro",
                  "Trap",
                  "K-Pop",
                  "Punk",
                  "Trance",
                ]}
                multiSelect={true}
              />
            )}
            {pageType === "event" && (
              <SelectableDivs
                nClick={() => setPageType("event visit")}
                bClick={() => setPageType("genre")}
                setFieldValue={setFieldValue}
                title={"What type of event do you enjoy most?"}
                fieldValue={"eventType"}
                selected={values}
                data={[
                  "Night Life",
                  "Dance",
                  "Clubbing",
                  "Drink",
                  "Hangout with friends",
                  "Explore new places",
                  "Brunch Parties",
                  "Bar hopping",
                  "Socialise",
                  "Pool Parties",
                  "Concerts",
                  "Live music events",
                  "Themed parties",
                ]}
                multiSelect={true}
              />
            )}
            {pageType === "event visit" && (
              <SelectableDivs
                nClick={() => setPageType("atmosphere")}
                bClick={() => setPageType("event")}
                setFieldValue={setFieldValue}
                fieldValue={"eventVisits"}
                selected={values}
                title={"How often do you go out for these events?"}
                data={[
                  "Weekly",
                  "Monthly",
                  "Occasionally",
                  "Rarely",
                  "First-time",
                ]}
                multiSelect={true}
              />
            )}
            {pageType === "atmosphere" && (
              <SelectableDivs
                nClick={() => setPageType("age")}
                bClick={() => setPageType("event visit")}
                setFieldValue={setFieldValue}
                fieldValue={"atmosphere"}
                selected={values}
                title={"What kind of atmosphere do you prefer?"}
                data={[
                  "Lively",
                  "Energetic",
                  "Chill",
                  "Relaxed",
                  "Intimate",
                  "Cozy",
                  "Trendy",
                  "Diverse",
                  "Inclusive",
                  "Peace",
                  "Silent",
                ]}
                multiSelect={true}
              />
            )}
            {pageType === "age" && (
              <SelectableDivs
                nClick={() => setPageType("socialize")}
                bClick={() => setPageType("age")}
                setFieldValue={setFieldValue}
                fieldValue={"ageRange"}
                selected={values}
                title={"Help us with your age to tailor your experience"}
                data={["Under 18", "18-24", "25-34", "35-44", "55 and over"]}
                multiSelect={false}
              />
            )}
            {pageType === "socialize" && (
              <Box
              // display="grid"
              // style={{ width: "50%", margin: "auto" }}
              // gap="20px"
              // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              >
                {/* <Typography
                  textAlign={"center"}
                  fontWeight={400}
                  fontSize="48px"
                  lineHeight={"48px"}
                  color="primary"
                  gridColumn="span 4"
                  mb={"1rem"}
                >
                  Let's Vybe!
                </Typography> */}
                <Box gridColumn={"span 12"} sx={{ textAlign: "center" }}>
                  <FlexBetween sx={{ flexFlow: 'column' }}>
                    <Box
                      sx={{ width: "100%" }}
                      gridColumn="span 12"
                      border={`1px solid ${palette.neutral.medium}`}
                      borderRadius="5px"
                      p="1rem"
                    >
                      <Dropzone
                        acceptedFiles=".jpg,.jpeg,.png"
                        multiple={false}
                        onDrop={(acceptedFiles) => setFieldValue("picture", acceptedFiles[0])}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <Box
                            {...getRootProps()}
                            border={`2px dashed ${palette.primary.main}`}
                            p="1rem"
                            sx={{
                              "&:hover": { cursor: "pointer" },
                              border: `2px dashed ${errors.picture ? palette.error.main : palette.primary.main
                                }`,
                            }}
                          >
                            <input {...getInputProps()} />
                            {!values.picture ? (
                              <Typography>{"Add Picture Here"}</Typography>
                            ) : (
                              <FlexBetween>
                                <Typography>{values.picture.name}</Typography>
                                <EditOutlinedIcon />
                              </FlexBetween>
                            )}
                          </Box>
                        )}
                      </Dropzone>
                    </Box>
                    <LoadingButton
                    data-umami-event-login={values.phone} 
                    data-umami-event="Create-Account-Button"
                      loading={loading}
                      type="submit"
                      startIcon={<PersonAddIcon />}
                      sx={{
                        p: "0.5rem",
                        width: "100%",
                        margin: "0.5rem auto",
                        backgroundColor: palette.grey[900],
                        color: palette.grey[50],
                        border: `1px solid ${palette.primary.main}`,
                        "&:hover": { color: palette.primary.main },
                      }}
                    >
                      <Typography fontSize={16} fontWeight={400}>
                        Create Account
                      </Typography>
                    </LoadingButton>
                    <Box gridColumn={"span 12"} style={{ textAlign: "center" }}>
                      <Button
                      data-umami-event-login={values.phone} 
                      data-umami-event="Back-Btn-Create-Account"
                        onClick={() => {
                          setPageType("age")
                          }}
                        sx={{
                          width: "100%",
                          m: "0.25rem auto",
                          color: "white",
                          "&:hover": {
                            cursor: "pointer",
                            background: "#00000000",
                          },
                        }}
                      >
                        <Typography fontSize={16} fontWeight={400}>
                          {"Back"}
                        </Typography>
                      </Button>
                    </Box>
                  </FlexBetween>

                </Box>
              </Box>
            )}
          </Box>

          {/* BUTTONS */}
        </form>
      )}
    </Formik>
  );
};

export default SignUpFlow;
