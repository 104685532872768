import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Typography } from "@mui/material";
import { apiUrl } from "config";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const BlogSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBlogs();
  }, []);
  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${apiUrl}/blogs/all-blogs/1/10`);
      const data = await response.json();
      setBlogs(data.blogs);
      // initiateScroll();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>{
        // if(prevIndex == (data.length - 1)){
        //   return 0;
        // }else{
        //   if((prevIndex + 1)  === (data.length - 1)){
        //     return (data.length - 1)
        //   }else{
        //     return (prevIndex + 1)
        //   }
        // }
       return (prevIndex === data.length - 1 ? 0 : prevIndex + 1)
      }
      );
    }, 8000);

    return () => clearInterval(interval);
 },[data.length]);
  // const initiateScroll = () => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) =>{
  //       console.log("setCurrentIndex prevIndex",prevIndex,data,data.length,(data.length - 1))
  //       if(prevIndex == (data.length - 1)){
  //         return 0;
  //       }else{
  //         if((prevIndex + 1)  === (data.length - 1)){
  //           console.log("setCurrentIndex if data.length -1",(data.length - 1))
  //           return (data.length - 1)
  //         }else{
  //           console.log("setCurrentIndex if data.length -1",(data.length - 1))
  //           return (prevIndex + 1)
  //         }
  //       }
  //       // prevIndex === data.length - 1 ? 0 : prevIndex + 1
  //     }
  //     );
  //   }, 8000);

  //   return () => clearInterval(interval);
  // }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getPrevIndex = () =>{
      return(currentIndex === 0 ? data.length - 1 : currentIndex - 1)
      // if(currentIndex  === 0){
      //   if(data.length - 1 == -1){
      //     return 0;
      //   }else{
      //     return(data.length - 1)
      //   }
      // }
      // return (currentIndex - 1);
    }
  
  const getNextIndex = () =>{
    return(currentIndex === data.length - 1 ? 0 : currentIndex + 1)
    // if(currentIndex === data.length - 1){
    //   return 0
    // }else{
    //   if(currentIndex + 1 > data.length - 1){
    //     return 0
    //   }else{
    //     return(currentIndex + 1)
    //   }
    // }
  }
    
  

  const prevIndex = getPrevIndex();
  const nextIndex = getNextIndex();
  // console.log("prevIndex,nextIndex", prevIndex, currentIndex, nextIndex)

  function truncateText(text) {
    if (text) {
      const words = text.split(" ");
      if (words.length > 20) {
        return words.slice(0, 20).join(" ") + "...";
      }
    }
    return text;

  }

  function truncateMobileText(text) {
    if (text) {
      if (text.length > 40) {
        return text.slice(0, 40) + "...";
      }
    }
    return text;
  }
  const navigateToBlogDetails=(blog)=>{
    navigate(`/blog-detail/${blog._id}`, { state: blog });
  }
  const navigateToBlogs=()=>{
    navigate(`/blog`);
  }
  return (
    <section className="!py-8 ">
      <div className="px-4 sm:px-16 pt-6 pb-12 flex justify-between items-center">
        <Typography
          variant="h2"
          className={`!font-bold text-xl animate-slide-up text-center md:text-left`}
        >
          Blogs
        </Typography>
          <div onClick={()=>{navigateToBlogs();}}  className="cursor-pointer text-yellow flex items-center gap-x-2">
            <div className="text-base">View All</div>
            <ArrowForwardIosIcon />
          </div>
      </div>

      {data.length > 0 ?
        <div className="relative flex justify-center items-center w-full h-full">
          <div className="flex justify-center items-center overflow-hidden">
            {/* Previous Card */}
            <div
              className="hidden sm:block w-[120px] h-[520px] overflow-hidden transition-transform duration-500 transform z-0 border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100"
              style={{
                background:
                  "linear-gradient(0deg, rgba(32, 32, 32, 1) 100%, rgba(0, 0, 0, 1) 100%)",
              }}
            >
              <img
                src={((data[prevIndex].blogBannerPath != undefined && data[prevIndex].blogBannerPath != null && data[prevIndex].blogBannerPath != '') ? data[prevIndex].blogBannerPath : '')}
                alt={data[prevIndex].blogTitle}
                className="object-cover w-[800px] h-[385px] opacity-50 rounded-t-[20px]"
              />
              <div className="w-[864px] h-[135px] flex flex-col justify-center gap-y-4 p-6 opacity-50 text-white rounded-b-[20px]">
                <div className="text-2xl !font-bold">
                  {truncateText(data[prevIndex].blogTitle)}
                </div>
                <div className="text-sm text-yellow">{data[prevIndex].createdAt ? dayjs(data[prevIndex].createdAt).format("MMMM DD, YYYY") : ""}</div>
              </div>
              <div
                onClick={handleNext}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 cursor-pointer"
              >
                <ArrowBackIosIcon style={{ color: "yellow", fontSize: "30px" }} />
              </div>
            </div>

            {/* Current Card */}
            <div
              className="w-[864px] h-[578px] z-10 mx-4 rounded-[20px] border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100"
              style={{
                cursor:'pointer',
                background:
                  "linear-gradient(0deg, rgba(32, 32, 32, 1) 100%, rgba(0, 0, 0, 1) 100%)",
              }}
              onClick={()=>{
                navigateToBlogDetails(data[currentIndex]);
              }}
            >
              <div>
                <img
                  src={((data[currentIndex].blogBannerPath != undefined && data[currentIndex].blogBannerPath != null && data[currentIndex].blogBannerPath != '') ? data[currentIndex].blogBannerPath : '')}
                  alt={data[currentIndex].blogTitle}
                  className="object-cover w-full h-[428px] rounded-t-[20px]"
                />
                <div className="w-full h-[150px] flex flex-col justify-center gap-y-4 p-6 rounded-b-[20px]">
                  <div className="sm:block hidden text-2xl font-extrabold text-[#E5E5E5]">
                    {truncateText(data[currentIndex].blogTitle)}
                  </div>
                  <div className="block sm:hidden text-2xl font-extrabold text-[#E5E5E5]">
                    {truncateMobileText(data[currentIndex].blogTitle)}
                  </div>
                  <div className="text-sm text-yellow">
                    {data[currentIndex].createdAt ? dayjs(data[currentIndex].createdAt).format("MMMM DD, YYYY") : ""}
                  </div>
                </div>
              </div>
            </div>

            {/* Next Card */}
            <div
              className="hidden sm:block w-[120px] h-[520px] rounded-[20px] border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100 transition-transform duration-500 transform z-0"
              style={{
                background:
                  "linear-gradient(0deg, rgba(32, 32, 32, 1) 100%, rgba(0, 0, 0, 1) 100%)",
              }}
            >
              <img
                src={((data[nextIndex].blogBannerPath != undefined && data[nextIndex].blogBannerPath != null && data[nextIndex].blogBannerPath != '') ? data[nextIndex].blogBannerPath : '')}
                alt={data[nextIndex].blogTitle}
                className="object-cover w-full h-[385px] opacity-50 rounded-t-[20px]"
              />
              <div className="w-[864px] h-[135px] flex flex-col justify-center gap-y-4 p-6 opacity-50 rounded-b-[20px]">
                <div className="text-2xl !font-bold">
                  {truncateText(data[nextIndex].blogTitle)}
                </div>
                <div className="text-sm text-yellow">
                  {data[nextIndex].createdAt ? dayjs(data[nextIndex].createdAt).format("MMMM DD, YYYY") : ""}
                </div>
              </div>
              <div
                onClick={handlePrev}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 cursor-pointer"
              >
                <ArrowForwardIosIcon
                  style={{ color: "yellow", fontSize: "30px" }}
                />
              </div>
            </div>

            {/* Mobile Arrows */}
            <div className="sm:hidden absolute top-1/2 pl-2 left-4 transform -translate-y-1/2 z-10 cursor-pointer">
              <ArrowBackIosIcon
                onClick={handlePrev}
                style={{ color: "yellow", fontSize: "30px" }}
              />
            </div>
            <div className="sm:hidden absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer">
              <ArrowForwardIosIcon
                onClick={handleNext}
                style={{ color: "yellow", fontSize: "30px" }}
              />
            </div>
          </div>
        </div> : ''}


    </section>
  );
};

export default BlogSection;
