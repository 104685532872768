import React, { useEffect, useState } from "react";
import LandingNavbar from "scenes/landingPage/LandingNavbar";
import VenueDetailsection from "./venueDetailSection";
import VenueEventsSection from "./eventSection";
import VenueAboutSection from "./venueAbout";
import OtherVenuesSection from "./otherVenues";
import { apiUrl } from "config";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";

const VenueDetail = () => {
  const [venue, setVenue] = useState({});
  const params = useParams();
  const [venueAvailable, setVenueAvailable] = useState(false);
  const isAuth = Boolean(useSelector((state) => state.token));
  const navigate = useNavigate();

  useEffect(() => {
    fetchVenues();
  }, []);
  const fetchVenues = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/venue/${params.venuSlug}`);
      const data = await response.json();
      if (data.error == false && data.venue && data.venue!=undefined && data.venue != null) {
        setVenue(data.venue);
        setVenueAvailable(true)
        // console.log(data);
      } else {
        pageNotFound()
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const pageNotFound = () => {
    navigate(`/404`);
  }

  return (
    <div className="bg-mainBg overflow-hidden">
      {isAuth ? <Navbar /> : <LandingNavbar />}
      {venueAvailable == true ? <>
        <VenueDetailsection venue={venue} />
        <VenueEventsSection venue={venue} />
        <VenueAboutSection venue={venue} /> </> : <>

      </>}

      <OtherVenuesSection />
    </div>
  );
};

export default VenueDetail;
