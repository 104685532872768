
import {
  Box,
  Divider,
  Typography,
  useTheme,
  Chip,
  Stack,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import { apiUrl } from "./../../config";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import EventInsightWidget from "./EventInsightsWidget";
import ProfileInsightWidget from "./ProfileInsightsWidget";

const DashboardWidget = ({ userId }) => {
  const dispatch = useDispatch();
  const [post, setPost] = useState("");
  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [dateRange, setDateRange] = useState([
    { tagName: '7 Days' },
    { tagName: '14 Days' },
    { tagName: '30 Days' }
  ])
  const [dateSearchType, setSearchType] = useState('7 Days');
  const [dateRangeLabel, setDateRangeLabel] = useState();
  const handlePost = async () => {
    const formData = new FormData();
    formData.append("userId", _id);
    formData.append("description", post);


    const response = await fetch(`${apiUrl}/posts`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });
    const posts = await response.json();
    dispatch(setPosts({ posts }));

    setPost("");
  };

  const getDatesString = () => {
    switch (dateSearchType) {
      case '7 Days':
        let currentDate = dayjs();
        let last7thDate = dayjs().subtract(7, 'days')
        setDateRangeLabel(`${last7thDate.format('DD MMM')} - ${currentDate.format('DD MMM')}`);
        break;
      case '14 Days':
        let currentDate2 = dayjs();
        let last14thDate = dayjs().subtract(14, 'days')
        setDateRangeLabel(`${last14thDate.format('DD MMM')} - ${currentDate2.format('DD MMM')}`);
        break;
      case '30 Days':
        let currentDate3 = dayjs();
        let last30thDate = dayjs().subtract(30, 'days')
        setDateRangeLabel(`${last30thDate.format('DD MMM')} - ${currentDate3.format('DD MMM')}`);
        break;
    }

  }
  useEffect(() => {
    getDatesString();
  }, [dateSearchType]);


  return (
    <WidgetWrapper m="0 0 2rem 0" minHeight='80vh'>
      <FlexBetween
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Stack
          direction="row"
          flexWrap={"wrap"}
          alignItems={"center"}
          // justifyContent="center"
          gap={"12px"}
          marginBottom={'10px'}
        >
          {dateRange.map((value) => (
            <Chip
              key={value.tagName}
              onClick={() => {
                if (dateSearchType === value.tagName) {
                  setSearchType(null)
                } else {
                  setSearchType(value.tagName)
                }

              }}
              style={{
                fontSize: "16px",
                padding: "1rem",
                border: `0.5px solid ${palette.primary.main}`,
                backgroundColor: dateSearchType == value.tagName
                  ? palette.primary.main
                  : palette.grey[900],
                color: dateSearchType == value.tagName ? palette.grey[900] : "white",
                cursor: "pointer",
              }}
              label={value.tagName}
              variant="outlined"
            />
          ))}
        </Stack>
        <Typography
          textAlign={"right"}
          fontWeight={400}
          fontSize="16px"
          color="white"
          marginBottom={'10px'}
        >
          {dateRangeLabel}
        </Typography>
      </FlexBetween>

      <Divider sx={{ margin: "1.25rem 0 0 0" }} />

      <WidgetWrapper m="0 0 2rem 0">
        <EventInsightWidget data={{typeLabel:"Events",totalTicketsBooked: 0,totalEvents:0,totalViews: 0}} />
      </WidgetWrapper>

      <WidgetWrapper m="0 0 2rem 0">
        <ProfileInsightWidget data={{typeLabel:"Profile",totalProfileViews: 0,profileViews:0,totalFollowers: 0}} />
      </WidgetWrapper>

    </WidgetWrapper>


  );
};

export default DashboardWidget;
