import React from 'react';
import LandingNavbar from 'scenes/landingPage/LandingNavbar';
import ErrorSection from './errorSection';
import Footer from 'scenes/commons/Footer';

const ErrorPage = () => {
  return (
    <div className="bg-mainBg">
        <LandingNavbar/>
        <ErrorSection/>
        <Footer/>
    </div>
  );
};

export default ErrorPage;
