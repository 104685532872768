import React, { useState } from "react";
import { Box, Typography, Tabs, Tab, useTheme } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import TabPanel from "components/TabPanel";
import BookingHistoryCardWidget from "scenes/widgets/BookingHistoryCardWidget";

const BookingHistoryWidget = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const { palette } = useTheme();
  const primary = palette.primary.main;

  return (
    <WidgetWrapper>
      <Typography variant="h5" color={primary} mb="1rem">
        Booking History
      </Typography>

      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Upcoming Events" />
          <Tab label="Past Events" />
        </Tabs>
      </Box>

      {/* Tab Panels */}
      <TabPanel value={activeTab} index={0}>
        <BookingHistoryCardWidget active={true} completed={false}/>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <BookingHistoryCardWidget completed={true} />
      </TabPanel>
    </WidgetWrapper>
  );
};

export default BookingHistoryWidget;
