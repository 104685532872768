const VenueCard = ({ venue }) => (
  <div className="bg-[#2A2A2A] rounded-[20px] shadow-md overflow-hidden w-full transition-transform transform hover:scale-105">
    <div className="relative">
      <img
        src={venue.bannerPath}
        alt={venue.venuName}
        className="w-full h-48 object-cover"
      />
    </div>
    <div className="p-4">
      {/* <div className="flex items-center"> */}
        {/* <span className="text-gray-500 text-xs">{venue.type}</span> */}
      {/* </div> */}
      <h3 className="mt-2 font-semibold text-lg">{venue.venuName}</h3>
      <p className="text-yellow text-xs">{venue.location}</p>
    </div>
  </div>
);

export default VenueCard;
