import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AuthNavbar from "scenes/navbar"

import UserWidget from "scenes/widgets/UserWidget";
import EventWidget from "scenes/widgets/EventWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import { apiUrl } from "../../config";
import { useParams } from 'react-router';
import { useLocation } from "react-router-dom";

const AuthFeaturedEventPage = () => {
  const location = useLocation();
  const eventData = location.state;


  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { _id, picturePath } = useSelector((state) => state.user);
  // const [eventData, setEventData] = useState({});
  // const token = useSelector((state) => state.token);
  // const params = useParams()
  const [eventDataAvailable, setEventDataAvalibility] = useState(true);
  // const getPostById = async () => {

  //   const response = await fetch(`${apiUrl}/posts/${params.eventId}/post`, {
  //     method: "GET",
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  //   const data = await response.json();
  //   console.log("data getPostById", data)
  //   updateInsights(data)
  //   setEventData(data)
  //   setEventDataAvalibility(true)
  // };

  // const updateInsights = async (data) => {

  //   const response = await fetch(`${apiUrl}/insights`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       insightType: "EVENT_VIEW",
  //       eventId: params.eventId,
  //       viewedByUserId: _id,
  //       profileId: data.userId
  //     })
  //   });

  // };
  // useEffect(() => {
  //   getPostById();
  // }, []);
  return (
    <Box>

      <AuthNavbar />
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        {/* <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <UserWidget userId={_id} picturePath={picturePath} />
        </Box> */}
        {eventDataAvailable === true ?
          <Box
            flexBasis={isNonMobileScreens ? "70%" : undefined}
            mt={isNonMobileScreens ? undefined : "2rem"}
          >
            <EventWidget event={eventData} />
          </Box> : ''}

        {isNonMobileScreens && (
          <Box flexBasis="28%">
            <AdvertWidget />
            <Box m="2rem 0" />
            {/* <FriendListWidget userId={_id} /> */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AuthFeaturedEventPage;
