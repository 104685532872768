import React, { useEffect, useState } from "react";
import LandingNavbar from "scenes/landingPage/LandingNavbar";
import ArtistDetailsection from "./artistDetailSection";
import ArtistAboutSection from "./artistAboutSection";
import ArtistEventsSection from "./EventsSection";
import OtherArtistsSection from "./otherArtists";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "config";

const ArtistDetail = () => {
  const isAuth = Boolean(useSelector((state) => state.token));
  const [artist, setArtist] = useState({});
  const params = useParams();
  const [artistAvailable, setArtistAvailable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchArtists();
  }, []);
  const fetchArtists = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/artist/${params.profileSlug}`);
      const data = await response.json();
      if (data.error == false && data.artist && data.artist != undefined && data.artist != null) {
        setArtist(data.artist);
        setArtistAvailable(true)
        // console.log(data);
      } else {
        pageNotFound()
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const pageNotFound = () => {
    navigate(`/404`);
  }
  return (
    <div className="bg-mainBg overflow-hidden">
      {isAuth ? <Navbar /> : <LandingNavbar />}
      {artistAvailable ? <>
        <ArtistDetailsection artist={artist}/>
        <ArtistEventsSection artist={artist}/>
        <ArtistAboutSection artist={artist}/></> 
        : 
      <></>}

      <OtherArtistsSection />
    </div>
  );
};

export default ArtistDetail;
