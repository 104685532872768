import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Typography } from "@mui/material";
import { apiUrl } from "config";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const HeroCarouselDivided = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setBlogs] = useState([{eventName: 'loading',picturePath:''}]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEventData();
  }, []);

  const fetchEventData = async () => {
    try {
      const response = await fetch(`${apiUrl}/posts/all-featured-events/1/10`);
      const data = await response.json();
      setBlogs(data.posts);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>{
       return (prevIndex === data.length - 1 ? 0 : prevIndex + 1)
      }
      );
    }, 8000);

    return () => clearInterval(interval);
 },[data.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getPrevIndex = () =>{
      return(currentIndex === 0 ? data.length - 1 : currentIndex - 1)

    }
  
  const getNextIndex = () =>{
    return(currentIndex === data.length - 1 ? 0 : currentIndex + 1)
  }
    
  

  const prevIndex = getPrevIndex();
  const nextIndex = getNextIndex();

  function truncateText(text) {
    if (text) {
      const words = text.split(" ");
      if (words.length > 20) {
        return words.slice(0, 20).join(" ") + "...";
      }
    }
    return text;

  }

  function truncateMobileText(text) {
    if (text) {
      if (text.length > 40) {
        return text.slice(0, 40) + "...";
      }
    }
    return text;
  }
  const snavigateToFeaturedEventData=(event)=>{
    navigate(`/event/${event._id}`, { state: event });
  }

  return (
    <section className=" ">
      {/* {data.length > 0 ? */}
        <div className="relative flex justify-center items-center w-full h-full">
          <div className="flex justify-center items-center overflow-hidden">
            {/* Previous Card */}
            <div
              className="hidden sm:block w-[250px] h-[280px] overflow-hidden transition-transform duration-500 transform z-0 border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100"
              style={{
                background:
                  "linear-gradient(0deg, rgba(32, 32, 32, 1) 100%, rgba(0, 0, 0, 1) 100%)",
              }}
            >
              <img
                src={((data[prevIndex].bannerPath != undefined && data[prevIndex].bannerPath != null && data[prevIndex].bannerPath != '') ? data[prevIndex].bannerPath : data[prevIndex].picturePath)}
                alt={data[prevIndex].eventName}
                className="object-cover w-[800px] h-[280px] opacity-50 rounded-t-[20px]"
              />
              {/* <div className="w-[864px] h-[135px] flex flex-col justify-center gap-y-4 p-6 opacity-50 text-white rounded-b-[20px]">
                <div className="text-2xl !font-bold">
                  {truncateText(data[prevIndex].eventName)}
                </div>
                <div className="text-sm text-yellow">{data[prevIndex].eventDate ? dayjs(data[prevIndex].eventDate).format("MMMM DD, YYYY") : ""}</div>
              </div> */}
              <div
                onClick={handleNext}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 cursor-pointer"
              >
                <ArrowBackIosIcon style={{ color: "yellow", fontSize: "30px" }} />
              </div>
            </div>

            {/* Current Card */}
            <div
              className="w-[750px] h-[350px] z-10 mx-4 rounded-[20px] border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100"
              style={{
                cursor:'pointer',
                background:
                  "linear-gradient(0deg, rgba(32, 32, 32, 1) 100%, rgba(0, 0, 0, 1) 100%)",
              }}
              onClick={()=>{
                snavigateToFeaturedEventData(data[currentIndex]);
              }}
            >
              <div>
                <img
                  src={((data[currentIndex].bannerPath != undefined && data[currentIndex].bannerPath != null && data[currentIndex].bannerPath != '') ? data[currentIndex].bannerPath : data[currentIndex].picturePath )}
                  alt={data[currentIndex].eventName}
                  className="object-contain w-full h-[350px] rounded-[20px]"
                />
                {/* <div className="w-full h-[150px] flex flex-col justify-center gap-y-4 p-6 rounded-b-[20px]">
                  <div className="sm:block hidden text-2xl font-extrabold text-[#E5E5E5]">
                    {truncateText(data[currentIndex].eventName)}
                  </div>
                  <div className="block sm:hidden text-2xl font-extrabold text-[#E5E5E5]">
                    {truncateMobileText(data[currentIndex].eventName)}
                  </div>
                  <div className="text-sm text-yellow">
                    {data[currentIndex].eventDate ? dayjs(data[currentIndex].eventDate).format("MMMM DD, YYYY") : ""}
                  </div>
                </div> */}
              </div>
            </div>

            {/* Next Card */}
            <div
              className="hidden sm:block w-[250px] h-[280px] rounded-[20px] border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100 transition-transform duration-500 transform z-0"
              style={{
                background:
                  "linear-gradient(0deg, rgba(32, 32, 32, 1) 100%, rgba(0, 0, 0, 1) 100%)",
              }}
            >
              <img
                src={((data[nextIndex].bannerPath != undefined && data[nextIndex].bannerPath != null && data[nextIndex].bannerPath != '') ? data[nextIndex].bannerPath : data[nextIndex].picturePath)}
                alt={data[nextIndex].eventName}
                className="object-cover w-full h-[280px] opacity-50 rounded-t-[20px]"
              />
              {/* <div className="w-[864px] h-[135px] flex flex-col justify-center gap-y-4 p-6 opacity-50 rounded-b-[20px]">
                <div className="text-2xl !font-bold">
                  {truncateText(data[nextIndex].eventName)}
                </div>
                <div className="text-sm text-yellow">
                  {data[nextIndex].eventDate ? dayjs(data[nextIndex].eventDate).format("MMMM DD, YYYY") : ""}
                </div>
              </div> */}
              <div
                onClick={handlePrev}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 cursor-pointer"
              >
                <ArrowForwardIosIcon
                  style={{ color: "yellow", fontSize: "30px" }}
                />
              </div>
            </div>

            {/* Mobile Arrows */}
            <div className="sm:hidden absolute top-1/2 pl-2 left-4 transform -translate-y-1/2 z-10 cursor-pointer">
              <ArrowBackIosIcon
                onClick={handlePrev}
                style={{ color: "yellow", fontSize: "30px" }}
              />
            </div>
            <div className="sm:hidden absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer">
              <ArrowForwardIosIcon
                onClick={handleNext}
                style={{ color: "yellow", fontSize: "30px" }}
              />
            </div>
          </div>
        </div> 
        {/* : ''} */}


    </section>
  );
};

export default HeroCarouselDivided;
