import { EventAvailable, LocationOn } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import NoFoodIcon from "@mui/icons-material/NoFood";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import {
  Box, Button, Grid, Typography, useTheme,
  Accordion,
  AccordionSummary, useMediaQuery,
  AccordionDetails
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEvent } from "state";
import { apiUrl } from "config";
import dayjs from "dayjs";
import UserImage from "components/UserImage";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EventWidget = ({ event
}) => {
  const [isAttending, setIsAttending] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  // const loggedInUserId = useSelector((state) => state.user._id);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;

  const navigateToSummary = () => {
    if(event.eventType == 'LISTING'){
      localStorage.setItem("backUrl",`/explore`)
      navigate(`/explore`)
    }else{
      dispatch(setEvent({
        event: event
      }));
      setTimeout(() => {
        localStorage.setItem("backUrl",`/event/${event._id}`)
        navigate(`/ticket/${event._id}`)
      }, 1000)
    }
   
  }

  // const patchAttend = async () => {
  //   // Placeholder API integration for attending an event
  //   // Replace this with your actual API endpoint and logic
  //   const response = await fetch(
  //     `${apiUrl}/events/${eventId}/`,
  //     {
  //       method: "PATCH",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ userId: loggedInUserId }),
  //     }
  //   );

  //   // const updatedEvent = await response.json();
  //   // dispatch(setEvent({ event: updatedEvent }));
  // };

  return (
    <WidgetWrapper mb="4rem" >
      {event.picturePath && (
        <img
          alt="event"
          style={{ borderRadius: "0.75rem", marginTop: "0.50rem", width: '100%', height: '300px', objectFit: 'contain',backgroundColor:'black' }}
          src={`${event.picturePath}`}
        />
      )}
      <Box padding={'1rem'} borderRadius={'10px'} marginTop={'1rem'} marginBottom={'1rem'} backgroundColor={'black'}>
        <FlexBetween justifyContent={'space-between'}>
          <div>
            <Typography variant="h3" mt="0.50rem" color={'white'}>
              {event.eventName}
            </Typography>
            <Typography variant="caption" fontSize={'12px'} mt="0.50rem" color={'white'}>By: {event.firstName} {event.lastName}</Typography>
          </div>
          <div>
            <Button
             data-umami-event-eventdetails={event.eventName} 
             data-umami-event="Event-details-page-book-explore-button"
              onClick={() => navigateToSummary()}
              variant="contained"
              sx={{
                p: "0.6rem",
                width: "100px",
                borderRadius: '10px',
                backgroundColor: palette.primary.main,
                color: palette.primary.dark,
                "&:hover": { color: palette.primary.main }
              }}
            >
             {event.eventType == 'LISTING' ? 'Explore': 'Book Now'} 
            </Button>

          </div>
        </FlexBetween>
        {isNonMobileScreens ?
          <FlexBetween alignContent={'center'} justifyContent={'space-between'}>
            <Typography variant="caption" fontSize={'12px'} mt="0.50rem" color={'white'}>
              {event.eventDate ? dayjs(event.eventDate).format('MMMM, DD YYYY') : ''} | {event.eventStartTime ? dayjs(event.eventStartTime).format('hh:mm A') : ''} to {event.eventStartTime ? dayjs(event.eventEndTime).format('hh:mm A') : ''}
            </Typography>


            <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-evenly', textAlign: 'center' }}>
              <LocationOn style={{ marginTop: '0.5rem' }} marginRight="4px" fontSize={'16px'} />
              <Typography variant="caption" fontSize={'12px'} mt="0.50rem" marginRight="4px" color={'white'}>  {event.location}</Typography>
              {event.eventType == 'LISTING' ? '' : 
              <>
              <Typography variant="caption" fontSize={'12px'} mt="0.50rem" color={'white'} marginRight="4px"> | ₹ {`${(event.ticketPrice != null && event.ticketPrice != undefined && event.ticketPrice != "null") ? event.ticketPrice : 0} onwards`}</Typography>
              <Typography variant="caption" fontSize={'12px'} mt="0.50rem" color={'white'} marginRight="4px"> | {`${event.spotsLeft ? event.spotsLeft : 0} spots left`}</Typography>
              </>
              }
            
            </div>


          </FlexBetween> :
          <>
            <FlexBetween alignContent={'start'} style={{ alignItems: 'flex-start' }} justifyContent={'space-between'} flexDirection={'column'}>
              <Typography variant="caption" fontSize={'12px'} mt="0.50rem" color={'white'}>
                <b>{event.eventDate ? dayjs(event.eventDate).format('MMMM, DD YYYY') : ''} | {event.eventStartTime ? dayjs(event.eventStartTime).format('hh:mm A') : ''} to {event.eventStartTime ? dayjs(event.eventEndTime).format('hh:mm A') : ''}</b>
              </Typography>
              <FlexBetween alignContent={'center'} justifyContent={'space-between'}>
                <LocationOn style={{ marginTop: '0.5rem' }} marginRight="4px" fontSize={'16px'} />
                <Typography variant="caption" fontSize={'12px'} mt="0.50rem" marginRight="4px" color={'white'}>  {event.location}</Typography>
              </FlexBetween>
              <Typography variant="caption" fontSize={'12px'} mt="0.50rem" color={'white'} marginRight="4px">  ₹ {`${event.ticketPrice} onwards`}</Typography>
              <Typography variant="caption" fontSize={'12px'} mt="0.50rem" color={'white'} marginRight="4px">  {`${event.spotsLeft ? event.spotsLeft : 0} spots left`}</Typography>



            </FlexBetween>
          </>
        }


      </Box>
      <Accordion defaultExpanded style={{ borderRadius: '10px', marginTop: "1rem", backgroundColor: 'black' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="about-content"
          id="about-header"
        >
          <Typography fontWeight={'500'} color={'white'}>About</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography variant="body2" gutterBottom>
            {event.aboutEvent.split("\n").map(str => <p>{str}</p>)}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ borderRadius: '10px', marginTop: "1rem", backgroundColor: 'black' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Artist-content"
          id="Artist-header"
        >
          <Typography fontWeight={'500'} color={'white'}>Artist</Typography>
        </AccordionSummary>
        {/* <Typography  variant="body2" gutterBottom> */}

        {/* </Typography> */}
        <AccordionDetails>
          <Grid container spacing={1}>
            {event.artists.map((artist) => (
              <Grid item>
                <Box style={{ position: "relative", textAlign: "center" }}>
                  <img
                    src="https://media.istockphoto.com/id/1160768128/photo/rap-musician-in-studio.jpg?s=612x612&w=0&k=20&c=8LzuLliur66CaZnXywhBZzcTRellYlRF3gRCTlSG3XE="
                    alt="Artist 1"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      marginBottom: "8px",
                    }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      background: "rgba(0, 0, 0, 0.5)",
                      color: "#fff",
                      padding: "4px",
                      borderRadius: "0 0 8px 8px",
                    }}
                  >
                    <Typography variant="caption">{artist.name}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded style={{ borderRadius: '10px', marginTop: "1rem", backgroundColor: 'black' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="disclaimer-content"
          id="disclaimer-header"
        >
          <Typography fontWeight={'500'} color={'white'}>Disclaimer</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography variant="body2" gutterBottom>
            {event.eventDesclaimer.split("\n").map(str => <p>{str}</p>)}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ borderRadius: '10px', marginTop: "1rem", backgroundColor: 'black' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="disclaimer-content"
          id="disclaimer-header"
        >
          <Typography fontWeight={'500'} color={'white'}>Terms & Conditions</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography
            variant="h6"
            mt="1rem"
            style={{ fontWeight: "bold", marginBottom: "0.5rem" }}
          >
            Facility
          </Typography>
          <Grid container spacing={2} mt="0.5rem">
            {event.eventFacility.map((facility) => (
              <Grid item>
                <Box style={{ textAlign: "center" }}>
                  <Box
                    width="45px"
                    height="45px"
                    borderRadius="50%"
                    border={`1px solid ${primary}`}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RestaurantIcon sx={{ color: primary }} />
                  </Box>
                  <Typography variant="caption">{facility}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Typography
            variant="h6"
            mt="1rem"
            style={{ fontWeight: "bold", marginBottom: "0.5rem" }}
          >
            Event Prohibited
          </Typography>
          <Grid container spacing={2} mt="0.5rem">
            {event.eventProhibited.map((probhited) => (
              <Grid item>
                <Box style={{ textAlign: "center" }}>
                  <Box
                    width="45px"
                    height="45px"
                    borderRadius="50%"
                    border={`1px solid ${primary}`}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NoFoodIcon sx={{ color: primary }} />
                  </Box>
                  <Typography variant="caption">{probhited}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* <FlexBetween mt="2rem" alignItems="center">
        <FlexBetween gap="2rem">
          <Box
            width="45px"
            height="45px"
            borderRadius="50%"
            border={`1px solid ${primary}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <EventAvailable sx={{ color: primary }} />
          </Box>
          <FlexBetween gap="0.3rem" flexDirection="column">
            <Typography>{eventDate ? dayjs(eventDate).format('MMMM, DD MMM YYYY') : ''}</Typography>
            <Typography>{eventStartTime ? dayjs(eventStartTime).format('hh:mm A') : ''} to {eventStartTime ? dayjs(eventEndTime).format('hh:mm A') : ''}</Typography>
          </FlexBetween>
        </FlexBetween>
      </FlexBetween> */}
      {/* <FlexBetween mt="0.75rem" alignItems="center">
        <FlexBetween gap="2rem">
          <Box
            width="45px"
            height="45px"
            borderRadius="50%"
            border={`1px solid ${primary}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LocationOn sx={{ color: primary }} />
          </Box>
          <Typography>{location}</Typography>
        </FlexBetween>
      </FlexBetween> */}


      {/* <Typography
        variant="h5"
        mt="2rem"
        style={{ fontWeight: "bold", marginBottom: "0.5rem" }}
      >
        Artist
      </Typography>
      <Grid container spacing={1}>
        {artists.map((artist) => (
          <Grid item>
            <Box style={{ position: "relative", textAlign: "center" }}>
              <img
                src="https://media.istockphoto.com/id/1160768128/photo/rap-musician-in-studio.jpg?s=612x612&w=0&k=20&c=8LzuLliur66CaZnXywhBZzcTRellYlRF3gRCTlSG3XE="
                alt="Artist 1"
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  marginBottom: "8px",
                }}
              />
              <Box
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  background: "rgba(0, 0, 0, 0.5)",
                  color: "#fff",
                  padding: "4px",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography variant="caption">{artist.name}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}

      </Grid> */}

      {/* <Button
        onClick={() => navigateToSummary()}
        variant="contained"
        sx={{
          p: "0.6rem",
          mt: "2rem",
          width: "100%",
          backgroundColor: palette.primary.main,
          color: palette.primary.dark,
          "&:hover": { color: palette.primary.main }
        }}
      >
        Book Now
      </Button> */}
    </WidgetWrapper>
  );
};

export default EventWidget;
