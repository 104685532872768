import React from 'react';
import { Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

const Content = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is small

    return (
        <>

            <Grid container justifyContent="center">
                <Grid item xs={12} style={{ backgroundColor: '#f7f7f7', padding: isSmallScreen ? '50px 20px' : '100px 130px' }}>
                    <Typography variant="body1" gutterBottom style={{ color: '#6d6f72', fontSize: '18px' }}>
                        <p>1. This Privacy Policy details the manner in which ANANTALOOP(“ANANTALOOP /Us/Our/We”) collects, restores, deals and handles the end users’ (“You/Your/User”) information made available by the User as a result of such User’s access to and use services provided by ANANTALOOP through VYBEZ Platform (which term shall be deemed to include the internet and mobile website www.insider.in as well as its mobile application).</p>
                        <br />
                        <p>
                            ANANTALOOP values the trust You place in it. That’s why ANANTALOOP insists upon the highest standards for secure transactions and customer information privacy on the VYBZE Platform. Please read the following statement to learn about ANANTALOOP s’s information gathering and dissemination practices.
                        </p>
                        <br />
                        <br />
                        <p>
                            The provisions of this Privacy Policy shall be deemed to be an integral part of and shall be read in conjunction with the T&C document (available here) and shall govern Your use of the services provided through VYBZE Platform. This Privacy Policy has been published by ANANTALOOP pursuant to the applicable provisions of the Information Technology Act, 2000 as amended and the relevant rules made thereunder as applicable, and the amended provisions pertaining to electronic records in various statutes under applicable Indian laws.
                        </p>
                        <br />
                        <p>Your use of the VYBEZ Platform and the services provided thereunder shall be governed by this Privacy Policy as well as any other applicable policies published on this VYBZE Platform, including but not limited to the Privacy Policy. Your use of the VYBEZ Platform whether for the purpose of transacting through VYBEZ Platform or otherwise, implies Your specific consent to the provisions of this Privacy Policy as well as other applicable policies, including but not limited to ANANTALOOP ’s use of the information provided by You for the purpose of Your use of this VYBZE Platform. ANANTALOOP hereby reserves the right to modify, add or delete any part of this Privacy Policy without prior written notice/ intimation to You. It shall be Your sole responsibility to periodically review this Privacy Policy and all other applicable policies published on the VYBEZ Platform for any revisions thereto. If You do not agree to the revisions made to this Privacy Policy by ANANTALOOP , You must discontinue further access and use of the VYBEZ Platform and the services provided thereunder. You agree and acknowledge that such discontinuance of access to and use of the VYBEZ Platform and the services provided thereunder will render the Privacy Policy inapplicable from such date of discontinuance only; however this Privacy Policy will remain applicable to all instances of use by You of VYBEZ Platform and the services provided thereunder prior to such date of discontinuance by You.

                        </p>
                        <br />
                        <p>
                            It is clarified that terms and conditions specific to an event shall be published on the event page accessible on the VYBZE Platform. In the event of any conflict between the event-related terms and conditions, and this Privacy Policy, User agrees that the terms of this Privacy Policy shall prevail and be binding on the User at all times.
                        </p>
                        <br />
                        <p>
                            By mere use of the VYBZE Platform, You expressly consent to Our use and disclosure of Your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the T&C.
                        </p>
                        <br />
                        <p>COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION AND OTHER INFORMATION</p>
                        <br />
                        <p>When You use the VYBZE Platform, We collect and store Your personal information which is provided by You from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows Us to provide services and features that most likely meet Your needs, and to customize VYBEZ Platform to make Your experience safer and easier. More importantly, while doing so We may collect personal information from You that We consider necessary for achieving this purpose.</p>
                        <br />
                        <p>In general, You can browse the VYBEZ Platform without telling Us who You are or revealing any personal information about Yourself. Once You give Us Your personal information, You are not anonymous to us. Where possible, We indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service or feature on the VYBZE Platform. We may automatically track certain information about You based upon Your behaviour on Our VYBZE Platforms. We use this information to do internal research on Our users’ demographics, interests, and behaviour to better understand, protect and serve Our users. This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on VYBEZ Platform or not), which URL You next go to (whether this URL is on Our VYBEZ Platform or not), Your computer browser information, and Your IP address.</p>
                        <br />
                        <p>
                            We use data collection devices such as “cookies” on certain pages of VYBEZ Platform to help analyse Our web page flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small files placed on Your hard drive that assist Us in providing Our services. We offer certain features that are only available through the use of a “cookie”.
                        </p>
                        <br />
                        <p>
                            We also use cookies to allow You to enter Your password less frequently during a session. Cookies can also help Us provide information that is targeted to Your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from Your hard drive at the end of a session. You are always free to decline Our cookies if Your browser permits, although in that case You may not be able to use certain features on VYBEZ Platform and You may be required to re-enter Your password more frequently during a session.
                        </p>

                        <br />
                        <p>
                            Additionally, You may encounter “cookies” or other similar devices on certain pages of VYBEZ Platform that are placed by third parties. We do not control the use of cookies by third parties.</p>
                        <br />
                        <p>If You choose to buy on VYBZE Platform, We collect information about Your buying behaviour.</p>
                        <br />
                        <p>If You choose to buy on VYBZE Platform, We collect information about Your buying behaviour.</p>
                        <br />
                        <p>If You choose to post messages on Our message boards, chat rooms or other message areas or leave feedback, We will collect that information You provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.</p>
                        <br />
                        <p>If You send Us personal correspondence, such as emails or letters, or if other users or third parties send Us correspondence about Your activities or postings on VYBZE Platform, We may collect such information into a file specific to you.

                        </p>
                        <br />
                        <p>We collect personally identifiable information (email address, name, mobile number, credit card / debit card / other payment instrument details, etc.) from you when You set up a free account with us. While You can browse some sections of Our VYBEZ Platform without being a registered member, certain activities (such as placing an order) do require registration. We do use Your contact information to send You offers based on Your previous orders and Your interests.</p>

                        <br />
                        <p>
                            USE OF DEMOGRAPHIC / PROFILE DATA / YOUR INFORMATION
                        </p>
                        <br />
                        <p>
                            We use personal information to provide the services You request. To the extent possible, We use Your personal information to market to You or share information with certain other parties to market to You, We will provide You the ability to opt-out of such uses. We use Your personal information to resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest in Our products and services, inform You about online and offline offers, products, services, and updates; customize Your experience; detect and protect Us against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to You at the time of collection.
                        </p>
                        <br />
                        <p>In Our efforts to continually improve Our product and service offerings, We collect and analyse demographic and profile data about Our users’ activity on VYBZE Platform.</p>
                        <br />
                        <p>
                            We identify and use Your IP address to help diagnose problems with Our server, and to administer VYBZE Platform. Your IP address is also used to help identify you and to gather broad demographic information.
                        </p>
                        <br />
                        <p>
                            We may occasionally ask You to complete optional online surveys. These surveys may ask You for contact information and demographic information (like zip code, age, or income level). We use this data to tailor Your experience on the VYBZE Platform, providing You with content that We think You might be interested in and to display content according to Your preferences.
                        </p>
                        <br />
                        <br />
                        <p>                        Cookies
                        </p>

                        <br />
                        <p>SHARING OF PERSONAL INFORMATION</p>
                        <br />
                        <p>
                            We may share personal information with our affiliates, the Event Organizer as well as the Event Organizer’s (as defined under the T&C, available here) affiliates for various purposes, including but not limited to help with the detection and prevention of identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent abuse of Our services; to facilitate marketing and promotional activities. Unless you explicitly agree to these T&Cs at the time of booking a ticket to an Event (as defined under T&Cs), the Event Organizer and its affiliates may not market to You as a result of such sharing of personal information by You.
                        </p>
                        <br />
                        <p>
                            We may also disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce Our Terms and Conditions (“T&C”) or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of Our users or the general public.
                        </p>
                        <br />
                        <p>We and Our affiliates will also share / sell some or all of Your personal information with another business entity should We (or Our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur that other business entity (or the new combined entity) will be required to follow this Privacy Policy with respect to Your personal information.</p>

                        <br />
                        <p>
                            LINKS TO OTHER SITES
                        </p>
                        <br />
                        <p>VYBZE Platforms link to other websites that may collect personally identifiable information about You. VYBZE is not responsible for the privacy practices or the content of those linked websites.

                        </p>
                        <br />
                        <p>SECURITY PRECAUTIONS</p>
                        <br />
                        <p>VYBZE Platforms have stringent security measures in place to protect the loss, misuse, and alteration of the information under Our control. Whenever You change or access Your account information, We offer the use of a secure server. Once your information is in Our possession We adhere to strict security guidelines, protecting it against unauthorized access.
                        </p>
                        <br />
                        <p>>CHOICE/OPT-OUT</p>
                        <p>
                            We provide all Users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from Us on behalf of our partners, and from Us in general, after setting up an account.
                        </p>
                        <br />
                        <p>If You want to remove Your contact information from all VYBZE lists and newsletters, please click on the unsubscribe links in the emailer</p>
                        <br />
                        <p>ACCOUNT DELETION</p>
                        <br />
                        <p>If You wish to delete Your account from the VYBZE Platform, please send a request from your registered email id to help@vybez.in. Your account will be deleted as soon as is reasonably possible within 5-7 working days and confirmation of deletion of your account will be sent to your registered email id.</p>
                        <br />
                        <p>Please note that, after Your account is deleted from the VYBZE Platform, You will be automatically unsubscribed from receiving any communication from Us. Furthermore, You will not be able to login into the VYBEZ Platform from the same registered email id.</p>
                        <br />
                        <p>You may sign up and create a new account with the same email id and avail the benefits of Our platform. However, please note that once We have confirmed deletion of Your account, You will be unable to retrieve any of Your data or account information that was created using Your previous account. An account created using the same email id as used before, will be considered as a new account for the purposes of this privacy policy and Your transaction history associated with the previous account will not be accessible or retrievable by You.</p>
                        <br />
                        <p>Please note that if you access the VYBEZ Platform using the social login plugin whereby You use the VYBEZ Platform through a third-party website, such as for e.g., facebook or gmail, then if You wish to discontinue Your use of the VYBZE Platform, You are advised to open your personal account on the third-party website facilitating Your access and visit the section that allows You to change settings related to third party applications. Once there, You may remove authorization for the VYBEZ Platform from such third-party website. Once You have removed such authorization, then VYBEZ Platform will no longer be accessible by You through such a social login plugin, until you once again grant authorization.

                        </p>
                        <br />
                        <p>After You delete Your account, we may retain information including Your transactions history which we are legally obligated to retain as per the applicable laws including the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 read with Information Technology (Intermediaries guidelines) Rules, 2011, for the purposes of any legal or regulatory investigations.

                        </p>
                        <br />
                        <p>

                            ADVERTISEMENTS ON VYBZE
                        </p>
                        <br />
                        <p>
                            YOUR CONSENT
                        </p>
                        <br />
                        <p>By using the VYBEZ Platform and/ or by providing Your information, You consent to the collection and use of the information You disclose on VYBEZ Platform in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this Privacy Policy.</p>
                        <br />
                        <p>If We decide to change Our Privacy Policy, We will post those changes on this page so that You are always aware of what information We collect, how We use it, and under what circumstances We disclose it.

                        </p>
                        <br />
                        <p>
                            GRIEVANCE OFFICER
                        </p>
                        <br />
                        <p>
                            In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                        </p>
                        <br />
                        <p>
                            ANANTALOOP
                        </p>
                        <br />
                        <p>
                            4TH FLOOR ,9th Cross Rd,

                            J. P. Nagar, Bengaluru,
                            2nd Phase,

                            Karnataka 560078
                        </p>
                        <br />
                        <br />
                        <br />
                        <p>Time: Mon to Sat (9:00 to 18:00)</p>
                        <br />
                        <p>
                            QUESTIONS?
                        </p>
                        <br />
                        <p>
                            Please Contact Us regarding any questions regarding this statement .
                        </p>
                    </Typography>
                </Grid>

            </Grid>

        </>
    );
}

export default Content;
