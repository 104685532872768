import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import { Typography, Grid, Box } from "@mui/material";
import EventTicketWidget from "./EventTicketWidget";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { cloneDeep } from "lodash";
import { setEventPurchasedTickets } from "state";
import { toast } from 'react-toastify';

const EventTicketsWidget = ({ userId, isProfile, tickets, event }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const token = useSelector((state) => state.token);

  const [totalAmount, setTotalAmount] = useState(0);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const primaryMain = palette.primary.main;
  const primaryDark = palette.primary.dark;
  const [ticketCount, setTicketCount] = useState(0);
  const [updatedTickets, setTicketPurchaseCount] = useState([]);
  const [disabledTicketTypes, setDisabledTicketTypes] = useState([]);

  useEffect(() => {
    // const newTotalAmount = tickets.reduce(
    //   (accumulator, ticket) =>
    //     accumulator + ticket.ticketPrice * ticket.ticketCount,
    //   0
    // );

    // setTotalAmount(newTotalAmount);
    setTicketPurchaseCount(tickets)
  }, []);


  const handleIncrease = (ticket) => {
    let updatedTicketsVar = cloneDeep(updatedTickets);
    let index = updatedTicketsVar.findIndex((ele) => ele.ticketId == ticket.ticketId)
    // let otherTicketsTypes = [];
    // updatedTicketsVar.map((ele) => {
    //   if (ele.ticketId !== ticket.ticketId) {
    //     otherTicketsTypes.push(ele.ticketId)
    //   }
    // })
    // setDisabledTicketTypes(otherTicketsTypes)
    if (index != -1) {
      if (updatedTicketsVar[index]['ticketPurchaseCount'] == undefined) {
        updatedTicketsVar[index]['ticketPurchaseCount'] = 0
      }
      if (updatedTicketsVar[index].ticketPurchaseCount < updatedTicketsVar[index].spotsLeft) {
        updatedTicketsVar[index].ticketPurchaseCount = updatedTicketsVar[index].ticketPurchaseCount + 1;
        setTicketPurchaseCount(updatedTicketsVar);
      }
    }

  };

  const handleDecrease = (ticket) => {
    let updatedTicketsVar = cloneDeep(updatedTickets);
    let index = updatedTicketsVar.findIndex((ele) => ele.ticketId == ticket.ticketId)
    if (index != -1) {
      if (updatedTicketsVar[index]['ticketPurchaseCount'] == undefined) {
        updatedTicketsVar[index]['ticketPurchaseCount'] = 0
      }
      if (updatedTicketsVar[index].ticketPurchaseCount > 0) {
        updatedTicketsVar[index].ticketPurchaseCount = updatedTicketsVar[index].ticketPurchaseCount - 1;
        setTicketPurchaseCount(updatedTicketsVar);
        // if (updatedTicketsVar[index].ticketPurchaseCount === 0) {
        //   setDisabledTicketTypes([])
        // }
      }
    }
  };

  const checkIfTicketIsSelected = () => {
    let updatedTicketsVar = cloneDeep(updatedTickets);
    let count = 0;
    updatedTicketsVar.map((ele) => {
      count = count + parseInt(ele.ticketPurchaseCount)
    })
    if (count != 0) {
      return true
    } else {
      return false
    }
  }


  const proceedToPurchase = () => {
    if (checkIfTicketIsSelected() == true) {
      dispatch(setEventPurchasedTickets({ purchasedTickets: updatedTickets }));
      navigate(`/checkout/initiate`)
    } else {
      toast.error(' Please select atleast 1 ticket to continue.')
    }

  }
  return (
    <>
      <Typography
        variant="h4"
        mb="1rem"
        sx={{ color: primary, fontWeight: "bold" }}
      >
        What Tickets Would You Like?
      </Typography>
      {updatedTickets.map(
        (ticket) => (
          <WidgetWrapper style={{ backgroundColor: (disabledTicketTypes.includes(ticket.ticketId) ? palette.neutral.light : palette.background.alt) }} mb="1rem">
            <FlexBetween justifyContent="center" alignItems="center" gap="0.75rem"
              style={{ cursor: (disabledTicketTypes.includes(ticket.ticketId) ? 'not-allowed' : '') }}>
              <Grid item xs={12} md={7}>
                <Box>
                  <Typography variant="h4" pb="0.5rem" sx={{ color: primary }}>
                    {ticket.name}
                  </Typography>
                  <Typography style={{ fontSize: "12px" }} pb="0.75rem">
                    {ticket.description}
                  </Typography>
                  <Typography pb="0.75rem">Spots Left: {ticket.spotsLeft}</Typography>
                  <Typography pb="0.75rem">Ticket Price: Rs.{ticket.ticketPrice ? ticket.ticketPrice : 0}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <FlexBetween flexDirection="column" alignItems="center" gap="1rem">
                  <FlexBetween gap="1rem">
                    <Button data-umami-event-checkoutpage={`${ticket.name}-${event.eventName}`}
                      data-umami-event="Purchase-ticket-minus-button"
                      disabled={disabledTicketTypes.includes(ticket.ticketId)} variant="outlined" onClick={() => handleDecrease(ticket)}>
                      -
                    </Button>
                    <Typography variant="h6">{ticket.ticketPurchaseCount}</Typography>
                    <Button data-umami-event-checkoutpage={`${ticket.name}-${event.eventName}`}
                      data-umami-event="Purchase-ticket-plus-button"
                      disabled={disabledTicketTypes.includes(ticket.ticketId)} variant="outlined" onClick={() => handleIncrease(ticket)}>
                      +
                    </Button>
                  </FlexBetween>
                  <Typography>Total Price: Rs.{(ticket.ticketPrice ? ticket.ticketPrice : 0) * (ticket.ticketPurchaseCount ? ticket.ticketPurchaseCount : 0)}</Typography>
                </FlexBetween>
              </Grid>
            </FlexBetween>
          </WidgetWrapper>
        )
      )}
      <Button
        data-umami-event-checkoutpage={event.eventName}
        data-umami-event="Purchase-ticket-button"
        variant="contained"
        onClick={() => proceedToPurchase()}
        sx={{
          backgroundColor: primaryMain,
          color: primaryDark,
          "&:hover": { color: primaryMain },
          p: "0.6rem",
          width: "100%",
        }}
      >
        Purchase
      </Button>
    </>
  );
};

export default EventTicketsWidget;
