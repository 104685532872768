import React, { useState } from "react";
import Logo from "../../assests/logo/Vybez_Logo.png";

const LandingNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-black py-4 px-8">
      <div className="container mx-auto flex justify-between items-center">
        <div className="">
          <a href="/">
            <img src={Logo} alt="Logo" className="h-24 w-auto" />
          </a>
        </div>
        <div className="hidden md:flex space-x-10 px-8">
          <a
            href="#aboutus"
            className="text-white text-base font-medium scroll-smooth hover:underline"
          >
            Explore Who We Are
          </a>
          <a
            href="/blog"
            className="text-white text-base font-medium hover:underline"
          >
            Blog
          </a>
          <a
            href="/login"
            className="text-[#E6C100] text-base !font-bold hover:underline"
          >
            Sign In
          </a>
        </div>
        <div className="md:hidden flex items-center">
          <a
            href="/login"
            className="text-[#E6C100] pr-6 text-base !font-bold hover:underline"
          >
            Sign In
          </a>
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="p-4 mt-6">
            <a
              href="#aboutus"
              className="block text-white text-base p-2 font-medium scroll-smooth hover:underline"
            >
              Explore Who We Are
            </a>
            <a
              href="/blog"
              className="block text-white text-base p-2 font-medium scroll-smooth hover:underline"
            >
              Blog
            </a>
            {/* <a
              href="/login"
              className="block text-[#E6C100] text-base !font-bold  p-2 font-medium scroll-smooth hover:underline"
            >
              Sign In
            </a> */}
          </div>
        </div>
      )}
    </nav>
  );
};

export default LandingNavbar;
