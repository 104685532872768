import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DiscountIcon from "@mui/icons-material/Discount";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  Divider,
  Popover,
  Typography,
  useTheme,
  CircularProgress,
  Backdrop,
  Checkbox
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CouponCardWidget from "./CouponCardWidget";
import dayjs from "dayjs";
import { apiUrl } from "config";
import { cloneDeep } from "lodash";
import { razorPayScriptUrl } from "config";
import Logo from "../../assests/logo/Vybez_Logo.png";
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { seo } from "utilites/utlis";

const CheckoutWidget = ({ checkoutData }) => {
  const [isAttending, setIsAttending] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [paramTransactionId, setTransaction] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUser = useSelector((state) => state.user);
  const event = useSelector((state) => state.event);
  const purchasedTickets = useSelector((state) => state.purchasedTickets);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  // const fees = 50;
  const taxePercent = 1.18;
  const params = useParams();
  const [fees, setFees] = useState(0);
  const [privacyPolicy, setPrivacyPolicyChecked] = useState(false);
  const [termsAndCondn, setTermsAndCondnChecked] = useState(false);
  const sampleCoupons = [
    // {
    //   code: "COUPON1",
    //   description: "20% off on all products",
    //   expiresOn: "2023-12-31",
    // },
    // {
    //   code: "COUPON2",
    //   description: "Free shipping on orders over $50",
    //   expiresOn: "2023-12-31",
    // },
    // {
    //   code: "COUPON3",
    //   description: "$10 off on your first order",
    //   expiresOn: "2023-12-31",
    // },
  ];
  seo({title:`Checkout: ${event.eventName}`,metaDescription:`Checkout: ${event.eventName}`})

  useEffect(() => {
    if (event) {
      if (event.eventType == 'GUESTLIST') {
        setFees(0)
      } else {
        setFees(50)
      }
    }
  }, []);
  const handleChangePP = (event) => {
    setPrivacyPolicyChecked(event.target.checked);
  };
  const handleChangeTC = (event) => {
    setTermsAndCondnChecked(event.target.checked);
  };
  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handlePopupApply = () => {
    handlePopupClose();
  };

  const getTotalTickets = () => {
    let tickets = cloneDeep(purchasedTickets);
    if (tickets) {
      let ticketStr = "";
      tickets.map((ele, index) => {
        ticketStr =
          ticketStr +
          ele.name +
          ": " +
          ele.ticketPurchaseCount +
          (index == tickets.length - 1 ? "" : ",");
      });
      return ticketStr;
    } else {
      return "";
    }
  };

  const getTotalTicketPrice = () => {
    let tickets = cloneDeep(purchasedTickets);
    if (tickets) {
      let totalTicketsPrice = 0;
      tickets.map((ele, index) => {
        totalTicketsPrice =
          totalTicketsPrice +
          parseInt(ele.ticketPurchaseCount) * parseInt((ele.ticketPrice ? ele.ticketPrice : 0));
      });
      return totalTicketsPrice;
    } else {
      return 0;
    }
  };

  const totalPrice = () => {
    let tickets = cloneDeep(purchasedTickets);
    if (tickets) {
      let totalTicketsPrice = 0;
      tickets.map((ele, index) => {
        totalTicketsPrice =
          totalTicketsPrice +
          parseInt(ele.ticketPurchaseCount) * parseInt((ele.ticketPrice ? ele.ticketPrice : 0));
      });
      let finalPrice = totalTicketsPrice + fees;
      let gstPrice = finalPrice * taxePercent;
      return gstPrice;
    } else {
      return 0;
    }
  };
  const getTotalTicketPriceWithGst = () => {
    let tickets = cloneDeep(purchasedTickets);
    if (tickets) {
      let totalTicketsPrice = 0;
      tickets.map((ele, index) => {
        totalTicketsPrice =
          totalTicketsPrice +
          parseInt(ele.ticketPurchaseCount) * parseInt((ele.ticketPrice ? ele.ticketPrice : 0));
      });
      let finalPrice = totalTicketsPrice + fees;
      let gstPrice = finalPrice * (0.18);
      return gstPrice;
    } else {
      return 0;
    }
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const initiatePayment = async () => {
    setLoading(true);
    let totalPrice = 0;
    let tickets = cloneDeep(purchasedTickets);
    let discount = 0;
    let taxAmt = 0;
    let noOfTickets = 0;
    if (tickets) {
      let totalTicketsPrice = 0;
      tickets.map((ele, index) => {
        totalTicketsPrice = totalTicketsPrice + parseInt(ele.ticketPurchaseCount) * parseInt((ele.ticketPrice ? ele.ticketPrice : 0));
        noOfTickets = noOfTickets + parseInt(ele.ticketPurchaseCount);
      });
      let price = totalTicketsPrice + fees;
      taxAmt = price * (0.18);
      totalPrice = price * taxePercent;
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      setLoading(false);
      return;
    }

    const response = await fetch(`${apiUrl}/checkout/initiate-order`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        totalPrice: totalPrice,
        taxes: taxAmt,
        fees: fees,
        discount: discount,
        eventId: event._id,
        userId: loggedInUserId,
        purchasedTickets: purchasedTickets,
        name: (loggedInUser.firstName ? loggedInUser.firstName : '') + " " + (loggedInUser.lastName ? loggedInUser.lastName : ''),
        email: loggedInUser.email,
        contact: loggedInUser.phone,
        noOfTicketsPurchased: noOfTickets,
        eventType: event.eventType
      }),
    });
    const orderUpdate = await response.json();
    if (!orderUpdate) {
      alert("Server error. Are you online?");
      setLoading(false);
      return;
    }
    const { amount, id: order_id, currency } = orderUpdate;
    const options = {
      key: "rzp_test_S2okzzmVFjUYuJ", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "VybeZ",
      description: `For ticket purchase of ${event.eventName}`,
      image: { Logo },
      order_id: order_id,
      handler: async (response) => {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          eventId: event._id,
          userId: loggedInUserId,
          name: (loggedInUser.firstName ? loggedInUser.firstName : '') + " " + (loggedInUser.lastName ? loggedInUser.lastName : ''),
          email: loggedInUser.email,
          contact: loggedInUser.phone,
          tickets: purchasedTickets,
        };
        console.log("data payment sttaus", data, response);
        const completeOrderResp = await fetch(
          `${apiUrl}/checkout/complete-order`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: data }),
          }
        );
        console.log("completeOrderResp", completeOrderResp);
        setLoading(false);
        navigate("/bookinghistory");
        // alert(data.razorpayOrderId);
      },
      prefill: {
        name: loggedInUser.firstName,
        email: loggedInUser.email,
        contact: loggedInUser.phone,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#E9FE09",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  const initiatePaymentPhonePe = async () => {
    try {
      setLoading(true);
      let totalPrice = 0;
      let tickets = cloneDeep(purchasedTickets);
      let discount = 0;
      let noOfTickets = 0;
      let taxAmt = 0;
      if (tickets) {
        let totalTicketsPrice = 0;
        tickets.map((ele, index) => {
          totalTicketsPrice = totalTicketsPrice + parseInt(ele.ticketPurchaseCount) * parseInt(ele.ticketPrice);
          noOfTickets = noOfTickets + parseInt(ele.ticketPurchaseCount);
        });
        let price = totalTicketsPrice + fees;
        taxAmt = price * (0.18);
        totalPrice = price * taxePercent;
      }


      const response = await fetch(`${apiUrl}/checkout/initiate-order-phonepe`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          totalPrice: totalPrice,
          taxes: taxAmt,
          fees: fees,
          discount: discount,
          eventId: event._id,
          userId: loggedInUserId,
          purchasedTickets: purchasedTickets,
          name: (loggedInUser.firstName ? loggedInUser.firstName : '') + " " + (loggedInUser.lastName ? loggedInUser.lastName : ''),
          email: loggedInUser.email,
          contact: loggedInUser.phone,
          noOfTicketsPurchased: noOfTickets
        }),
      });
      const orderUpdate = await response.json();
      if (orderUpdate.error == false) {
        console.log("orderUpdate", orderUpdate);
        window.open(orderUpdate.order.redirectUrl, "_self", "popup=yes", 'height=200,width=150');
      }
    } catch (e) {
      setLoading(false);
      toast.error(`Unable to initiate transaction. Try again.`)
    }


  };
  const checkTransactionStatus = async () => {

    if (params.transactionId != undefined && params.transactionId != '') {
      if (params.transactionId !== 'initiate' && paramTransactionId != params.transactionId) {
        setTransaction(params.transactionId)
        setPageLoading(true);
        const response = await fetch(`${apiUrl}/checkout/check-transaction-status`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            transactionId: params.transactionId,
            orderCreationId: params.transactionId,
            eventId: event._id,
            userId: loggedInUserId,
            name: (loggedInUser.firstName ? loggedInUser.firstName : '') + " " + (loggedInUser.lastName ? loggedInUser.lastName : ''),
            email: loggedInUser.email,
            contact: loggedInUser.phone,
            tickets: purchasedTickets
          }),
        });
        const transactionStatus = await response.json();
        if (transactionStatus.error == false) {
          toast.success("Transaction is successful. Get ready to Vybe it!", {
            toastId: params.transactionId
          });
          navigate('/bookinghistory');
        } else {
          toast.error(`Transaction unsuccessful. Error: ${transactionStatus.data.message}.`)
        }
      }
    }
  }


  const initiateRSVP = async () => {
    try {


      setLoading(true);
      let totalPrice = 0;
      let tickets = cloneDeep(purchasedTickets);
      let discount = 0;
      let taxAmt = 0;
      let noOfTickets = 0;
      if (tickets) {
        let totalTicketsPrice = 0;
        tickets.map((ele, index) => {
          totalTicketsPrice = totalTicketsPrice + parseInt(ele.ticketPurchaseCount) * parseInt((ele.ticketPrice ? ele.ticketPrice : 0));
          noOfTickets = noOfTickets + parseInt(ele.ticketPurchaseCount);
        });
        let price = totalTicketsPrice + fees;
        taxAmt = price * (0.18);
        totalPrice = price * taxePercent;
      }
      const response = await fetch(`${apiUrl}/checkout/complete-order-rsvp`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          totalPrice: totalPrice,
          taxes: taxAmt,
          fees: fees,
          discount: discount,
          eventId: event._id,
          userId: loggedInUserId,
          purchasedTickets: purchasedTickets,
          name: (loggedInUser.firstName ? loggedInUser.firstName : '') + " " + (loggedInUser.lastName ? loggedInUser.lastName : ''),
          email: loggedInUser.email,
          contact: loggedInUser.phone,
          noOfTicketsPurchased: noOfTickets,
          eventType: event.eventType
        }),
      });
      const orderUpdate = await response.json();
      if (orderUpdate.error == false) {
        setLoading(false);
        toast.success("Booking Successful. Get ready to Vybe it!")
        navigate("/bookinghistory");
      } else {
        toast.error("Unable to complete the booking. Try again later")
        setLoading(false);
        return;
      }
    } catch (e) {
      toast.error("Unable to complete the booking. Try again later")
      setLoading(false);
      return;
    }

  }

  useEffect(() => {
    checkTransactionStatus()
  }, [params.transactionId]);
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={pageLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <WidgetWrapper mb="1rem">
        <FlexBetween justifyContent="center" alignItems="center" gap="0.75rem">
          <Box width="35%">
            <img
              width="100%"
              height="auto"
              alt="event"
              style={{ borderRadius: "0.75rem", marginTop: "0.50rem" }}
              src={`${event.picturePath}`}
            />
          </Box>
          <Box width="60%">
            <Typography variant="h4" pb="1rem" sx={{ color: primary }}>
              {event.eventName}
            </Typography>
            {/* <Typography variant="p" pb=".5rem" sx={{ color: main }}>
              {event.eventDate} - {checkoutData.eventTime}
            </Typography> */}
            <Typography variant="p" pb=".5rem" sx={{ color: main }}>
              {event.eventDate
                ? dayjs(event.eventDate).format("MMMM, DD MMM YYYY")
                : ""}
            </Typography>
            <br />
            <Typography variant="p" pb=".5rem" sx={{ color: main }}>
              {event.eventStartTime
                ? dayjs(event.eventStartTime).format("hh:mm A")
                : ""}{" "}
              to{" "}
              {event.eventStartTime
                ? dayjs(event.eventEndTime).format("hh:mm A")
                : ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: main, display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon sx={{ fontSize: 16, marginRight: "4px" }} />
              {event.location}
            </Typography>
          </Box>
        </FlexBetween>
      </WidgetWrapper>

      <WidgetWrapper mb="1rem">
        <FlexBetween justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DiscountIcon sx={{ fontSize: 16, marginRight: "4px" }} /> Apply
            Coupon
          </Typography>
          {/* <Button color="primary" onClick={handlePopupOpen}>
            Apply
          </Button> */}
        </FlexBetween>
        <Divider sx={{ my: "1rem" }} />
        <Button variant="text" fullWidth onClick={handlePopupOpen}>
          <FlexBetween
            justifyContent="space-between"
            alignItems="center"
            m=".25rem"
          >
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center" }}
            >
              View All Coupons
            </Typography>
            <ArrowRightIcon />
          </FlexBetween>
        </Button>
      </WidgetWrapper>
      <WidgetWrapper mb="1rem">
        <Typography variant="h6" color="primary">
          Order Summary
        </Typography>

        {/* Price */}
        <FlexBetween
          justifyContent="space-between"
          alignItems="center"
          my=".5rem"
        >
          <Typography variant="body1">Price({getTotalTickets()})</Typography>
          <Typography variant="body1">Rs. {getTotalTicketPrice()}</Typography>
        </FlexBetween>

        {/* Fees */}
        <FlexBetween
          justifyContent="space-between"
          alignItems="center"
          my=".5rem"
        >
          <Typography variant="body1">Fees</Typography>
          <Typography variant="body1">Rs. {fees}</Typography>
        </FlexBetween>

        {/* Taxes */}
        <FlexBetween
          justifyContent="space-between"
          alignItems="center"
          my=".5rem"
        >
          <Typography variant="body1">Taxes(GST 18%)</Typography>
          <Typography variant="body1">Rs. {getTotalTicketPriceWithGst()}</Typography>
        </FlexBetween>

        {/* Horizontal Line */}
        <Divider sx={{ my: "1rem" }} />

        {/* Total Price */}
        <FlexBetween
          justifyContent="space-between"
          alignItems="center"
          my=".5rem"
        >
          <Typography variant="body1">Total Price</Typography>
          <Typography variant="body1">Rs. {totalPrice()}</Typography>
        </FlexBetween>
      </WidgetWrapper>
      {/* <Button
        variant="contained"
        onClick={() => { initiatePayment() }}
        sx={{
          backgroundColor: palette.primary.main,
          color: primaryDark,
          "&:hover": { color: palette.primary.main },
          p: "0.6rem",
          width: "100%",
        }}
      >
        Confirm
      </Button> */}
      <FlexBetween gap={"1rem"} style={{ justifyContent: 'flex-start' }}>
        <Checkbox
          checked={termsAndCondn}
          onChange={handleChangeTC}
          inputProps={{ 'aria-label': 'controlled' }}
        /><Typography
          textAlign={"center"}
          sx={{
            gridColumn: "span 4",
            color: palette.primary.main,
            "&:hover": {
              cursor: "pointer",
              color: palette.primary.light,
            },
          }}
        >
          Accept <a style={{ textDecoration: "underline", color: "white" }} target="_blank" href="https://vybez.in/terms-and-conditions/">Terms and Conditions</a> & <a target="_blank" style={{ textDecoration: "underline", color: "white" }} href="https://vybez.in/refund-policy/">Refund policy</a>
        </Typography>
      </FlexBetween>
      <FlexBetween gap={"1rem"} style={{ justifyContent: 'flex-start' }}>
        <Checkbox
          checked={privacyPolicy}
          onChange={handleChangePP}
          inputProps={{ 'aria-label': 'controlled' }}
        /><Typography
          textAlign={"center"}
          sx={{
            gridColumn: "span 4",
            color: palette.primary.main,
            "&:hover": {
              cursor: "pointer",
              color: palette.primary.light,
            },
          }}
        >
          Accept <a style={{ textDecoration: "underline", color: "white" }} target="_blank" href="https://vybez.in/privacy-policy/">Privacy policy</a>
        </Typography>
      </FlexBetween>
      {event.eventType == 'GUESTLIST' ?
        <LoadingButton
        data-umami-event-checkoutpage={event.eventName} 
        data-umami-event="Guest-list-button"
          disabled={(privacyPolicy == false || termsAndCondn == false) == true ? true : false}
          sx={{
            width: "100%",
            backgroundColor: palette.primary.main,
            color: palette.primary.dark,
            "&:hover": { color: palette.primary.main },
          }}
          color="primary"
          onClick={() => {
            initiateRSVP();
          }}
          loading={loading}
          loadingIndicator="Initiating Payment..."
          variant="contained"
        >
          <span>Confirm & Reserve</span>
        </LoadingButton> :
        <LoadingButton
        data-umami-event-checkoutpage={event.eventName} 
        data-umami-event="Confirm-and-Pay-button"
          disabled={(privacyPolicy == false || termsAndCondn == false) == true ? true : false}
          sx={{
            width: "100%",
            backgroundColor: palette.primary.main,
            color: palette.primary.dark,
            "&:hover": { color: palette.primary.main },
          }}
          color="primary"
          onClick={() => {
            initiatePayment();
          }}
          loading={loading}
          loadingIndicator="Initiating Payment..."
          variant="contained"
        >
          <span>Confirm & Pay</span>
        </LoadingButton>}

      {/* Popup for Applying Coupon */}
      <Popover
        open={popupOpen}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopupClose}
      >
        <Box p="2rem" maxWidth="600px" style={{ position: "relative" }}>
          {sampleCoupons.length > 0 ? (
            <CouponCardWidget coupons={sampleCoupons} />
          ) : (
            <WidgetWrapper mb="1rem" p="1rem">
              <Typography
                variant="h6"
                color="primary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <DiscountIcon sx={{ fontSize: 16, marginRight: "4px" }} /> No
                Coupons Available
              </Typography>
            </WidgetWrapper>
          )}

          <Button
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "1rem",
            }}
            color="primary"
            onClick={handlePopupClose}
          >
            Close
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default CheckoutWidget;
