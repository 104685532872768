import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VenueCard from "./venueCardComponent";
import image from "../../assests/images/badmaash.png";
import { Stack, Pagination, Typography } from "@mui/material";
import "../../assests/css/swiper.css";
import { apiUrl } from "config";


const VenuesSection = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [venues, setVenues] = useState([]);
  const [totalVenues, setTotalVenues] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchVenues(page);
  }, []);
  const fetchVenues = async (page) => {
    try {
      const response = await fetch(`${apiUrl}/users/venues/${page}/${limit}`);
      const data = await response.json();
      setVenues(data.venues);
      setTotalVenues(data.total)
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    console.log("searchTerm",searchTerm)
    if(searchTerm == undefined || searchTerm == null || searchTerm == ''){
      fetchVenues(page);
    }else{
      const response = await fetch(`${apiUrl}/users/venues-search/${searchTerm}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
  
      });
      let respData = await response.json();
      let venues = respData.venues || []
      setVenues(venues);
      setTotalVenues(respData.total)
    }
  };


  const handleChange = (event, value) => {
    setPage(value);
    fetchVenues(value);
  };



  const navigateToVenueDetail = (venue) => {
    navigate(`/venue-detail/${venue.profileSlug}`);
  };

  return (
    <section className="px-4 sm:px-16 py-10 mb-20">
      <div className="flex justify-between items-center mb-8">
        <div className="text-3xl">Venues</div>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by..."
          className="w-full md:w-1/4 p-4 bg-mainBg border focus:outline-none rounded-lg"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {venues.length > 0 ? 
        <>
          {venues.map((venue, index) => (
          <div
            onClick={() => {
              navigateToVenueDetail(venue);
            }}
            className="cursor-pointer"
          >
            <VenueCard venue={venue} />
          </div>
        ))}
        </>: 
        <>
          <Typography variant="h5" className="pb-2 text-neutral-600">
            No Venues Found
        </Typography>
        </>}
        
      </div>
      <Stack spacing={2} className="mt-8 flex items-center">
        <Pagination
          count={Math.ceil(totalVenues / limit)}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          page={page}
        />
      </Stack>
    </section>
  );
};

export default VenuesSection;
