import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  user: null,
  token: null,
  posts: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "dark" ? "light" : "dark";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem("token",action.payload.token);
      localStorage.setItem("user",JSON.stringify(action.payload.user))
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error("user friends non-existent :(");
      }
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
    setEvent: (state, action) => {
      state.event = action.payload.event;
    },
    setEventPurchasedTickets: (state, action) => {
      state.purchasedTickets = action.payload.purchasedTickets;
    },
    setViewETicket: (state, action) => {
      state.viewTicket = action.payload;
    },
  },
});

export const { setMode, setLogin, setLogout, setFriends, setPosts, setPost, setEvent, setEventPurchasedTickets,setViewETicket } =
  authSlice.actions;
export default authSlice.reducer;
