
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Divider, Tooltip, IconButton, Typography, useTheme, useMediaQuery, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import { apiUrl } from "./../../config";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import nopreview from "assests/images/no-picture-available.png"
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { toast } from "react-toastify";
const MyEventWidget = ({
  event, callUpdatedPost
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = Boolean(event.likes[loggedInUserId]);
  const likeCount = Object.keys(event.likes).length;
  const navigate = useNavigate();
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const background = palette.background.default;
  const primaryLight = palette.primary.light;
  const primaryMain = palette.primary.main;
  const primaryDark = palette.primary.dark;
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const [imgErr, setImgErr] = useState(false);
  const [open, setOpen] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleteEvent = async () => {
    const response = await fetch(`${apiUrl}/posts/remove`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      body: JSON.stringify({ postId: event._id }),
    });
    const data = await response.json();
    if (data.success == true) {
      toast.success("Event deleted successfuly");
      callUpdatedPost();
      handleClose();
    } else {
      toast.error(`Unable to delete event. Try again or Contact support. Ref: ${data.message}`)
    }
  }
  const downloadXLSX = async () => {
    try {
      const response = await fetch(`${apiUrl}/checkout/download-event-bookings`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({ eventId: event._id }),
        responseType: 'blob'
      });
      const data = await response.blob();
      const href = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `bookings_${event.eventName}_${new Date().getTime()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("File is being downloaded")
    } catch (e) {
      toast.error("Unable to download booking. Try again or Contact support")
    }

  }

  return (
    <WidgetWrapper m="10px" style={{ backgroundColor: '#3A3939' }}
      width={isNonMobileScreens ? "25%" : "100%"} >
      <FlexBetween sx={{ justifyContent: 'flex-end' }}>
        <Tooltip title="Delete event"><DeleteRoundedIcon color={primaryDark} onClick={handleClickOpen} sx={{ marginRight: '5px', cursor: 'pointer' }} />
        </Tooltip>
        {/* <EditRoundedIcon color={primaryDark}  onClick={() => navigate(`/create-event/${event._id}`)} sx={{cursor:'pointer'}}/> */}
        <Tooltip title="Copy event"><ContentCopyRoundedIcon color={primaryDark} onClick={() => navigate(`/create-event/${event._id}`)} sx={{ cursor: 'pointer' }} />
        </Tooltip>
      </FlexBetween>
      <Typography
        fontWeight={400}
        fontSize="20px"
        onClick={() => navigate(`/event/${event._id}`)}
        color={primary}
        style={{ cursor: 'pointer' }}>
        {event.eventName}
      </Typography>
      <Typography variant="p" pb=".5rem" sx={{ color: main }}>
        {event.eventDate
          ? dayjs(event.eventDate).format("MMMM, DD MMM YYYY")
          : ""}
      </Typography>
      <br />
      <Typography variant="p" pb=".5rem" sx={{ color: main }}>
        {event.eventStartTime
          ? dayjs(event.eventStartTime).format("hh:mm A")
          : ""}{" "}
        to{" "}
        {event.eventStartTime
          ? dayjs(event.eventEndTime).format("hh:mm A")
          : ""}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: main, display: "flex", alignItems: "center" }}
      >
        <LocationOnIcon sx={{ fontSize: 16, marginRight: "4px" }} />
        {event.location}
      </Typography>
      {imgErr == false ? (
        <img
          width="100%"
          alt="post"
          onError={() => { setImgErr(true) }}
          style={{ borderRadius: "0.75rem", marginTop: "0.75rem", height: '30vh', objectFit: 'cover' }}
          src={`${event.picturePath}`}
        />
      ) :
        (<img
          width="100%"
          alt="post"
          style={{ borderRadius: "0.75rem", marginTop: "0.75rem", height: '30vh', objectFit: 'cover' }}
          src={`${nopreview}`}
        />)}
      {/* <Box
        flexBasis={"18%"}
        style={{ width: "100%", marginTop: "2px" }}
        paddingLeft={'1rem'}
        paddingRight={'1rem'}
        paddingBottom={'1rem'}
      // backgroundColor={palette.background.alt}
      > */}
      <FlexBetween justifyContent={'space-between'}>
        <Button
          onClick={() => {
            navigate(`/bookings/${event._id}`)
          }}
          variant="contained"
          sx={{
            backgroundColor: primaryMain,
            color: primaryDark,
            "&:hover": { color: palette.primary.main },
            p: "0.6rem",
            borderRadius: '10px',
          }}
        >
          View Bookings
        </Button>
        <Button
          onClick={() => {
            downloadXLSX()
          }}
          variant="contained"

          sx={{
            backgroundColor: primaryDark,
            color: 'white',
            p: "0.6rem",
            borderRadius: '10px',
            "&:hover": { color: palette.primary.main }
          }}
        >
          <ArrowCircleDownRoundedIcon color={primaryDark} sx={{ mr: "0.5rem" }} /> List
        </Button>
      </FlexBetween>

      {/* </Box> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Event?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Event will be deleted permanently. Kindly confirm.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancel</Button>
          <Button onClick={deleteEvent} >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </WidgetWrapper>
  );
};

export default MyEventWidget;
