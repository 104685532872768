import React, { useState, useEffect, useRef } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { aboutUsVideoUrl } from "config";

const AboutUsLandingPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const abtUsRef = useRef();
  const [myElementIsVisible, setElementVisibility] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      console.log("entry", entry);
      if (entry.isIntersecting != undefined) {
        setElementVisibility(entry.isIntersecting)
      }
    });
    observer.observe(abtUsRef.current)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="pl-4 sm:px-16 py-2 my-10 " >
      <Typography
        variant="h2"
        className={`py-6 !-ml-4 !mb-4 !font-bold text-xl animate-slide-up text-center md:text-left`}
      >
        About Us
      </Typography>
      <Grid container spacing={2} id="aboutus" ref={abtUsRef}>
        {myElementIsVisible == true ?
          <>
            <Grid
              item
              xs={12}
              md={6}
              className={`flex justify-center bg-yellow items-center !pl-0 !pt-0 ${isMobile ? "h-[55vh]" : "h-[70vh]"
                }`}
            >
              <Box className="relative flex justify-center">
                {isMobile ? <>
                  <iframe loading="lazy" className='video'
                    title='Youtube player'
                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                    src={`https://youtube.com/embed/${aboutUsVideoUrl}?autoplay=0`}
                    style={{ position: 'relative', height: '50vh', width: '40vh' }}
                  >
                  </iframe></> : <>
                  <iframe loading="lazy" className='video'
                    title='Youtube player'
                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                    src={`https://youtube.com/embed/${aboutUsVideoUrl}?autoplay=0`}
                    style={{ position: 'relative', height: '60vh', width:'80vh' }}
                  >
                  </iframe></>}

              </Box>
            </Grid></> : <></>}


        <Grid
          item
          xs={12}
          md={6}
          className="flex justify-center items-center p-5 !px-8"
        >
          <Box className="max-w-xl">
            <Typography
              variant="h2"
              paragraph
              className="text-xl sm:text-2xl"
            >
              <span className="text-yellow text-4xl">VYBEZ,</span> THE
              ULTIMATE PLATFORM FOR THOSE WHO LOVE TO EXPLORE NEW EVENTS,
              VENUES, AND ARTISTS.
            </Typography>
            <Typography variant="body1" className="text-justify" paragraph>
              With Vybez, you can discover the latest happenings in your city
              and beyond, all in one place. Whether you're looking for live
              music, parties, art exhibitions, or food festivals, Vybez has got
              you covered!
            </Typography>
            <Typography variant="body1" className="text-justify" paragraph>
              At Vybez, we believe that life is all about experiencing new
              things and making memories. That's why we've created a platform
              that brings together a diverse community, all with the shared goal
              of creating unforgettable experiences for everyone.
            </Typography>
            <Typography variant="body1" className="text-justify">
              By joining Vybez, you become part of this vibrant community, where
              you can stay connected to the latest info, be on the guest list,
              pre-buy packages, tickets, and plan seamlessly.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default AboutUsLandingPage;
