import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import ExplorePostsWidget from "scenes/widgets/ExplorePostsWidget";
import { seo } from "utilites/utlis";

const Explore = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath, userType, userVerified } = useSelector((state) => state.user);
  seo({title:`Explore`,metaDescription:`Explore`})

  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        padding="1rem"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        flexDirection="column"
        justifyContent="space-between"
        paddingBottom='4rem'
      >
        <ExplorePostsWidget userId={_id} />

      </Box>
    </Box>
  );
};

export default Explore;
