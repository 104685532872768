import React from 'react';
import { Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const Content = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is small

    return (
        <>

            <Grid container justifyContent="center">
                <Grid item xs={12} style={{ backgroundColor: '#f7f7f7', padding: isSmallScreen ? '50px 20px' : '100px 130px' }}>
                    <Typography variant="body1" gutterBottom style={{ color: '#6d6f72', fontSize: '18px' }}>
                        <p>Tickets once successfully purchased for an event listed on VYBZE cannot be cancelled, and that amount will not be refunded. VYBZE does not support cancellation of registration/ticket purchase on the website/App right now.</p>
                        <br />
                        <p>
                            In case, the transaction has been declined or is unsuccessful, but the payment has been deducted from the account, the refund process will be initiated once notified. The amount that had been deducted will be refunded into the account from which the payment was being attempted.
                        </p>
                        <br />
                        <p>

                            VYBZE will not entertain any refund/cancellation request in case of any issues related to payment gateway/method (including wallets such as Mobikwik, PayTM, PayPal etc.) offer. Any issues related to such offers will be addressed by the payment gateway/method itself.
                        </p>
                        <br />
                        <p>
                            Please refer

                            <Link> Terms and Conditions </Link> for more information


                        </p>
                    </Typography>
                </Grid>

            </Grid>

        </>
    );
}

export default Content;
