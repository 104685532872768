import React, { useEffect, useState } from "react";
import image from "../../assests/images/badmaash.png";

const ArtistAboutSection = (details) => {
  return (
    <section className="px-4 sm:px-24 py-12">
    <div className="text-3xl text-yellow px-6">Explore the Artist's Story</div>
      <div className="text-base leading-7 text-justify p-6">
        {(details.artist.introduction != undefined && details.artist.introduction!=null && details.artist.introduction!='null' && details.artist.introduction!='' && details.artist.introduction!='undefined') ? 
        details.artist.introduction : 'Information not available'}
      </div>
      <div className="flex justify-end">
        <div className="!w-[200px] !h-[20px] -mr-10 sm:-mr-32 mt-4 bg-yellow z-50 rounded-l-md"></div>
      </div>
    </section>
  );
};

export default ArtistAboutSection;
