import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Stack, Pagination } from "@mui/material";
import { apiUrl } from "./../../config";
import "../../assests/css/swiper.css";
import EventsLandingCardWidget from "scenes/landingPage/EventsLandingCardWidget";

const ArtistEventsSection = (details) => {
  const navigate = useNavigate();
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [page, setPage] = useState(1);

  const getLimit = () => {
    const width = window.innerWidth;
    if (width <= 600) {
      return 1;
    } else {
      return 6;
    }
  };

  const limit = getLimit();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(`${apiUrl}/posts/artist/${details.artist._id}/tags/${page}/${limit}`);
        const data = await response.json();
        setFeaturedPosts(data.posts);
        setTotalPosts(data.total);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEventData();
  }, [page, limit, details]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getTotalPages = () => {
    return Math.ceil(totalPosts / limit);
  };

  return (
    <section className="px-4 sm:px-24 py-4">
      <div
        className={`!font-bold text-2xl animate-slide-up pb-6 text-center md:text-left`}
      >
        Upcoming Events
      </div>
      {featuredPosts.length === 0 ? (
        <Typography variant="body2" className="pb-2 text-neutral-600">
          No Events Found
        </Typography>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {featuredPosts.map((event) => (
              <EventsLandingCardWidget key={event._id} event={event} />
            ))}
          </div>
          <Stack spacing={2} className="mt-8 flex items-center">
            <Pagination
              count={getTotalPages()}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              page={page}
            />
          </Stack>
        </>
      )}
    </section>
  );
};

export default ArtistEventsSection;
