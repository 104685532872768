import React from "react";
import LandingNavbar from "scenes/landingPage/LandingNavbar";
import ArtistSection from "./artistSection";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";

const ArtistList = () => {
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="bg-mainBg overflow-hidden">
      {isAuth ? <Navbar /> : <LandingNavbar />}
      <ArtistSection />
    </div>
  );
};

export default ArtistList;
