import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Divider, IconButton, Typography, useTheme,useMediaQuery } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import { apiUrl } from "./../../config";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const ExplorePostWidget = ({
  event
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = Boolean(event.likes[loggedInUserId]);
  const likeCount = Object.keys(event.likes).length;
  const navigate = useNavigate();
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const patchLike = async () => {
    const response = await fetch(`${apiUrl}/posts/${event._id}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
  };

  return (
    <WidgetWrapper
    data-umami-event-checkoutpage={event.eventName}
    data-umami-event="Explore-Event-Event-Click"
    m="10px" style={{cursor:'pointer',backgroundColor: '#3A3939'}}
      width={isNonMobileScreens ? "25%" : "100%"} onClick={() => navigate(`/event/${event._id}`)}>
      <Typography
        fontWeight={400}
        fontSize="20px"
        color={primary}>
        {event.eventName}
      </Typography>
      <Typography variant="p" pb=".5rem" sx={{ color: main }}>
        {event.eventDate
          ? dayjs(event.eventDate).format("MMMM, DD MMM YYYY")
          : ""}
      </Typography>
      <br />
      <Typography variant="p" pb=".5rem" sx={{ color: main }}>
        {event.eventStartTime
          ? dayjs(event.eventStartTime).format("hh:mm A")
          : ""}{" "}
        to{" "}
        {event.eventStartTime
          ? dayjs(event.eventEndTime).format("hh:mm A")
          : ""}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: main, display: "flex", alignItems: "center" }}
      >
        <LocationOnIcon sx={{ fontSize: 16, marginRight: "4px" }} />
        {event.location}
      </Typography>
      {event.picturePath && (
        <img
          width="100%"
          alt="post"
          style={{ borderRadius: "0.75rem", marginTop: "0.75rem", height: '30vh', objectFit: 'cover' }}
          src={`${event.picturePath}`}
        />
      )}
    </WidgetWrapper>
  );
};

export default ExplorePostWidget;
